import React, { useState, useEffect, useCallback } from 'react';
import { createPropertyType, updatePropertyType, fetchPropertyTypeById } from '../../../services/propertyTypeService';
import { validatePropertyType } from '../../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../../utils/errorHandler';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';

export const AddPropertyType = ({
  editMode = false,
  initialData = {},
  onSuccess,
  setSelectedPageGroup,
  setEditMode
}) => {
  const [formData, setFormData] = useState({ propertyType: '' });
  const [errors, setErrors] = useState({ propertyType: '' });
  const [apiError, setApiError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchPropertyTypeById(initialData.id);
          setFormData({ propertyType: data.propertyType || '' });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({ propertyType: '' });
      }
    };
    fetchData();
  }, [editMode, initialData]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { valid, errors: validationErrors } = validatePropertyType(formData);
    setErrors(validationErrors);

    if (valid) {
      setApiError('');
      try {
        setIsButtonDisabled(true);
        const action = editMode ? updatePropertyType : createPropertyType;
        await action({ ...formData, id: editMode ? initialData.id : undefined });
        toast.success(`Property Type ${editMode ? 'updated' : 'added'} successfully!`);
        setIsButtonDisabled(false);
        setFormData({ propertyType: '' });
        onSuccess && onSuccess(); 
        setEditMode(false);
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      }
    }
  }, [formData, editMode, initialData, onSuccess,setEditMode]);

  const handleAddNewClick = () => {
    setFormData({ propertyType: '' });
    setErrors({ propertyType: '' });
    setApiError('');
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>
      <ComponentHeader title='Property Type Master'/>

      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? 'Update Property Type' : 'Add Property Type'}</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyType" className="form-label">Property Type <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="propertyType"
                        value={formData.propertyType}
                        placeholder='Enter Property Type'
                        onChange={handleInputChange}
                        className={`form-control ${errors.propertyType ? 'is-invalid' : ''}`}
                      />
                      {errors.propertyType && <div className="invalid-feedback">{errors.propertyType}</div>}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button type="submit" className="btn btn-secondary pt-1 pb-1 p-3" disabled={isButtonDisabled}>{isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}</button>
                      {editMode && (
                        <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1 pt-1 pb-1 p-3">
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
