import React, {useState} from 'react';
import TableHeader from '../Common/TableComponent/TableHeader';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { Link } from 'react-router-dom';
import { PaymentUpdateModal } from './PaymentUpdateModal';

export const OtherItemsDetails = ({ customerData, handleFetchData }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPaymentId, setSelectedPaymentId] = useState('');

    const handleOpenModal = (paymentId) => {
        setSelectedPaymentId(paymentId);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedPaymentId(null);
    };

    const OtherItems = customerData?.oOtherItems || [];
    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric',
        }).format(date);
    };

    const handlePaymentSuccess = () => {
        handleFetchData();
    };

    return (
        <div className="card mt-xxl-n5">
            <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Other Items Details</h5>
            </div>
            <div className='table-responsive'>
                <table className="table table-nowrap align-middle table-bordered">
                    <TableHeader columns={['#', 'Item Name', 'Amount', 'Tax Amount', 'Total Amount', 'Payment Id', 'Payment Status', 'Paid On', 'Updated On', 'Add Payment']} />
                    <tbody className="manage-page-group-table-values">
                        {OtherItems.length === 0 ? (
                            <TableDataStatusError colspan="12" />
                        ) : (
                            OtherItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.itemName}</td>
                                    <td>Rs. {item.amount}</td>
                                    <td>Rs. {item.taxAmount}</td>
                                    <td>Rs. {item.totalAmount}</td>
                                    <td>{item.paymentId}</td>
                                    <td>
                                        {item.paymentStatus === 'Initiated' && <span className="badge rounded-pill badge-soft-primary">Initiated</span>}
                                        {item.paymentStatus === 'Success' && <span className="badge rounded-pill badge-soft-success">Success</span>}
                                        {item.paymentStatus === 'Failed' && <span className="badge rounded-pill badge-soft-danger">Failed</span>}
                                        {item.paymentStatus === 'Pending' && <span className="badge rounded-pill badge-soft-warning">Pending</span>}
                                    </td>
                                    <td>{formatDate(item.paidOn)}</td>
                                    <td>{formatDate(item.updatedOn)}</td>
                                    <td>
                                        {item.paymentStatus === 'Success' ? (
                                            <span style={{ color: 'green', fontWeight: '500' }}>
                                                No Payment Pending
                                            </span>
                                        ) : (
                                            <span className="badge text-bg-danger" style={{ fontSize: '11px' }}><Link to="#" onClick={() => handleOpenModal(item.orderGuid)} style={{ textDecoration: 'none', color: "#fff" }}>Add Payment</Link></span>
                                        )}

                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            <PaymentUpdateModal show={modalVisible} onClose={handleCloseModal} orderId={selectedPaymentId} pageIdentify='other' onPaymentSuccess={handlePaymentSuccess} />
        </div>
    );
}