import React, { useState, useCallback } from 'react';
import { updateSupport } from '../../services/SupportService';
import { Link } from 'react-router-dom';
import { validateSupportUpdateForm } from '../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const UpdateSupport = ({ customerData,setReRendering }) => {
    const [selectedProduct, setSelectedProduct] = useState('');
    const [remarks, setRemarks] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [apiError, setApiError] = useState({});
    const [previewUrl, setPreviewUrl] = useState(null);
    const handleAttachmentChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);

        if (file) {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        } else {
            setPreviewUrl(null);
        }
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setApiError({});
        const validationErrors = validateSupportUpdateForm(selectedProduct, remarks);

        if (Object.keys(validationErrors).length > 0) {
            setApiError(validationErrors);
            return;
        }

        try {
            setIsButtonDisabled(true);
            const formData = new FormData();
            formData.append('SupportGuid', customerData.supportGuid);
            formData.append('ActivityType', selectedProduct);
            formData.append('Remarks', remarks);
            if (attachment) {
                formData.append('Attachment', attachment);
            }

            await updateSupport(formData);
            toast.success('Support request added successfully!');
            setReRendering(true);
            setSelectedProduct('');
            setRemarks('');
            setAttachment(null);
            setPreviewUrl(null);
        } catch (error) {
            setApiError({ flatGuid: 'Error adding support request' });
            toast.error('Error occurred while adding support request!');
        } finally {
            setIsButtonDisabled(false);
        }
    }, [customerData.supportGuid, selectedProduct, remarks, attachment, setReRendering]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Support Management</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to='/support'>
                                        Manage Supports
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Support Detail
                                </li>

                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-1 mt-sm-1">Update Support</h5>
                        </div>
                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="productName" className="form-label">Activity Type <span className='required-field'>*</span></label>
                                            <select
                                                value={selectedProduct}
                                                onChange={(e) => setSelectedProduct(e.target.value)}
                                                className={`form-select ${apiError.ActivityType ? 'is-invalid' : ''}`}
                                            >
                                                <option value="">Select Activity Type</option>
                                                <option value="Created">Created</option>
                                                <option value="InProgress">InProgress</option>
                                                <option value="Waiting">Waiting</option>
                                                <option value="Resolved">Resolved</option>
                                                <option value="Closed">Closed</option>
                                            </select>
                                            {apiError.ActivityType && <div className="invalid-feedback">{apiError.ActivityType}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="remarks" className="form-label">Remarks <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                className={`form-control ${apiError.remarks ? 'is-invalid' : ''}`}
                                            />
                                            {apiError.remarks && <p className="text-danger">{apiError.remarks}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">

                                        <div className="mb-3">
                                            <label htmlFor="attachment" className="form-label">Attachment</label>
                                            <input
                                                type="file"
                                                onChange={handleAttachmentChange}
                                                className="form-control"
                                            />
                                            <label htmlFor='attachment' className='form-label'>(Note: Please attach image or file within 2MB)</label>
                                        </div>

                                        {previewUrl && (
                                            <div className="mb-3">
                                                <label className="form-label">Attachment Preview:</label><br />
                                                {attachment.type.startsWith("image/") ? (
                                                    <img src={previewUrl} alt="Preview" style={{ maxWidth: "30%", height: "auto" }} />
                                                ) : (
                                                    <a href={previewUrl} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={isButtonDisabled}
                                >
                                    Update
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
