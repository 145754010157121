import React, { useState, useEffect, useCallback } from 'react';
import { orderViewDetails } from '../../services/orderManagementService';
import { useParams } from 'react-router-dom';
import { OrderInfoDetails } from './OrderInfoDetails';
import { RentDetails } from './RentDetails';
import { UtilityDetails } from './UtilityDetails';
import { MaintainenceDetails } from './MaintainenceDetails';
import { OtherItemsDetails } from './OtherItemsDetails';

export const CompleteViewOrder = () => {
    const { orderId } = useParams();
    const [customerData, setCustomerData] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const apidata = await orderViewDetails(orderId);
            setCustomerData(apidata.data.result);
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    },[orderId]);


    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleFetchData = () => {
        fetchData();
    };


    return (
        <>
            <div className="container-fluid">
                <OrderInfoDetails customerData={customerData} />
            </div>
            <div className="container-fluid">
                <RentDetails customerData={customerData} handleFetchData={handleFetchData} />
            </div>
            <div className="container-fluid">
                <UtilityDetails customerData={customerData} handleFetchData={handleFetchData} />
            </div>
            <div className="container-fluid">
                <MaintainenceDetails customerData={customerData} handleFetchData={handleFetchData} />
            </div>
            <div className="container-fluid">
                <OtherItemsDetails customerData={customerData} handleFetchData={handleFetchData} />
            </div>
        </>
    );
}