import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createBrand = async (cityData) => {
    const response = await axiosInstance.post('masters/asset-brand', { brandName: cityData.brandName, }, { headers });
    return response.data;
};

export const fetchBrands = async () => {
    const response = await axiosInstance.get('masters/asset-brand', { headers });
    return response.data;
};

export const deleteBrand = async (brandid) => {
    const response = await axiosInstance.delete(`masters/asset-brand/${brandid}`, { headers });
    return response.data;
};

export const fetcBrandById = async (brandId) => {
    const response = await axiosInstance.get(`masters/asset-brand/${brandId}`, { headers });
    return response.data.result;
};

export const updateBrand = async (BrandData) => {
    const response = await axiosInstance.put('masters/asset-brand', BrandData, { headers });
    return response.data;
};