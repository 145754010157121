import React, { useState, useEffect, useCallback } from 'react';
import { fetchSWKProperty } from '../../services/SWKPropertService';
import { fetchFlats } from '../../services/FlatMasterService';
import { fetchPropertyAuditById, updatePropertyAudit } from '../../services/propertyAudit';
import { Link } from 'react-router-dom';
import { validatePropertyAuditForm } from '../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { useParams } from 'react-router-dom';
import { handleErrors } from '../../utils/errorHandler';
import AsyncSelect from 'react-select/async';

export const UpdatePropertyAudit = () => {
    const { id } = useParams();
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [flats, setFlats] = useState([]);
    const [selectedFlat, setSelectedFlat] = useState('');
    const [Comments, setComments] = useState('');
    const [AuditDate, setAuditDate] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [apiError, setApiError] = useState({});

    useEffect(() => {
        const fetchCity = async () => {
            try {
                const data = await fetchPropertyAuditById(id);
                setComments(data.comments);
                setSelectedFlat(data.flatGuid);
                setSelectedProperty(data.propertyGuid);
                setAuditDate(data.auditDate);
            } catch (error) {
                handleErrors(error);
            }
        };

        if (id) {
            fetchCity();
        }
    }, [id]);

    useEffect(() => {
        const getProperties = async () => {
            try {
                const result = await fetchSWKProperty();
                setProperties(result);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        getProperties();
    }, []);


    useEffect(() => {
        const getFlats = async () => {
            if (selectedProperty) {
                try {
                    const result = await fetchFlats(selectedProperty);
                    setFlats(result);
                } catch (error) {
                    console.error('Error fetching flats:', error);
                }
            } else {
                setFlats([]);
            }
        };
        getFlats();
    }, [selectedProperty]);

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = properties.filter((property) =>
            property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredProperties.map((property) => ({
                    label: property.propertyName,
                    value: property.propertyGuid,
                }))
            );
        }, 1000);
    };

    const loadFlatOptions = (inputValue, callback) => {
        const filteredFlats = flats.filter((flat) =>
            flat.flatName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredFlats.map((flat) => ({
                    label: flat.flatName,
                    value: flat.flatGuid,
                }))
            );
        }, 1000);
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setApiError({});
    
        const validationErrors = validatePropertyAuditForm({
            PropertyGuid: selectedProperty,
            FlatGuid: selectedFlat,
            AuditDate: AuditDate,
            Comments: Comments,
            Attachments:'attach',
        });
    
        if (Object.keys(validationErrors).length > 0) {
            setApiError(validationErrors);
            return;
        }
    
        try {
            setIsButtonDisabled(true);
    
            const formData = new FormData();
            formData.append('PropertyGuid', selectedProperty);
            formData.append('FlatGuid', selectedFlat);
            formData.append('Comments', Comments);
            formData.append('AuditDate', AuditDate);
    
    
                formData.append('id', id);
                await updatePropertyAudit(formData);
                toast.success('Audit updated successfully!');
        } catch (error) {
            setApiError({ formError: id ? 'Error updating property Audit' : 'Error adding property Audit' });
            toast.error(id ? 'Error occurred while updating Audit!' : 'Error occurred while adding Audit!');
        } finally {
            setIsButtonDisabled(false);
        }
    }, [id, selectedFlat, Comments, AuditDate, selectedProperty]);
    
    return (
        <>
             <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Property Audit</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/property-audit">
                                        Manage Property Audit
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Update Property Audit-{id}
                                    
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-1 mt-sm-1">{id ? 'Update Property Audit' : 'Add Property Audit'}</h5>
                        </div>
                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="propertyName" className="form-label">Property Name <span className='required-field'>*</span></label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadPropertyOptions}
                                                defaultOptions={properties.map((property) => ({
                                                    label: property.propertyName,
                                                    value: property.propertyGuid,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedProperty(selectedOption?.value || '');
                                                }}
                                                value={{
                                                    label: properties.find((property) => property.propertyGuid === selectedProperty)?.propertyName,
                                                    value: selectedProperty,
                                                }}
                                                isClearable
                                                placeholder="Select Property"
                                                className={apiError.PropertyGuid ? 'is-invalid' : ''}
                                            />
                                            {apiError.PropertyGuid && <div className="invalid-feedback">{apiError.PropertyGuid}</div>}
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="flatGuid" className="form-label">Flat Name</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadFlatOptions}
                                                defaultOptions={flats.map((flat) => ({
                                                    label: flat.flatName,
                                                    value: flat.flatGuid,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedFlat(selectedOption?.value || '');
                                                }}
                                                value={{
                                                    label: flats.find((flat) => flat.flatGuid === selectedFlat)?.flatName,
                                                    value: selectedFlat,
                                                }}
                                                isClearable
                                                placeholder="Select Flat"
                                                className={apiError.flatGuid ? 'is-invalid' : ''}
                                            />
                                            {apiError.flatGuid && <div className="invalid-feedback">{apiError.flatGuid}</div>}
                                        </div>
                                    </div>
                                 
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="AuditDate" className="form-label">Audit Date<span className='required-field'>*</span></label>
                                            <Flatpickr
                                                className={`form-control ${apiError.AuditDate ? 'is-invalid' : ''}`}
                                                value={AuditDate ? new Date(AuditDate) : ''}
                                                placeholder="Enter Audit Date"
                                                onChange={(date) => {
                                                    const formattedDate = new Date(date[0]).toISOString();
                                                    setAuditDate(formattedDate);
                                                }}
                                                options={{
                                                    dateFormat: "d-M-Y",
                                                    monthSelectorType: "static",
                                                }}
                                            />


                                            {apiError.AuditDate && <p className="text-danger">{apiError.AuditDate}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="Comments" className="form-label">Comments<span className='required-field'>*</span></label>
                                            <textarea
                                                value={Comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                className={`form-control ${apiError.Comments ? 'is-invalid' : ''}`}
                                                rows="4"
                                            />
                                            {apiError.Comments && <div className="invalid-feedback">{apiError.Comments}</div>}
                                        
                                        </div>
                                    </div>
                                </div>

                               
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={isButtonDisabled}
                                >
                                    Update
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
