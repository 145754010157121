import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { fetchAmenities, deleteAmenity } from '../../../services/amenityService';
import { paginateData, calculateTotalPages } from '../../../assets/js/script';
import TableHeader from '../../Common/TableComponent/TableHeader';
import EntriesDropdown from '../../Common/TableComponent/EntriesDropdown';
import TablesRow from '../../Common/TableComponent/TablesRow';
import { Pagination } from '../../Common/TableComponent/Pagination';
import { Loading } from '../../Common/OtherElements/Loading';
import { AddAmenityMaster } from './AddAmenityMaster';
import Swal from 'sweetalert2';
import { confirmDelete } from '../../Common/OtherElements/confirmDeleteClone';
import {TableDataStatusError} from '../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../utils/errorHandler';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const ManageAmenityMasterContent = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [amenityList, setAmenityList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPageGroup, setSelectedPageGroup] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageAccessDetails, setPageAccessDetails] =useState([]);
  const PageLevelAccessurl = 'amenity-master';
const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if(!pageAccessData.addAccess && !pageAccessData.viewAccess){
            navigate('/404-error-page');
            } else{
                setPageAccessDetails(pageAccessData);
            }
            
        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetchAmenities();
            setAmenityList(response);
        } catch (error) {
           handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDelete = useCallback(async (amenityId) => {
        const confirmed = await confirmDelete('amenity master');
        if (confirmed) {
            try {
                await deleteAmenity(amenityId);
                setAmenityList((prev) => prev.filter((item) => item.id !== amenityId));
                Swal.fire('Deleted!', 'The amenity master has been deleted successfully.', 'success');
            } catch (error) {
        handleErrors(error);
            }
        }
    }, []);

    const filteredData = useMemo(() => {
        return amenityList.filter(item =>
            item.amenityName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [amenityList, searchTerm]);

    const currentData = useMemo(() => paginateData(filteredData, currentPage, entriesPerPage), [filteredData, currentPage, entriesPerPage]);
    const totalPages = useMemo(() => calculateTotalPages(filteredData.length, entriesPerPage), [filteredData.length, entriesPerPage]);

    const handleEntriesChange = useCallback((value) => {
        setEntriesPerPage(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
    }, []);

    const handleSearchChange = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, []);

    return (
        <>
        {pageAccessDetails.addAccess ? (
            <AddAmenityMaster
                editMode={editMode}
                initialData={selectedPageGroup}
                onSuccess={fetchData}
                setSelectedPageGroup={setSelectedPageGroup}
                setEditMode={setEditMode}
            />
            
) : ''}
            {pageAccessDetails.viewAccess ? (
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-2 mt-sm-2">Manage Amenity Master</h5>
                        </div>
                        <div className="card-body manage-amenity-master-card-body">
                            <div className="pagination-details-responsive justify-content-between mb-3">
                                <EntriesDropdown
                                    entriesPerPage={entriesPerPage}
                                    onEntriesChange={handleEntriesChange}
                                />
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="form-control mb-2"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                    <table className="table align-middle table-bordered">
                                        <TableHeader columns={['#', 'Amenity Name', 'Amenity Icon', 'Added On', 'Action']} />
                                        <tbody className="manage-page-group-table-values">
                                            {currentData.length === 0 ? (
                                                <TableDataStatusError colspan="5"/>
                                            ) : (
                                                currentData.map((item, index) => (
                                                    <TablesRow
                                                        key={item.id}
                                                        rowData={{
                                                            id: (currentPage - 1) * entriesPerPage + index + 1,
                                                            amenityName: item.amenityName,
                                                            amenityicon: (
                                                                <img src={item.amenityIcon} alt={item.amenityName} width='20px' height='20px' />
                                                            ),
                                                            addedon: new Date(item.addedOn).toLocaleDateString(),
                                                        }}
                                                        columns={['id', 'amenityName', 'amenityicon', 'addedon']}
                                                        hideIcons={false}
                                                        onEdit={() => {
                                                            setSelectedPageGroup(item);
                                                            setEditMode(true);
                                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                                        }}
                                                        onDelete={() => handleDelete(item.id)}
                                                        pageLevelAccessData={pageAccessDetails}
                                                    />
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                    </div>
                                )
                            }

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                totalEntries={filteredData.length}
                                entriesPerPage={entriesPerPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            
) : ''}
        </>
    );
};
