import axiosInstance from '../Interceptors/axiosInstance.jsx'; 
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createLocation = async (locationData) => {
const response = await axiosInstance.post('location/add', {cityId: String(locationData.cityId), cityName: locationData.cityName, locationName: locationData.locationName, }, { headers });
        return response.data; 
};

export const fetchLocations = async () => {
      const response = await axiosInstance.get('location/all', { headers });
      return response.data; 
  };
  
  export const deleteLocation = async (locationId) => {
 await axiosInstance.delete(`location/delete/${locationId}`, { headers });
  };

  export const fetchLocationById = async (id) => {
const response = await axiosInstance.get(`location/details/${id}`, {headers});
      return response.data; 
  };
  
  
  export const updateLocation = async (payload) => {
  const response = await axiosInstance.put('location/update', payload, {headers});
      return response.data;
  };
  

  export const fetchSWRProperty = async () => {
    const response = await axiosInstance.get("properties/all", { headers });
    return response.data.result;
};
  
export const fetchPropertiesByHandyman = async (hmGuid) => {
  try {
    const response = await axiosInstance.get(
      `properties/handyman/assign/single/${hmGuid}`,
      { headers }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching properties by supervisor:", error);
    throw error;
  }
};



export const fetchPropertiesBySalesstaff = async (ssGuid) => {
  try {
    const response = await axiosInstance.get(
      `properties/salesstaff/assign/single/${ssGuid}`,
      { headers }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching properties by supervisor:", error);
    throw error;
  }
};