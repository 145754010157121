import React from 'react';
import { ToastContainer } from 'react-toastify';
import { HeaderTab } from '../components/Properties/ViewEachPropertyDetail/HeaderTab';
export const ViewFullPropertyDetails = () => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
                    <HeaderTab/>
                </div>
            </div>
        </div>
    )
}
