import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ManageCustomers } from '../components/OnboardCustomers/ManageCustomers';
export const ManageOnboardingCustomers = () => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                    <ManageCustomers />
                </div>
            </div>

        </div>

    )
}
