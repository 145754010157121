import React, { useEffect, useState, useCallback } from "react";
import { getPropertyNearby, deletePropertyNearby } from "../../../services/PropertyNearbyService";
import { paginateData, calculateTotalPages } from "../../../assets/js/script";
import TableHeader from "../../Common/TableComponent/TableHeader";
import EntriesDropdown from "../../Common/TableComponent/EntriesDropdown";
import TablesRow from "../../Common/TableComponent/TablesRow";
import { Pagination } from "../../Common/TableComponent/Pagination";
import { AddPropertyNearby } from "./AddPropertyNearby";
import { Loading } from "../../Common/OtherElements/Loading";
import Swal from 'sweetalert2';
import { confirmDelete } from '../../Common/OtherElements/confirmDeleteClone';
import {TableDataStatusError} from '../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from "../../../utils/errorHandler";

export const ManageNearbyContent = ({propertyDetails, pageLevelAccessData}) => {
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [managePageGroup, setManagePageGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPageGroup, setSelectedPageGroup] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPropertyNearby(propertyDetails);
      setManagePageGroup(response.data.result || []);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [propertyDetails]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = managePageGroup.filter(item =>
    item.nearByName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentData = paginateData(filteredData, currentPage, entriesPerPage);
  const totalPages = calculateTotalPages(filteredData.length, entriesPerPage);

  const handleEntriesChange = (value) => {
    setEntriesPerPage(value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (propertyId) => {
    const confirmed = await confirmDelete('Property');
    if (confirmed) {
      try {
        await deletePropertyNearby(propertyId);
        setManagePageGroup(prev => prev.filter(item => item.id !== propertyId));
        Swal.fire('Deleted!', 'The property has been deleted successfully.', 'success');
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleEdit = (propertyId) => {
    const propertyToEdit = managePageGroup.find(item => item.id === propertyId);
    setSelectedPageGroup(propertyToEdit);
    setEditMode(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
    {pageLevelAccessData.addAccess ? (
      <AddPropertyNearby
        editMode={editMode}
        initialData={selectedPageGroup || {}}
        onSuccess={fetchData}
        setSelectedPageGroup={setSelectedPageGroup}
        setEditMode={setEditMode}
        propertyDetails={propertyDetails}
      />
    ) : ''}
      
{pageLevelAccessData.viewAccess ? (
 <div className="row">
 <div className="col-xxl-12">
   <div className="card mt-xxl-n5">
     <div className="card-header">
       <h5 className="mb-sm-2 mt-sm-2">Manage NearBy Property</h5>
     </div>
     <div className="card-body manage-amenity-master-card-body">
       <div className="pagination-details-responsive justify-content-between mb-3">
         <EntriesDropdown
           entriesPerPage={entriesPerPage}
           onEntriesChange={handleEntriesChange}
         />
         <div>
           <input
             type="text"
             placeholder="Search..."
             className="form-control mb-2"
             value={searchTerm}
             onChange={(e) => setSearchTerm(e.target.value)}
           />
         </div>
       </div>
       {loading ? (
         <Loading />
       ) : (
         <div className='table-responsive'>
         <table className="table align-middle table-bordered">
           <TableHeader columns={["#", "Near By Types", "Near By Name", "Distance In KM", "Near By Link", "Added On", "Action",]}
           />
           <tbody className="manage-page-group-table-values">
             {currentData.length === 0 ? (
            <TableDataStatusError colspan="7"/>
             ) : (
               currentData.map((item, index) => (
                 <TablesRow
                   key={item.id}
                   rowData={{
                     id: (currentPage - 1) * entriesPerPage + index + 1,
                     nearByTypes: item.nearByTypes,
                     nearByName: item.nearByName,
                     distanceInKM: `${item.distanceInKM} km`,
                     nearByLink: (
                       <a
                         href={item.nearByLink.trim() || "#"}
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         {item.nearByLink.trim() || "#"}
                       </a>
                     ),
                     updatedOn: new Date(item.updatedOn).toLocaleDateString(),
                   }}
                   columns={["id", "nearByTypes", "nearByName", "distanceInKM", "nearByLink", "updatedOn",]}
                   hideIcons={false}
                   onEdit={() => handleEdit(item.id)}
                   onDelete={() => handleDelete(item.id)}
                   pageLevelAccessData={pageLevelAccessData}
                 />
               ))
             )}
           </tbody>
         </table>
         </div>
       )}
       <Pagination
         currentPage={currentPage}
         totalPages={totalPages}
         totalEntries={managePageGroup.length}
         entriesPerPage={entriesPerPage}
         onPageChange={handlePageChange}
       />
     </div>
   </div>
 </div>
</div>
) : ''}
     
    </>
  );
};
