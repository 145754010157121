import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createBHKType = async (bhkTypeData) => {
    const response = await axiosInstance.post('masters/bhk-type', bhkTypeData, { headers });
    return response.data;
};

export const fetchBHKTypes = async () => {
    const response = await axiosInstance.get('masters/bhk-type', { headers });
    return response.data;
};

export const deleteBHKType = async (bhkTypeId) => {
    await axiosInstance.delete(`masters/bhk-type/${bhkTypeId}`, { headers });
};

export const fetchBHKTypeById = async (id) => {
    const response = await axiosInstance.get(`masters/bhk-type/${id}`, { headers });
    return response.data.result;
};

export const updateBHKType = async (bhkTypeData) => {
    const response = await axiosInstance.put('masters/bhk-type', bhkTypeData, { headers });
    return response.data;
};
