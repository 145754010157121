import React from 'react'
import { ManageLocationContent } from '../components/AppSettings/Location/ManageLocationContent'
import { ToastContainer } from 'react-toastify';
export const LocationPage = () => {
  return (
    <div className="main-content">
    <div className="page-content">
        <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
            <ManageLocationContent/>
        </div>
    </div>
</div>
  )
}
