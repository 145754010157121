import React, { useState, useEffect } from 'react';
import { SupportViewDetails } from '../../services/SupportService';
import { useParams } from 'react-router-dom';
import { SupportInfoDetails } from './SupportInfoDetails';
import { SupportTimeline } from './SupportTimeline';
import { UpdateSupport } from './UpdateSupport';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';


export const CompleteViewSupport = () => {
    const { supportid } = useParams();
    const [rerendering, setReRendering] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = '/support/detail/:supportid';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.editAccess && !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    }, [pageAccessData, navigate])
    
    useEffect(() => {
        const fetchData = async () => {
            if (supportid) {
                try {
                    const apidata = await SupportViewDetails(supportid);
                    setCustomerData(() => apidata.data.result);
                    setReRendering(false);
                } catch (error) {
                    console.error("Error fetching customer data:", error);
                }
            }
        };
        fetchData();
    }, [supportid, rerendering]);
    console.log('renredering status', rerendering);

    return (
        <>
            {pageAccessDetails.editAccess ? (
                <div className="container-fluid">
                    <UpdateSupport customerData={customerData} setReRendering={setReRendering} />
                </div>
            ) : ''}
            {pageAccessDetails.viewAccess ? (
                <>
            <div className="container-fluid">
                <SupportInfoDetails customerData={customerData} />
            </div>
            <div className="container-fluid">
                <SupportTimeline customerData={customerData} />
            </div>
            </>
            ) : ''}
        </>
    );
}