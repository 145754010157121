import React, { useEffect, useState, useCallback } from 'react';
import { fetchPropertyAuditById, deletePropertyAuditGallery } from '../../services/propertyAudit';
import TableHeader from '../Common/TableComponent/TableHeader';
import TablesRow from '../Common/TableComponent/TablesRow';
import { Loading } from '../Common/OtherElements/Loading';
import Swal from 'sweetalert2';
import { confirmDelete } from '../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../utils/errorHandler';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const ManagePropertyAuditGallery = ({ auditId }) => {
    const [manageCities, setManageCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'property-audit';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])


    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetchPropertyAuditById(auditId);
            setManageCities(response.gallery || []);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, [auditId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const handleDelete = useCallback(async (galleryid) => {
        const confirmed = await confirmDelete('Gallery Item');
        if (confirmed) {
            try {
                await deletePropertyAuditGallery(galleryid);
                setManageCities((prev) => prev.filter(city => city.id !== galleryid));
                Swal.fire('Deleted!', 'The Gallery item has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    }, []);

    return (
        <>
            <style>
                {`
                    .ri-pencil-fill:before {
                      display:none;
                    }
                `}
            </style>
            {pageAccessDetails.viewAccess ? (
            <div className="row">
                <div className="col-xxl-12 pt-4">
                    <div className="mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-2 mt-sm-2">Manage Audit Gallery</h5>
                        </div>
                        <div className="card-body manage-amenity-master-card-body">

                            {loading ? (
                                <Loading />
                            ) : (
                                <div className='table-responsive'>
                                    <table className="table align-middle table-bordered">
                                        <TableHeader columns={['#', 'Image', 'File Type', 'Added On', 'Action']} />
                                        <tbody className="manage-page-group-table-values">
                                            {manageCities.length === 0 ? (
                                                <TableDataStatusError colspan="4" />
                                            ) : (
                                                manageCities.map((city, index) => (
                                                    <TablesRow
                                                        key={city.id}
                                                        rowData={{
                                                            id: index + 1,
                                                            image: (
                                                                <>
                                                                    <span>
                                                                        <img
                                                                            src={city.fileLink || '-'}
                                                                            alt="Audit items"
                                                                            className="audit-img"
                                                                            style={{ width: '80px', height: '50px' }}
                                                                        />
                                                                    </span>
                                                                </>
                                                            ),
                                                            filetype: city.fileType,
                                                            addedon: new Date(city.addedOn).toLocaleDateString(),
                                                        }}
                                                        columns={['id', 'image', 'filetype', 'addedon']}
                                                        hideIcons={false}
                                                        onDelete={() => handleDelete(city.id)}
                                                        pageLevelAccessData={pageAccessDetails}
                                                    />
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            ) : ''}
        </>
    );
};
