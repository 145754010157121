import { useRef, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Editor } from "@tinymce/tinymce-react";
import AsyncSelect from 'react-select/async';
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";

export const FormSection1 = ({ label,labelStar, name, value, onChange, error, type, options, preferredTenantsdata, parkingOptionsdata, stayType,tags, clearKey,formData,propertySeries, setFormData, handleCheckboxChange, handleCheckboxChangeAmenity }) => {
  const dropdownRef = useRef(null);

  const filterOptions = (inputValue) => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 1000);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'preferredTenants' || name === 'parking' || name === 'stayType' || name ==='tags') {
      const checked = e.target.checked;
      const currentValue = formData[name] || '';
      const values = currentValue.split(',');

      if (checked) {
        if (!values.includes(value)) {
          values.push(value);
        }
      } else {
        const index = values.indexOf(value);
        if (index !== -1) {
          values.splice(index, 1);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: values.join(','),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownRef]);

  const handleEditorChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      flatDesc: content,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); 
    const month = date.toLocaleString('default', { month: 'short' }); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = (selectedDate) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: formatDate(selectedDate),
    }));
  };

  if (type === 'dropdown') {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
        <div className="mb-3">
          <label htmlFor={name} className="form-label">{labelStar}</label>
          <select
            name={name}
            className={`form-select ${error ? 'is-invalid' : ''}`}
            value={value}
            onChange={onChange}
          >
            <option value="">Select {label}</option>
            {options && options.length > 0 ? (
              options.map((option) => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))
            ) : (
              <option value="" disabled>No options available</option>
            )}
          </select>
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    );
  } else if (type === 'propertyOwner') {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 pt-3" style={{zIndex:5}}>
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{labelStar}</label>
        <AsyncSelect
          cacheOptions
          key={clearKey}
          defaultOptions={options} 
          loadOptions={loadOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={options.find((option) => option.id === value)} 
          onChange={(selectedOption) => onChange({ target: { name, value: selectedOption.id } })}
          className={error ? 'is-invalid' : ''}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
    );
  } else if (type === 'preferredTenant') {

    return (
      <>
        <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
          <div className="mb-3" ref={dropdownRef}>
            <label htmlFor="preferredTenants" className="form-label">Preferred Tenants <span className='required-field'>*</span></label>
            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                m: 1,
                width: '100%',
              }}
            >
              <Select
                labelId="preferred-tenants-checkbox-label"
                className={`form-select ${error ? 'is-invalid': ''}`}
                id="preferred-tenants-checkbox"
                multiple
                value={formData.preferredTenants ? formData.preferredTenants.split(',') : []}
                onChange={handleChange}
                input={<OutlinedInput label="Preferred Tenants" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {preferredTenantsdata.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    onClick={(e) => {
                      if (e.target.tagName !== 'INPUT') {
                        handleCheckboxChange('preferredTenants', option);
                      }
                    }}
                  >
                    <Checkbox
                      checked={formData.preferredTenants.includes(option)}
                      onChange={() => handleCheckboxChange("preferredTenants", option)}
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              
              {error ? (
                <div style={{color:'#dc3545', fontSize:'.875em'}}>{error}</div>
              ) : ''}
            </FormControl>
            
          </div>
          
        </div>
      </>
    )
  } else if (type === 'parkingOptions') {

    return (
      <>
        <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
          <div className="mb-3" ref={dropdownRef}>
            <label htmlFor="parkingOptions" className="form-label">Parking <span className="required-field">*</span></label>
            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                m: 1,
                width: '100%',
              }}
            >
              <Select
                labelId="parking-checkbox-label"
                className={`form-select ${error ? 'is-invalid' : ''}`}
                id="parking-checkbox"
                multiple
                value={formData.parking ? formData.parking.split(',') : []}
                onChange={handleChange}
                input={<OutlinedInput label="Parking" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {parkingOptionsdata.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    onClick={(e) => {
                      if (e.target.tagName !== 'INPUT') {
                        handleCheckboxChange('parking', option);
                      }
                    }}
                  >
                    <Checkbox
                      checked={formData.parking.includes(option)}
                      onChange={() => handleCheckboxChange("parking", option)}
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              {error ? (
                <div style={{color:'#dc3545', fontSize:'.875em'}}>{error}</div>
              ) : ''}
            </FormControl>
          </div>
        </div>
      </>
    )
  } else if (type === 'amenity') {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
        <div className="mb-3" ref={dropdownRef}>
          <label htmlFor="amenity" className="form-label">Amenity <span className='required-field'>*</span></label>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            sx={{
              m: 1,
              width: '100%',
            }}
          >
            <Select
              labelId="amenity-checkbox-label"
              className={`form-select ${error ? 'is-invalid': ''}`}
              id="amenity-checkbox"
              multiple
              value={formData.amenityIds || []}
              onChange={handleChange}
              input={<OutlinedInput label="Amenity" />}
              renderValue={(selected) => {
                const selectedAmenities = options.filter(option => selected.includes(option.id));
                return selectedAmenities.map(amenity => amenity.name).join(', ');
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  onClick={(e) => {
                    if (e.target.tagName !== 'INPUT') {
                      handleCheckboxChangeAmenity("amenityIds", option.id);
                    }
                  }}
                >
                  <Checkbox
                    checked={formData.amenityIds?.includes(option.id) || false}
                    onChange={() => handleCheckboxChangeAmenity("amenityIds", option.id)}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            {error ? (
                <div style={{color:'#dc3545', fontSize:'.875em'}}>{error}</div>
              ) : ''}
        </div>
      </div>
    )
  } else if (type === 'stayType') {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
      <div className="mb-3" ref={dropdownRef}>
        <label htmlFor={name} className="form-label">{labelStar}</label>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{
            m: 1,
            width: '100%',
          }}
        >
          <Select
            labelId="stay-type-checkbox-label"
            className={`form-select ${error ? 'is-invalid' : ''}`}
            id="stay-type-checkbox"
            multiple
            value={formData.stayType ? formData.stayType.split(',') : []}
            onChange={(e) => {
              const selectedValues = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                stayType: selectedValues.join(','),
              }));
            }}
            input={<OutlinedInput label="Stay Type" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {stayType && stayType.length > 0 ? (
              stayType.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={formData.stayType ? formData.stayType.includes(option) : false}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>No stayType available</MenuItem>
            )}
          </Select>
          {error && <div style={{ color: '#dc3545', fontSize: '.875em' }}>{error}</div>}
        </FormControl>
      </div>
    </div>
    )
  } else if (type === 'tags') {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
      <div className="mb-3" ref={dropdownRef}>
        <label htmlFor={name} className="form-label">{labelStar}</label>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{
            m: 1,
            width: '100%',
          }}
        >
          <Select
            labelId="stay-type-checkbox-label"
            className={`form-select ${error ? 'is-invalid' : ''}`}
            id="stay-type-checkbox"
            multiple
            value={formData.tags ? formData.tags.split(',') : []}
            onChange={(e) => {
              const selectedValues = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                tags: selectedValues.join(','),
              }));
            }}
            input={<OutlinedInput label="Stay Type" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {tags && tags.length > 0 ? (
              tags.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={formData.tags ? formData.tags.includes(option) : false}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>No tags available</MenuItem>
            )}
          </Select>
          {error && <div style={{ color: '#dc3545', fontSize: '.875em' }}>{error}</div>}
        </FormControl>
      </div>
    </div>
    )
  } else if (type === 'date') {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
        <div className="mb-3">
          <label htmlFor={name} className="form-label">{labelStar}</label>
          <Flatpickr
            data-enable-time
            className={`form-control ${error ? 'is-invalid' : ''}`}
            value={value}
            onChange={handleDateChange}
            placeholder={`Enter ${label}`}
            options={{
              enableTime: true,
              dateFormat: 'd-M-Y',
              monthSelectorType: 'static',
              time_24hr: true,
            }}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    );
  }else if (type === 'flatDesc') {
    return (
      <div className="col-lg-12 pt-1" style={{zIndex:0}}>
        <div className="mb-3" ref={dropdownRef}>
        <label htmlFor="flatDesc" className="form-label">Flat Description <span className='required-field'>*</span></label>
        <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            value={formData.flatDesc || ''} 
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist", "autolink", "link", "image", "lists", "charmap",
                "preview", "anchor", "pagebreak", "searchreplace", "wordcount",
                "visualblocks", "code", "fullscreen", "insertdatetime", "media",
                "table", "emoticons", "template", "help",
              ],
              toolbar:
                "undo redo | styles | bold italic | forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | preview media fullscreen | emoticons | help",
            }}
            onEditorChange={handleEditorChange}
          />
           {error ? (
                <div style={{color:'#dc3545', fontSize:'.875em'}}>{error}</div>
              ) : ''}
        </div>
      </div>
    )
  }
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 pt-3">
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{labelStar}</label>
        <input
          type={type || 'text'}
          name={name}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          placeholder={`Enter ${label}`}
          value={value}
          onChange={onChange}
          disabled={name === 'flatUrl' ? true : false}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};