import React, { useEffect, useState } from 'react';
import { fetchVendor, deleteVendor } from '../../../services/VendorService';
import { paginateData, calculateTotalPages } from '../../../assets/js/script';
import TableHeader from '../../Common/TableComponent/TableHeader';
import EntriesDropdown from '../../Common/TableComponent/EntriesDropdown';
import TablesRow from '../../Common/TableComponent/TablesRow';
import { Pagination } from '../../Common/TableComponent/Pagination';
import { Loading } from '../../Common/OtherElements/Loading';
import Swal from 'sweetalert2';
import { confirmDelete } from '../../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../utils/errorHandler';
import { AddVendor } from './AddVendor';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';


export const ManageVendorContent = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [managePageGroup, setManagePageGroup] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPageGroup, setSelectedPageGroup] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'vendor-master';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetchVendor();
            setManagePageGroup(response);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredData = managePageGroup.filter(item =>
        item.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentData = paginateData(filteredData, currentPage, entriesPerPage);
    const totalPages = calculateTotalPages(filteredData.length, entriesPerPage);

    const handleEntriesChange = (value) => {
        setEntriesPerPage(value);
        setCurrentPage(1);
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const handleDelete = async (groupId) => {
        const confirmed = await confirmDelete('Vendor');
        if (confirmed) {
            try {
                await deleteVendor(groupId);
                setManagePageGroup(prev => prev.filter(item => item.id !== groupId));
                Swal.fire('Deleted!', 'Vendor has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    return (
        <>
            {pageAccessDetails.addAccess ? (
                <AddVendor
                    editMode={editMode}
                    initialData={selectedPageGroup}
                    onSuccess={fetchData}
                    setSelectedPageGroup={setSelectedPageGroup}
                    setEditMode={setEditMode}
                />
            ) : ''}
            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Manage Vendor</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="pagination-details-responsive justify-content-between mb-3">
                                    <EntriesDropdown
                                        entriesPerPage={entriesPerPage}
                                        onEntriesChange={handleEntriesChange}
                                    />
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="form-control mb-2"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className="table align-middle table-bordered">
                                            <TableHeader columns={['#', 'Vendor Name', 'Building Number', 'City', 'State', 'Pincode', 'GST No', 'Business Name', 'Action']} />
                                            <tbody className="manage-page-group-table-values">
                                                {currentData.length === 0 ? (
                                                    <TableDataStatusError colspan="9" />
                                                ) : (
                                                    currentData.map((item, index) => (
                                                        <TablesRow
                                                            key={item.id}
                                                            rowData={{
                                                                id: (currentPage - 1) * entriesPerPage + index + 1,
                                                                vendorName: (
                                                                    <>
                                                                        <div>{item.vendorName}</div>
                                                                        <div>
                                                                            <i className='ri-mail-fill' style={{ fontSize: '12px' }}></i> <span style={{ fontWeight: 600 }}>{item.emailAddress}</span>
                                                                        </div>
                                                                        <div>
                                                                            <i className='ri-phone-fill' style={{ fontSize: '12px' }}></i> <span style={{ fontWeight: 600 }}>{item.contactNo}</span>
                                                                        </div>


                                                                    </>
                                                                ),
                                                                buildingno: item.buildingNo,
                                                                city: item.city,
                                                                state: item._State,
                                                                pincode: item.pincode,
                                                                gstno: item.gstin,
                                                                businessname: item.businessName,
                                                                addedOn: new Date(item.addedOn).toLocaleDateString(),
                                                            }}
                                                            columns={['id', 'vendorName', 'buildingno', 'city', 'state', 'pincode', 'gstno', 'businessname']}
                                                            hideIcons={false}
                                                            onEdit={() => {
                                                                setSelectedPageGroup(item);
                                                                setEditMode(true);
                                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                            }}
                                                            onDelete={() => handleDelete(item.id)}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    totalEntries={filteredData.length}
                                    entriesPerPage={entriesPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
};
