import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createSpecs = async (cityData) => {
    const response = await axiosInstance.post('masters/asset-specs', { specName: cityData.specName, }, { headers });
    return response.data;
};

export const fetchSpecss = async () => {
    const response = await axiosInstance.get('masters/asset-specs', { headers });
    return response.data;
};

export const deleteSpecs = async (brandid) => {
    const response = await axiosInstance.delete(`masters/asset-specs/${brandid}`, { headers });
    return response.data;
};

export const fetcSpecsById = async (brandId) => {
    const response = await axiosInstance.get(`masters/asset-specs/${brandId}`, { headers });
    return response.data.result;
};

export const updateSpecs = async (BrandData) => {
    const response = await axiosInstance.put('masters/asset-specs', BrandData, { headers });
    return response.data;
};