import React, { useState, useEffect } from "react";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { validatePaymentForm } from "../../utils/validation";
import { handleErrors } from "../../utils/errorHandler";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { addRentPayment, addUtilityPayment, addMaintenancePayment, addOtherPayment } from "../../services/orderManagementService";

const formatDate = (date) => {
    if (!date) return "-";
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
    return formattedDate.replace(/ /g, "-");
};


export const PaymentUpdateModal = ({ show, onClose, orderId, pageIdentify, onPaymentSuccess  }) => {
    const [formData, setFormData] = useState({
        orderGuid: orderId,
        paymentId: "",
        paidOn: "",
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            orderGuid: orderId,
        }));
    }, [orderId]);

    if (!show) return null;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date) => {
        const formattedDate = date.length ? formatDate(date[0]) : "";
        setFormData({ ...formData, paidOn: formattedDate });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validatePaymentForm(formData);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            try {
                setIsButtonDisabled(true);
                if (pageIdentify === 'rent') {
                    await addRentPayment(formData);
                } else if (pageIdentify === 'utility') {
                    await addUtilityPayment(formData);
                } else if (pageIdentify === 'maintenance') {
                    await addMaintenancePayment(formData);
                } else if (pageIdentify === 'other') {
                    await addOtherPayment(formData);
                }
                setIsButtonDisabled(false);
                toast.success("Payment Updated successful!");
                onPaymentSuccess();
                setFormData({
                    orderGuid: '',
                    paymentId: "",
                    paidOn: "",
                });
                onClose();
            } catch (error) {
                setIsButtonDisabled(false);
                handleErrors(error);
            }
        }
    };

    return (
        <div
            className="modal fade show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Payment</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Payment Id</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.paymentId ? "is-invalid" : ""}`}
                                    placeholder="Enter Payment Id"
                                    name="paymentId"
                                    value={formData.paymentId}
                                    onChange={handleInputChange}
                                />
                                {errors.paymentId && (
                                    <div className="invalid-feedback">{errors.paymentId}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Paid On</label>
                                <Flatpickr
                                    className={`form-control ${errors.paidOn ? "is-invalid" : ""}`}
                                    placeholder="Enter Paid On"
                                    value={formData.paidOn ? new Date(formData.paidOn) : ""}
                                    onChange={handleDateChange}
                                    options={{
                                        dateFormat: "d-M-Y",
                                        monthSelectorType: "static",
                                    }}
                                />
                                {errors.paidOn && (
                                    <div className="invalid-feedback">{errors.paidOn}</div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                                    Submit Payment
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
