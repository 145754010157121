import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const addonboardingCustomers = async (formData) => {
    const response = await axiosInstance.post('onboarding/customer', formData, { headers });
    return response.data.result;
  };

  export const updateOnboardingCustomers = async (payload) => {
    const response = await axiosInstance.put('onboarding/customer', payload, { headers });
    return response.data;
  };

  export const fetchOnboardingCustomersById = async (customerGuid) => {
    const response = await axiosInstance.get(`onboarding/customer/${customerGuid}`, { headers });
    return response.data;
  };

  export const paginationOnboardingCustomers = async (formData) => {
    const response = await axiosInstance.post('onboarding/view-customer', formData, {headers});
    return response;
  }

  export const deleteOnboardingCustomers = async (customerId) => {
    return await axiosInstance.delete(`onboarding/customer/${customerId}`, { headers });
};

export const orderManagementDetails = async(customerId) => {
  return await axiosInstance.get(`onboarding/customer-order/?custGuid=${customerId}`, {headers});
}

export const SuborderManagementDetails = async(customerId) => {
  return await axiosInstance.get(`onboarding/customer-monthly-order/?masterOrderGuid=${customerId}`, {headers});
}

export const updateKycStatus = async (customerGuid, kycStatus) => {
  return await axiosInstance.put(`onboarding/customer/kyc-status?customerGuid=${customerGuid}&kycStatus=${kycStatus}`, { headers });
};

export const updateCustomerStatus = async (customerGuid, customerStatus) => {
  return await axiosInstance.put(`onboarding/customer/status?customerId=${customerGuid}&customerStatus=${customerStatus}`, { headers });
};

export const paginationActiveOnboardingCustomers = async (formData) => {
  const response = await axiosInstance.post('onboarding/view-active-customer', formData, {headers});
  return response;
}