import React, { useCallback, useEffect, useState, useRef } from "react";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import {
    paginationMovedOutReport,
    fetchLocations,
} from "../../services/ReportsService";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from "../Common/TableComponent/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import * as XLSX from "xlsx";
import allImages from "../../assets/images-import";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";
import { fetchSWKProperty } from "../../services/SWKPropertService";

export const ManageMovedOutReport = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [selectedProperty, setSelectedProperty] = useState("");
    const [toDate, setToDate] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [searchLocations, setSearchLocation] = useState([]);
    const [locVal, setLocVal] = useState("");
    const [propertieslist, setPropertieslist] = useState([]);
    const searchInputRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const navigate = useNavigate();
    const PageLevelAccessurl = "reports/moved-out-report";
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);
    const [downloadBtnDisable, setDownloadBtnDisable] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedTerm, setSearchedTerm] = useState("");

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate("/404-error-page");
            } else {
                setPageAccessDetails(pageAccessData);
            }
        } else {
            console.log("No page access details found");
        }
    }, [pageAccessData, navigate]);

    const fetchProperties = useCallback(async () => {
        setLoading(true);
        const formData = {
            pageSize: entriesPerPage,
            pageNo: currentPage,
            fromDate,
            toDate,
            sParam: searchedTerm,
            propertyName:selectedProperty,
            location: locVal,
        };
        try {
            const response = await paginationMovedOutReport(formData);
            const { data } = response;
            setProperties(data.result);
            setTotalCount(data.result[0]?.totalCount || 0);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, [
        entriesPerPage,
        currentPage,
        fromDate,
        toDate,
        searchedTerm,
        selectedProperty,
        locVal,
    ]);

    useEffect(() => {
        fetchProperties();
    }, [fetchProperties]);

    const handleDownloadReport = async () => {
        const formData = {
            pageSize: totalCount,
            pageNo: 1,
            fromDate,
            toDate,
            sParam: searchedTerm,
            propertyName:selectedProperty,
            location: locVal,
        };

        try {
            setDownloadBtnDisable(true);
            const response = await paginationMovedOutReport(formData);
            const { data } = response;
            const allProperties = data.result;
            setDownloadBtnDisable(false);
            const excelData = allProperties.map((item, index) => ({
                "#": index + 1,
                "User Name": item.userName,
                "Email Address": item.emailAddress,
                "Contact Number": item.contactNo,
                "Property Name": item.propertyName,
                "Property Location": item.propertyLocation,
                "Flat Name": item.flatName,
                "Flat Number": item.flatNo,
                "Move In Date": formatDate(item.moveInDate),
                "Move Out Date": formatDate(item.moveOutDate),
                "Updated By":item.updatedBy,
                "Updated Date": formatDate(item.updatedOn),
            }));

            const worksheet = XLSX.utils.json_to_sheet(excelData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Moved Out Reports");

            XLSX.writeFile(workbook, "Moved_out_Report.xlsx");
        } catch (error) {
            handleErrors(error);
        }
    };

    const loadLocationOptions = (inputValue, callback) => {
        setTimeout(() => {
            const filteredOptions = searchLocations
                .filter((loc) =>
                    loc.locationName.toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((loc) => ({
                    label: loc.locationName,
                    value: loc.locationName,
                }));
            callback(filteredOptions);
        }, 1000);
    };

    useEffect(() => {
        const getProperties = async () => {
          try {
            const result = await fetchSWKProperty();
            setPropertieslist(result);
          } catch (error) {
            console.error("Error fetching properties:", error);
          }
        };
        getProperties();
      }, []);

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = propertieslist.filter((property) =>
          property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );
    
        setTimeout(() => {
          callback(
            filteredProperties.map((property) => ({
              label: property.propertyName,
              value: property.propertyGuid,
            }))
          );
        }, 1000);
      };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setLocVal(selectedOption?.value || "");
    };

    const fetchCitiesData = async () => {
        try {
            const locationsData = await fetchLocations();
            setSearchLocation(locationsData.result);
        } catch (error) {
            handleErrors(error);
        }
    };
    useEffect(() => {
        fetchCitiesData();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handleSearchClick = () => {
        setSearchedTerm(searchTerm);
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const totalPages = Math.ceil(totalCount / entriesPerPage);


    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const options = { year: "numeric", month: "long", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    };
    return (
        <>
            <style>
                {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(8){ display:none;} 
                `}
            </style>
            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header responsive-filter-type justify-content-between">
                                <h5 className="mb-sm-2 mt-sm-2">Moved Out Report</h5>
                                {pageAccessDetails.downloadAccess ? (
                                    <button
                                        className="btn btn-download"
                                        onClick={handleDownloadReport}
                                        disabled={downloadBtnDisable}
                                    >
                                        <img
                                            src={allImages.Excel}
                                            alt="Flat Report Export to excel"
                                            width="24px"
                                        />{" "}
                                        <span>
                                            {downloadBtnDisable ? "Exporting..." : "Export To Excel"}
                                        </span>
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="responsive-filter mb-3 row gy-3">
                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                        <label htmlFor="entriesPerPage" className="form-label me-2">
                                            Show entries:
                                        </label>
                                        <select
                                            className="form-select"
                                            id="entriesPerPage"
                                            value={entriesPerPage}
                                            onChange={handleEntriesPerPageChange}
                                        >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                    <div className="date-filters  col-lg-2 col-md-6 col-sm-12">
                                        <label htmlFor="fromDate" className="form-label me-2">
                                            From Date:
                                        </label>
                                        <Flatpickr
                                            id="fromDate"
                                            className="form-control"
                                            placeholder="Select From Date"
                                            value={fromDate}
                                            onChange={([date]) => setFromDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: "static",
                                            }}
                                        />
                                    </div>

                                    <div className="date-filters col-lg-2 col-md-6 col-sm-12">
                                        <label htmlFor="toDate" className="form-label me-2">
                                            To Date:
                                        </label>
                                        <Flatpickr
                                            id="toDate"
                                            className="form-control"
                                            placeholder="Select To Date"
                                            value={toDate}
                                            onChange={([date]) => setToDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: "static",
                                            }}
                                        />
                                    </div>
                                    <div className="search-input col-lg-3 col-md-6 col-sm-12">
                                        <label htmlFor="search" className="form-label me-2">
                                            Search:
                                        </label>
                                        <AsyncSelect
                                            cacheOptions
                                            loadOptions={loadPropertyOptions}
                                            defaultOptions={propertieslist.map((property) => ({
                                                label: property.propertyName,
                                                value: property.propertyGuid,
                                            }))}
                                            onChange={(selectedOption) =>
                                                setSelectedProperty(selectedOption?.label || "")
                                            }
                                            value={
                                                selectedProperty
                                                    ? {
                                                        label: selectedProperty,
                                                        value: propertieslist.find(
                                                            (property) =>
                                                                property.propertyName === selectedProperty
                                                        )?.propertyGuid,
                                                    }
                                                    : null
                                            }
                                            isClearable
                                            placeholder="Select Property Name"
                                        />
                                    </div>
                                
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <label htmlFor="location" className="form-label me-2">
                                            Location:
                                        </label>
                                        <AsyncSelect
                                            cacheOptions
                                            loadOptions={loadLocationOptions}
                                            defaultOptions={searchLocations.map((loc) => ({
                                                label: loc.locationName,
                                                value: loc.locationName,
                                            }))}
                                            value={selectedOption}
                                            onChange={handleChange}
                                            placeholder="Select Location"
                                            isClearable
                                        />
                                    </div>

                                    <div className="search-input col-lg-3 col-md-6 col-sm-12">
                                        <label htmlFor="search" className="form-label me-2">
                                            Search:
                                        </label>
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Search...."
                                            ref={searchInputRef}
                                        />


                                    </div>

                                    <div className=" col-lg-3 col-md-6 col-sm-12 pt-2">
                                        <button
                                            className="btn btn-secondary btn-properties-search"
                                            onClick={handleSearchClick}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>

                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table table-nowrap align-middle table-bordered">
                                            <TableHeader
                                                columns={[
                                                    '#', 'User Details', 'Flat Details', 'Period', 'Rent Amount','Updated By','Updated On'
                                                ]}
                                            />
                                            <tbody className="manage-amenity-master-table-values p-3">
                                                {properties.length > 0 ? (
                                                    properties.map((city, index) => (
                                                        <TablesRow
                                                            key={city.id}
                                                            rowData={{
                                                                id:
                                                                    (currentPage - 1) * entriesPerPage +
                                                                    index +
                                                                    1,
                                                                userDetails: (
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            <h5 className="fs-14 my-1">
                                                                                <Link to={`/onboarding-customers/detail/${city.userGuid}`}>{city.userName}</Link>
                                                                            </h5>
                                                                            <span><i className="ri-mail-fill"></i> {city.emailAddress}</span><br />
                                                                            <span><i className="ri-phone-fill"></i> {city.contactNo}</span>
                                                                        </div>
                                                                    </div>
                                                                ),
                                                                flatDetails: (
                                                                    <>
                                                                        <h5 className="fs-14 my-1 fw-normal"><Link to={`/property/detail/${city.propertyGuid}`} style={{ color: '#108b34' }}>{city.propertyName}</Link></h5>
                                                                        <span>Flat Number : {city.flatNo} </span>
                                                                    </>
                                                                ),
                                                                period: (
                                                                    <span>
                                                                        {new Date(city.moveInDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} -
                                                                        {new Date(city.moveOutDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                                                    </span>
                                                                ),
                                                                rentAmount: `Rs. ${city.rentAmount}`,
                                                                updatedBy: city.updatedBy,
                                                                updatedOn:new Date(city.updatedOn).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} }
                                                            columns={[
                                                                'id', 'userDetails', 'flatDetails', 'period', 'rentAmount','updatedBy','updatedOn'
                                                            ]}
                                                            hideIcons={false}
                                                            showIcons={false}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                ) : (
                                                    <TableDataStatusError colspan="10" />
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    totalEntries={totalCount}
                                    entriesPerPage={entriesPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};
