import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const SupportTimeline = ({ customerData }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const SupportTimelineData = customerData.supportActivities;

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(',', '');
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    return (
        <div className="card mt-xxl-n5 timeline-background">
            <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Support Timeline Details</h5>
            </div>
            <div className="card-body p-4">
                <div className="row justify-content-center">
                    <div className="col-xxl-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-body p-4">
                                    <div className="row g-3">
                                        {SupportTimelineData && SupportTimelineData.length > 0 ? (
                                            <div className="timeline">
                                                {SupportTimelineData.map((event, index) => (
                                                    <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                                                        <i className='icon ri-stack-line'></i>
                                                        <div className="date">{formatDate(event.updatedOn)}</div>
                                                        <div className="content">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1 ms-3">
                                                                   
                                                                        {event.activityType === 'Created' ?
                                                                            <h5><span className="badge rounded-pill badge-soft-primary">{event.activityType}</span></h5> :
                                                                            event.activityType === 'Closed' ?
                                                                                <h5><span className="badge rounded-pill badge-soft-danger">{event.activityType}</span></h5> :
                                                                                event.activityType === 'InProgress' ?
                                                                                    <h5><span className="badge rounded-pill badge-soft-danger">{event.activityType}</span></h5> :
                                                                                    event.activityType === 'Resolved' ?
                                                                                        <h5><span className="badge rounded-pill badge-soft-success">{event.activityType}</span></h5> :
                                                                                        event.activityType
                                                                        }
                                                                   
                                                                    <p className="mb-2 fw-semibold" style={{ color: '#150b6d' }}>Attachment</p>
                                                                    {event.attachment ? (
                                                                        <img
                                                                            src={event.attachment}
                                                                            alt="Support management attachment"
                                                                            width='100px'
                                                                            height='100px'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleImageClick(event.attachment)}
                                                                        />
                                                                    ) : '-'}
                                                                    <p className="text-muted mb-2 mt-2">{event.remarks}</p>
                                                                    <div>
                                                                        <p className="mb-1"><strong>Updated By:</strong> {event.updatedBy}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="mb-1"><strong>
                                                No timeline data available for this asset.</strong></p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Attachment Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {selectedImage && (
                        <img src={selectedImage} alt="Support Attachment" className="img-fluid" />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};
