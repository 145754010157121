import React, { useState, useEffect, useRef, useCallback } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import "flatpickr/dist/flatpickr.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import { validatePersonalDetails, validateUpdatePersonalDetails } from './../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../utils/errorHandler';
import { addonboardingCustomers, fetchOnboardingCustomersById, updateOnboardingCustomers } from './../../services/onboardingCustomerService';
import { Link } from 'react-router-dom';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { GetCountries, GetState } from "react-country-state-city";
import Select from 'react-select';

export const PersonalDetails = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryApiValue, setCountryApiValue] = useState('');
    const [age, setAge]= useState(false);
    const { customerGuid } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState({
        firstName: '', lastName: '', gender: '', emailAddress: '', phoneNo: '', dob: '', whatsApp: '', altEmail: '', altPhoneNo: '', nationality: '', streetAddress: '', city: '', state: '', country: '', pincode: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [PageLevelAccessurl, setPageLevelAccessurl] = useState();

    const convertDateToFormat = (dateString) => {
        const [day, month, year] = dateString.split('/');
        
        const date = new Date(year, month - 1, day);
        
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/\s/g, '-');
      };

      const convertDateToNumericFormat = (dateString) => {
        const monthMap = {
          jan: '01',feb: '02',mar: '03',apr: '04',may: '05',jun: '06',jul: '07',aug: '08',sep: '09',
          oct: '10',nov: '11',dec: '12',
        };
      
        const [day, monthAbbr, year] = dateString.split('-');
        try{
            const month = monthMap[monthAbbr.toLowerCase()];
            return `${day}/${month}/${year}`;
        } catch(err){
            toast.error('Date of Birth obtained is not in proper format');
        }
        
      };

    useEffect(() => {
        GetCountries().then((result) => {
            const formattedCountries = result.map((country) => ({
                label: country.name,
                value: country.id,
            }));
            setCountries(formattedCountries);
        });
    }, []);


    const handleCountryChange = useCallback((selectedOption) => {
        setValues({ ...values, country: selectedOption.label })
        setCountryApiValue(selectedOption.label);
        setSelectedCountry(selectedOption);
        setStates([]);
        if (selectedOption) {
            GetState(selectedOption.value).then((result) => {
                const formattedStates = result.map((state) => ({
                    label: state.name,
                    value: state.id,
                }));
                setStates(formattedStates);
            });
        }
    }, [values]);
    console.log(selectedCountry);

    const handleStateChange = useCallback((selectedOption) => {
        setValues({ ...values, state: selectedOption.label })
    }, [values]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
    
        let formattedValue = value;
        if (/^\d{2}$/.test(value)) {
          formattedValue = `${value}/`;
        } else if (/^\d{2}\/\d{2}$/.test(value)) {
          formattedValue = `${value}/`;
        }
    
        setValues({ ...values, [name]: formattedValue });
    
        const ddmmyyyyPattern =
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;
        if (ddmmyyyyPattern.test(formattedValue)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dob: "",
          }));
        } else if (formattedValue.length === 10) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dob: "Invalid date format. Use dd/mm/yyyy.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dob: "",
          }));
        }
      };

    useEffect(() => {
        if (customerGuid) {
            setPageLevelAccessurl('onboarding-customers/update/:customerGuid');
        } else {
            setPageLevelAccessurl('onboarding-customers/add');
        }
    }, [customerGuid])
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (customerGuid) {
                if (!pageAccessData.editAccess) {
                    navigate('/404-error-page');
                } else {
                    return;
                }
            } else {
                if (!pageAccessData.addAccess) {
                    navigate('/404-error-page');
                } else {
                    return;
                }
            }

        } else {
            console.log('No page access details found');
        }
    })


    useEffect(() => {
        if (customerGuid) {
            setIsDisabled(true);
            const fetchData = async () => {
                try {
                    const apidata = await fetchOnboardingCustomersById(customerGuid);
                    const data = apidata.result;
                    setValues({
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        gender: data.gender || '',
                        emailAddress: data.emailAddress || '',
                        phoneNo: data.phoneNo || '',
                        dob: data.dob ? convertDateToNumericFormat(data.dob) : '',
                        whatsApp: data.whatsApp || '',
                        altEmail: data.altEmail || '',
                        altPhoneNo: data.altPhoneNo || '',
                        nationality: data.nationality || '',
                        streetAddress: data.streetAddress || '',
                        city: data.city || '',
                        state: data.state || '',
                        country: data.country || '',
                        pincode: data.pincode || ''
                    });
                } catch (error) {
                    console.error("Error fetching customer data:", error);
                }
            };
            fetchData();
        } else {
            setValues({
                firstName: '',
                lastName: '',
                gender: '',
                emailAddress: '',
                phoneNo: '',
                dob: '',
                whatsApp: '',
                altEmail: '',
                altPhoneNo: '',
                nationality: '',
                streetAddress: '',
                city: '',
                state: '',
                country: '',
                pincode: ''
            });
            setIsDisabled(false);
        }
    }, [customerGuid]);

    const handleCountryChangeRef = useRef(handleCountryChange);

    useEffect(() => {
        handleCountryChangeRef.current = handleCountryChange;
    }, [handleCountryChange]);

    const handleStateChangeRef = useRef(handleStateChange);

    useEffect(() => {
        handleStateChangeRef.current = handleStateChange;
    }, [handleStateChange]);


    useEffect(() => {
        const selectedOption = countries.find((option, index) => {
            if (option.label === values.country) {
                option.value = index;
                return true;
            }
            return false;
        });

        if (selectedOption) {
            handleCountryChangeRef.current(selectedOption);
        }
    }, [values.country, countries]);

    useEffect(() => {
        const selectedOption = states.find((option, index) => {
            if (option.label === values.state) {
                option.value = index;
                return true;
            }
            return false;
        });

        if (selectedOption) {
            handleStateChangeRef.current(selectedOption);
        }
    }, [values.state, states]);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    const handlePhoneChange = (phone) => setValues({ ...values, phoneNo: phone });
    const handleWhatsAppChange = (phone) => setValues({ ...values, whatsApp: phone });
    const handleAltPhoneChange = (phone) => setValues({ ...values, altPhoneNo: phone });

    const validateDateFormat = (date) => {
        const ddmmyyyyPattern =
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;
        const ddmmmyyyyPattern =
          /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/;
        return ddmmyyyyPattern.test(date) || ddmmmyyyyPattern.test(date);
      };

      let validationErrors;
    const handleSubmit = async (e, action) => {
        e.preventDefault();
        if (customerGuid) {
            validationErrors = validateUpdatePersonalDetails(values);
        } else{
            validationErrors = validatePersonalDetails(values);
        }
        
        setErrors(validationErrors);

        if (!values.dob) {
            errors.dob = "Date of Birth is required.";
          } else if (!validateDateFormat(values.dob)) {
            errors.dob = "Invalid date format. Use dd/mm/yyyy";
          } else {
            const [day, month, year] = values.dob.split("/");
            const dob = new Date(`${year}-${month}-${day}`);
            const today = new Date();
            const age = today.getFullYear() - dob.getFullYear();
            const monthDifference = today.getMonth() - dob.getMonth();
            if (
              monthDifference < 0 ||
              (monthDifference === 0 && today.getDate() < dob.getDate())
            ) {
            }
            if (age < 18) {
                setAge(true);
            } else{
                setAge(false);
            }
          }

        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            try {
                const formattedDob = values.dob ? convertDateToFormat(values.dob) : null;
                const payload = {
                    userGuid: customerGuid,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    gender: values.gender,
                    emailAddress: values.emailAddress,
                    phoneNo:values.phoneNo,
                    dob: formattedDob,
                    altEmail: values.altEmail,
                    altPhoneNo: values.altPhoneNo,
                    nationality: values.nationality,
                    streetAddress: values.streetAddress,
                    city: values.city,
                    state: values.state,
                    country: countryApiValue,
                    pincode: values.pincode
                };

                setIsButtonDisabled(true);
                let response;

                if (customerGuid) {
                    await updateOnboardingCustomers(payload);
                    toast.success("Customer details updated successfully!");
                    setIsButtonDisabled(false);
                } else {
                    response = await addonboardingCustomers({ ...values, dob: formattedDob });
                    toast.success("Customer onboarding successful!");
                    setValues({
                        firstName: '', lastName: '', gender: '', emailAddress: '', phoneNo: '', dob: '', whatsApp: '', altEmail: '',
                        altPhoneNo: '', nationality: '', streetAddress: '', city: '', state: '', country: '', pincode: ''
                    });
                    setIsButtonDisabled(false);
                }

                if (action === 'addOrders') {
                    navigate(`/onboarding-customers/add-order/${response.cust_id}`);
                } else {
                    console.log('updation successful');
                }
            } catch (error) {
                handleErrors(error);
                setIsButtonDisabled(false);
            } finally {
                setIsButtonDisabled(false);
                setIsSubmitting(false);
            }
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Onboard Customer Details</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                {customerGuid ? (
                                    <>
                                        <li className="breadcrumb-item">
                                            <Link to='/onboarding-customers'>
                                                Manage Onboard Customers
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            Update Onboard Customer
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="breadcrumb-item">
                                            Add Onboard Customer
                                        </li>
                                    </>
                                )}

                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xxl-8">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-1 mt-sm-1">Onboard Customer Details</h5>
                            </div>
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="firstName" className="form-label">First Name <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={values.firstName}
                                                placeholder='Enter First Name'
                                                onChange={handleChange}
                                                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            />
                                            {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={values.lastName}
                                                placeholder='Enter Last Name'
                                                onChange={handleChange}
                                                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="emailAddress" className="form-label">Email <span className='required-field'>*</span></label>
                                            <input
                                                type="email"
                                                name="emailAddress"
                                                value={values.emailAddress}
                                                placeholder='Enter Email Address'
                                                onChange={handleChange}
                                                className={`form-control ${errors.emailAddress ? 'is-invalid' : ''}`}
                                                // disabled={isDisabled}
                                            />
                                            {errors.emailAddress && <p className="text-danger">{errors.emailAddress}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="phonenumber" className="form-label">Phone Number <span className='required-field'>*</span></label>
                                            <PhoneInput
                                                international
                                                value={values.phoneNo}
                                                onChange={handlePhoneChange}
                                                // disabled={isDisabled}
                                                defaultCountry='IN'
                                                maxLength="15"
                                                placeholder='Enter Phone Number'
                                            />
                                            {errors.phoneNo && <div style={{ color: '#dc3545' }}>{errors.phoneNo}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label">Gender <span className='required-field'>*</span></label>
                                            <select
                                                className={`form-select ${errors.gender ? 'is-invalid' : ''}`}
                                                name="gender"
                                                value={values.gender}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            {errors.gender && <p className="text-danger">{errors.gender}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="country" className="form-label">Country <span className='required-field'>*</span></label>
                                            <Select
                                                options={countries}
                                                placeholder="Select your Country*"
                                                value={countries.find((option) => option.label === values.country) || null}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        handleCountryChange(selectedOption);
                                                    }
                                                }}
                                                className={errors.country ? "is-invalid" : ""}
                                            />
                                            {errors.country && <p className="text-danger">{errors.country}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="state" className="form-label">State <span className='required-field'>*</span></label>
                                            <Select
                                                options={states}
                                                placeholder="Select your State*"
                                                value={states.find((option) => option.label === values.state) || null}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        handleStateChange(selectedOption);
                                                    }
                                                }}
                                                className={errors.state ? "is-invalid" : ""}
                                                // isDisabled={!selectedCountry}
                                            />

                                            {errors.state && <p className="text-danger">{errors.state}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">City <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="city"
                                                value={values.city}
                                                placeholder='Enter City'
                                                onChange={handleChange}
                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                            />
                                            {errors.city && <p className="text-danger">{errors.city}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="pincode" className="form-label">Pincode <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="pincode"
                                                value={values.pincode}
                                                placeholder='Enter Pincode'
                                                onChange={handleChange}
                                                className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                            />
                                            {errors.pincode && <p className="text-danger">{errors.pincode}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="streetAddress" className="form-label">Street Address <span className='required-field'>*</span></label>
                                            <textarea
                                                name="streetAddress"
                                                value={values.streetAddress}
                                                placeholder='Enter Street Address'
                                                onChange={handleChange}
                                                className={`form-control ${errors.streetAddress ? 'is-invalid' : ''}`}
                                                rows="3"
                                            />
                                            {errors.streetAddress && <p className="text-danger">{errors.streetAddress}</p>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col xxl-4">
                        <div className="card mt-xxl-n5">
                            <div className="card-body p-4">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="dob" className="form-label">Date of Birth <span className='required-field'>*</span></label>
                                        <input
                                           type="text"
                                           name="dob"
                                           placeholder="dd/mm/yyyy"
                                           maxLength={10}
                                           value={values.dob}
                                           onChange={handleDateChange}
                                           className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                        />
                                        {age ? (
                                            <p className="text-danger">Age must be greater than 18</p>
                                        ) : ''}
                                        {errors.dob && <p className="text-danger">{errors.dob}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="altEmail" className="form-label">Alternate Email</label>
                                        <input
                                            type="email"
                                            name="altEmail"
                                            value={values.altEmail}
                                            placeholder='Enter Alternate Email Address'
                                            onChange={handleChange}
                                            className={`form-control ${errors.altEmail ? 'is-invalid' : ''}`}
                                        />
                                        {errors.altEmail && <p className="text-danger">{errors.altEmail}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="whatsApp" className="form-label">WhatsApp Number</label>
                                        <PhoneInput
                                            international
                                            value={values.whatsApp}
                                            onChange={handleWhatsAppChange}
                                            disabled={isDisabled}
                                            placeholder='Enter whatsup Number'
                                            maxLength="15"
                                            defaultCountry='IN'
                                        />
                                        {errors.whatsApp && <p className="text-danger">{errors.whatsApp}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="nationality" className="form-label">Nationality</label>
                                        <input
                                            type="text"
                                            name="nationality"
                                            value={values.nationality}
                                            placeholder='Enter Nationality'
                                            onChange={handleChange}
                                            className={`form-control ${errors.nationality ? 'is-invalid' : ''}`}
                                        />
                                        {errors.nationality && <p className="text-danger">{errors.nationality}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="altPhoneNo" className="form-label">Alternate Phone Number</label>
                                        <PhoneInput
                                            international
                                            value={values.altPhoneNo}
                                            onChange={handleAltPhoneChange}
                                            defaultCountry='IN'
                                            maxLength="15"
                                            placeholder='Enter Alternative Phone Number'
                                        />
                                        {errors.altPhoneNo && <p className="text-danger">{errors.altPhoneNo}</p>}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                    {isSubmitting ? (customerGuid ? 'Updating' : 'Saving') : (customerGuid ? "Update" : "Save")}
                </button>
                {customerGuid ? '' : (
                    <button type="button" className="btn btn-success ms-3" onClick={(e) => handleSubmit(e, 'addOrders')}>
                        Save and Add Booking
                    </button>
                )
                }
            </form>
        </>
    );
};
