import React from 'react'
import { ToastContainer } from 'react-toastify';
import { ManageProductContent } from '../components/AppSettings/Product/ManageProductContent';
export const ProductMaster = () => {
  return (
    <div className="main-content">
    <div className="page-content">
        <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
            <ManageProductContent/>
        </div>
    </div>
</div>
  )
}
