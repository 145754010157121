import Avatar1 from './images/users/avatar-1.jpg';
import profileImage from './images/users/avatar-1.jpg';
import logoSm from './images/logo-sm.png';
import logoDark from './images/logo-dark.png';
import errorImagesvg from './images/error.svg';
import defaultprofile from './images/profile-img-default.png';
import playButton from './images/play-button.png';
import blankProfile from './images/blank-profile.webp';
import SigninPageBack from './images/siginpage-background.png';
import Excel from './images/excel.png';
const allImages  = {
  Excel,
  blankProfile,
  playButton,
  defaultprofile,
  errorImagesvg,
  Avatar1,
  profileImage,
  logoSm,
  logoDark,
  SigninPageBack
};

export default allImages ;
