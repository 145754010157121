import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createPropertyType = async (propertyTypeData) => {
    const response = await axiosInstance.post('masters/property-type', { propertyType: propertyTypeData.propertyType, }, { headers });
    return response.data;
};

export const fetchPropertyTypes = async () => {
    const response = await axiosInstance.get('masters/property-type', { headers });
    return response.data;
};

export const deletePropertyType = async (propertyTypeId) => {
    await axiosInstance.delete(`masters/property-type/${propertyTypeId}`, { headers });
};

export const fetchPropertyTypeById = async (id) => {
    const response = await axiosInstance.get(`masters/property-type/${id}`, { headers });
    return response.data.result;
};

export const updatePropertyType = async (propertyTypeData) => {
    const response = await axiosInstance.put('masters/property-type', { id: propertyTypeData.id, propertyType: propertyTypeData.propertyType, }, { headers });
    return response.data;
};