import React from 'react';

export const Newtrial = () => {


  return (
    <div>
      hello
    </div>
  );
};

