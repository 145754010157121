import React, { useCallback, useEffect, useState } from 'react';
import TableHeader from '../../../Common/TableComponent/TableHeader';
import TablesRow from '../../../Common/TableComponent/TablesRow';
import { useNavigate } from 'react-router-dom';
import { fetchPropertyOwnerAgreements, SignEStampAgreement } from '../../../../services/propertyOwnerService';
import { Loading } from '../../../Common/OtherElements/Loading';
import { TableDataStatusError } from '../../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../../utils/errorHandler';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ManageAllAgreements = ({ ownerguid, rerenderComp }) => {
    const [currentData, setCurrentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();
    const getFlats = useCallback(async () => {
        try {
            const data = await fetchPropertyOwnerAgreements(ownerguid);
            setCurrentData(data);
        } catch (err) {
            handleErrors(err);
        } finally {
            setLoading(false);
        }
    }, [ownerguid]);

    useEffect(() => {
        if (ownerguid) {
            getFlats();
        }
    }, [ownerguid, rerenderComp, getFlats]);

    const SignAgreement = async (agreementId) => {
        try {
            const data = await SignEStampAgreement(agreementId);
            setIsDisabled(true);
            getFlats();
            toast.success('Agreement Signed Successfully');
            window.open(data.signedDocLink, '_blank')
            setIsDisabled(false);
        } catch (error) {
            setIsDisabled(false);
            toast.error(error.response.data.message);
        }
    }

    return (
        <>
            <style>
                {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table-agreement td:nth-child(9){ display:none;} 
                `}
            </style>
            <div className="manage-flats-details">
                <div className="card-header">
                    <h5 className="mb-sm-2 mt-sm-2">Manage Agreements</h5>
                </div>
                <div className="card-body manage-amenity-master-card-body">

                    {loading ? (
                        <Loading />
                    ) : (
                        <table className="table align-middle table-bordered table-agreement">
                            <TableHeader columns={['Property', 'Owner Name', 'UnSigned Doc', 'Signed Doc', 'Stamp', 'Status', 'Created On', 'Signed On']} />
                            <tbody>
                                {currentData.length === 0 ? (
                                    <TableDataStatusError colspan={9} />
                                ) : (
                                    currentData.map((item) => (
                                        <TablesRow
                                            key={item.id}
                                            rowData={{
                                                property: (
                                                    <>
                                                        <span><Link to={`/property/detail/${item.propertyGuid}`}>{item.propertyName}</Link></span><br />
                                                        <span>{item.flatNos ? (`Flat Number:${item.flatNos}`) : ''}</span>
                                                    </>
                                                ),
                                                ownerName: item.ownerName || '-',
                                                unsignedDocLink: item.unsignedDocLink ? (
                                                    <span className="badge text-bg-primary" style={{ fontSize: '11px', cursor:'pointer' }} onClick={() => window.open(item.unsignedDocLink, '_blank')} >
                                                        View UnSigned Document
                                                    </span>

                                                ) : '-',
                                                signedDocLink: item.signedDocLink ? (
                                                    <span className="badge text-bg-warning" style={{ fontSize: '11px', cursor:'pointer' }} onClick={() => window.open(item.signedDocLink, '_blank')} >
                                                        View Signed Document
                                                    </span>
                                                ) : '-',
                                                stamp: (
                                                    item.status === 'Signed' ? '-' : (
                                                        <span 
                                                        className={`badge text-bg-success ${isDisabled ? 'disabled' : ''}`} 
                                                        style={{ 
                                                            fontSize: '11px', 
                                                            cursor: isDisabled ? 'not-allowed' : 'pointer', 
                                                            opacity: isDisabled ? 0.5 : 1 
                                                        }} 
                                                        onClick={() => !isDisabled && SignAgreement(item.id)} 
                                                    >
                                                        Sign the Document
                                                    </span>
                                                    )
                                                ),
                                                status: (
                                                    <span
                                                        style={{ fontSize: '12px' }}
                                                        className={`badge badge-soft-${item.status === 'Signed' ? 'success' : 'danger'} badge-border`}
                                                    >
                                                        {item.status === 'Signed' ? 'Signed' : 'Created'}
                                                    </span>
                                                ),
                                                createdOn: item.createdOn || '-',
                                                signedOn: item.signedOn || '-',
                                            }}
                                            columns={['property', 'ownerName', 'unsignedDocLink', 'signedDocLink', 'stamp', 'status', 'createdOn', 'signedOn']}
                                            hideIcons={false}
                                            showIcons={true}
                                            onEdit={() => {
                                                navigate(`/property/detail/update-flat/${item.id}`);
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}
                                            pageLevelAccessData=''
                                        />

                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>


            </div>
        </>
    );
};
