import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const addPropertyNearby = async (formData) => {
    return await axiosInstance.post(
      "properties/nearby",
      {
        nearByTypes: formData.nearByTypes,
        nearByName: formData.nearByName,
        distanceInKM: Number(formData.distanceInKM),
        nearByLink: formData.nearByLink,
        propertyGuid: formData.propertyGuid,
      }, { headers }
    );
};
export const getPropertyNearby = async (nearById) => {
  return await axiosInstance.get(`properties/nearby/all/${nearById}`, { headers, });
};
export const deletePropertyNearby = async (nearById) => {
  return await axiosInstance.delete(`properties/nearby/${nearById}`, { headers, });
};
export const updatePropertyNearby = async (propertyData) => {
    const response = await axiosInstance.put(
      `properties/nearby`,
      {
        id: propertyData.id,
        nearByTypes: propertyData.nearByTypes,
        nearByName: propertyData.nearByName,
        distanceInKM: Number(propertyData.distanceInKM),
        nearByLink: propertyData.nearByLink,
        propertyGuid: propertyData.propertyGuid,
      }, { headers }
    );
    return response.data;
};
export const fetchPropertyNearby = async (nearById) => {
  const response = await axiosInstance.get(`properties/nearby/${nearById}`, { headers, });
    return response.data.result;
};