import axiosInstance from '../Interceptors/axiosInstance.jsx'; 
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createAmenityMaster = async (amenityData) => {
    const response = await axiosInstance.post('masters/amenity', amenityData, { headers });
    return response.data; 
};

export const fetchAmenities = async () => {
    const response = await axiosInstance.get('masters/amenity', { headers });
    return response.data.result;
};

export const deleteAmenity = async (amenityId) => {
    await axiosInstance.delete(`masters/amenity/${amenityId}`, { headers });
};

export const fetchAmenityById = async (id) => {
  const response = await axiosInstance.get(`masters/amenity/${id}`, { headers });
  return response.data.result;
};

export const updateAmenity = async (payload) => {
    const response = await axiosInstance.put('masters/amenity', payload, { headers });
    return response.data;
};