import React, { useState, useEffect } from 'react';
import { validateImageForm, validateVideoForm } from '../../../../utils/validation';
import { AddFlatGallery } from '../../../../services/FlatGalleryService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrors } from '../../../../utils/errorHandler';

export const FileUploadSection = ({ flatId, onClick }) => {
    const [mediaType, setMediaType] = useState('');
    const [imageFiles, setImageFiles] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');
    const [fileNameImage, setFileNameImage] = useState('');
    const [fileAltImage, setFileAltImage] = useState('');
    const [fileNameVideo, setFileNameVideo] = useState('');
    const [fileAltVideo, setFileAltVideo] = useState('');
    const [imageUrls, setImageUrls] = useState([]);
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        return () => {
            imageUrls.forEach((url) => URL.revokeObjectURL(url));
        };
    }, [imageUrls]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            const newImageUrls = files.map((file) => URL.createObjectURL(file));
            setImageUrls((prevUrls) => [...prevUrls, ...newImageUrls]);
            setImageFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    const handleRemoveImage = (index) => {
        const urlToRevoke = imageUrls[index];

        URL.revokeObjectURL(urlToRevoke);

        setImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
        setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let combinedErrors = {};
    
        if (mediaType === 'image') {
            const imageValidationErrors = validateImageForm({ fileName: fileNameImage, fileAlt: fileAltImage });
            combinedErrors = { ...combinedErrors, ...imageValidationErrors };
    
            if (imageFiles.length === 0) {
                combinedErrors.imageFiles = 'Please upload at least one image.';
            }
        } else if (mediaType === 'video') {
            const videoValidationErrors = validateVideoForm({ fileName: fileNameVideo, fileAlt: fileAltVideo, videoUrl });
            combinedErrors = { ...combinedErrors, ...videoValidationErrors };
    
            if (!videoUrl) {
                combinedErrors.videoUrl = 'Please enter a video URL.';
            }
        } else if (mediaType === 'image' && videoUrl) {
            const imageValidationErrors = validateImageForm({ fileName: fileNameImage, fileAlt: fileAltImage });
            const videoValidationErrors = validateVideoForm({ fileName: fileNameVideo, fileAlt: fileAltVideo, videoUrl });
    
            combinedErrors = { ...imageValidationErrors, ...videoValidationErrors };
        }
    
        if (Object.keys(combinedErrors).length > 0) {
            setErrors(combinedErrors);
            return;
        } else {
            setErrors({});
        }
    
        const flatGuid = flatId;
        const uploadPromises = [];
    
        if (imageFiles.length > 0) {
            for (const currentImageFile of imageFiles) {
                setIsButtonDisabled(true);
                const formData = new FormData();
                formData.append('ImageFile', currentImageFile);
                formData.append('flatGuid', flatGuid);
                formData.append('fileType', 'Image');
                formData.append('fileAlt', fileAltImage);
                formData.append('fileNm', fileNameImage);
    
                const uploadPromise = AddFlatGallery(formData)
                    .then((response) => {
                        console.log('Image uploaded successfully!', response);
                        setIsButtonDisabled(false);
                    })
                    .catch((error) => {
                        handleErrors(error);
                        setIsButtonDisabled(false);
                    });
    
                uploadPromises.push(uploadPromise);
            }
        }
    
        if (videoUrl) {
            setIsButtonDisabled(true);
            const formData = new FormData();
            formData.append('flatGuid', flatGuid);
            formData.append('fileType', 'Video');
            formData.append('fileLink', videoUrl);
            formData.append('fileAlt', fileAltVideo);
            formData.append('fileNm', fileNameVideo);
    
            const videoUploadPromise = AddFlatGallery(formData)
                .then((response) => {
                    console.log('Video uploaded successfully!', response);
                    
                })
                .catch((error) => {
                    handleErrors(error);
                    setIsButtonDisabled(false);
                });
                setIsButtonDisabled(false);
            uploadPromises.push(videoUploadPromise);
        }
    
        try {
            await Promise.all(uploadPromises);
            setImageFiles([]);
            setImageUrls([]);
            setVideoUrl('');
            setFileNameImage('');
            setFileAltImage('');
            setFileNameVideo('');
            setFileAltVideo('');
            onClick();
            toast.success('All uploads completed!');
            setIsButtonDisabled(false);
        } catch (error) {
            handleErrors(error);
            setIsButtonDisabled(false);
        }
    };
    

    return (
        <>
            <h5 className="mb-sm-0 ms-3 gallery-modal-heading"><b>Add Gallery</b></h5>
            <form onSubmit={handleSubmit} className="modal-body">
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <label htmlFor="mediaType" className="form-label">Select Media Type <span className='required-field'>*</span></label>
                        <select
                            id="mediaType"
                            className="form-select"
                            value={mediaType}
                            onChange={(e) => setMediaType(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select Media Type...</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                        </select>
                    </div>
                </div>

                {mediaType === 'image' && (
                    <>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="fileNameImage" className="form-label">File Name <span className='required-field'>*</span></label>
                                <input
                                    type="text"
                                    id="fileNameImage"
                                    className={`form-control ${errors.fileName ? 'is-invalid' : ''}`}
                                    placeholder="Enter file name"
                                    value={fileNameImage}
                                    required
                                    onChange={(e) => setFileNameImage(e.target.value)}
                                />
                                {errors.fileName && <div className="text-danger">{errors.fileName}</div>}
                            </div>
                            <div className="col">
                                <label htmlFor="fileAltImage" className="form-label">File Alt Text <span className='required-field'>*</span></label>
                                <input
                                    type="text"
                                    id="fileAltImage"
                                    className={`form-control ${errors.fileAlt ? 'is-invalid' : ''}`}
                                    placeholder="Enter alt text"
                                    value={fileAltImage}
                                    required
                                    onChange={(e) => setFileAltImage(e.target.value)}
                                />
                                {errors.fileAlt && <div className="text-danger">{errors.fileAlt}</div>}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="imageUpload" className="form-label">Upload Image <span className='required-field'>*</span></label>
                                <input
                                    type="file"
                                    id="imageUpload"
                                    className="form-control"
                                    accept="image/*"
                                    multiple
                                    onChange={handleFileChange}
                                    required
                                />
                                <div className="mt-2">
                                    {imageFiles.length > 0 && (
                                        <span>{imageFiles.length} file{imageFiles.length > 1 ? 's' : ''} selected</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="image-preview">
                                    {imageUrls.length > 0 && imageUrls.map((url, index) => (
                                        <div key={url} className="image-container">
                                            <img
                                                src={url}
                                                alt={`Preview ${index + 1}`}
                                                className="uploaded-image"
                                            />
                                            <button
                                                type="button"
                                                className="remove-image-btn"
                                                onClick={() => handleRemoveImage(index)}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {mediaType === 'video' && (
                    <>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="videoUrl" className="form-label">Video URL <span className='required-field'>*</span></label>
                                <input
                                    type="text"
                                    id="videoUrl"
                                    className={`form-control ${errors.videoUrl ? 'is-invalid' : ''}`}
                                    placeholder="Enter video URL"
                                    required
                                    value={videoUrl}
                                    onChange={(e) => setVideoUrl(e.target.value)}
                                />
                                {errors.videoUrl && <div className="text-danger">{errors.videoUrl}</div>}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="fileNameVideo" className="form-label">File Name <span className='required-field'>*</span></label>
                                <input
                                    type="text"
                                    id="fileNameVideo"
                                    className={`form-control ${errors.fileName ? 'is-invalid' : ''}`}
                                    placeholder="Enter file name"
                                    required
                                    value={fileNameVideo}
                                    onChange={(e) => setFileNameVideo(e.target.value)}
                                />
                                {errors.fileName && <div className="text-danger">{errors.fileName}</div>}
                            </div>
                            <div className="col">
                                <label htmlFor="fileAltVideo" className="form-label">File Alt Text <span className='required-field'>*</span></label>
                                <input
                                    type="text"
                                    id="fileAltVideo"
                                    className={`form-control ${errors.fileAlt ? 'is-invalid' : ''}`}
                                    placeholder="Enter alt text"
                                    required
                                    value={fileAltVideo}
                                    onChange={(e) => setFileAltVideo(e.target.value)}
                                />
                                {errors.fileAlt && <div className="text-danger">{errors.fileAlt}</div>}
                            </div>
                        </div>
                    </>
                )}

                <div className="row">
                    <div className="col text-start">
                        <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                        {isButtonDisabled ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};
