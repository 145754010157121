import React, { useEffect, useState, useRef } from "react";
import {
  paginationOrders,
  updateOrderStatus,
} from "../../services/orderManagementService";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import Swal from "sweetalert2";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from "../Common/TableComponent/Pagination";
import { Link, useParams } from "react-router-dom";
import { BootstrapTooltip } from "../../assets/js/script";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";

export const ManageOrders = () => {
  const { month } = useParams();
  const [entriesPerPage, setEntriesPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [manageBlogs, setManageBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const PageLevelAccessurl = "orders";
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.viewAccess) {
        navigate("/404-error-page");
      } else {
        setPageAccessDetails(pageAccessData);
      }
    } else {
      console.log("No page access details found");
    }
  }, [pageAccessData, navigate]);

  useEffect(() => {
    if (month) {
      const currentYear = new Date().getFullYear();
      const startOfMonth = new Date(`${currentYear}-${month}-01`);
      const endOfMonth = new Date();
      setFromDate(startOfMonth.toISOString().split("T")[0]);
      setToDate(endOfMonth.toISOString().split("T")[0]);
    }
  }, [month]);

  const searchInputRef = useRef(null);
  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      const formData = {
        pageSize: entriesPerPage || 10,
        pageNo: currentPage || 1,
        fromDate: fromDate || "",
        toDate: toDate || "",
        sParam: searchedTerm || "",
      };
      try {
        const response = await paginationOrders(formData);
        const { data } = response;
        setManageBlogs(data.result);
        setTotalCount(data.result[0]?.totalCount || 0);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [entriesPerPage, currentPage, fromDate, toDate, searchedTerm]);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchClick = () => {
    setSearchedTerm(searchTerm);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const confirmAndExecute = async (orderGuid, orderStatus) => {
    const result = await Swal.fire({
      title: `Are you sure you want to ${orderStatus.toLowerCase()} this order?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      try {
        await updateOrderStatus(orderGuid, orderStatus);
        if (orderStatus === "Deleted") {
          setManageBlogs((prevOrders) =>
            Array.isArray(prevOrders)
              ? prevOrders.filter((order) => order.orderGuid !== orderGuid)
              : []
          );
        } else {
          setManageBlogs((prevOrders) =>
            prevOrders.map((order) =>
              order.orderGuid === orderGuid
                ? { ...order, orderStatus: orderStatus }
                : order
            )
          );
        }

        Swal.fire(
          "Success",
          `Order ${orderStatus.toLowerCase()}d successfully!`,
          "success"
        );
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const totalPages = Math.ceil(totalCount / entriesPerPage);

  return (
    <>
      <style>
        {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                      table td:nth-child(13){ display:none;} 
                `}
      </style>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-2 mt-sm-2">Manage Bookings</h5>
            </div>
            <div className="card-body manage-amenity-master-card-body">
              <div className="responsive-filter-type mb-3">
                <div className="entries-dropdown">
                  <label htmlFor="entriesPerPage" className="form-label me-2">
                    Show entries:
                  </label>
                  <select
                    className="form-select"
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                  >
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>
                </div>
                <div className="date-filters">
                  <label htmlFor="fromDate" className="form-label me-2">
                    From Date:
                  </label>
                  <Flatpickr
                    id="fromDate"
                    className="form-control"
                    placeholder="Select From Date"
                    value={fromDate}
                    onChange={([date]) => setFromDate(date)}
                    options={{
                      dateFormat: "Y-m-d",
                      monthSelectorType: "static",
                    }}
                  />
                </div>
                <div className="date-filters">
                  <label htmlFor="toDate" className="form-label me-2">
                    To Date:
                  </label>
                  <Flatpickr
                    id="toDate"
                    className="form-control"
                    placeholder="Select To Date"
                    value={toDate}
                    onChange={([date]) => setToDate(date)}
                    options={{
                      dateFormat: "Y-m-d",
                      monthSelectorType: "static",
                    }}
                  />
                </div>
                <div className="search-input">
                  <label htmlFor="search" className="form-label me-2">
                    Search:
                  </label>
                  <input
                    type="text"
                    id="search"
                    className="form-control"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search..."
                    ref={searchInputRef}
                  />
                </div>
                <div className="pt-2">
                  <button
                    className="btn btn-secondary btn-properties-search"
                    onClick={handleSearchClick}
                  >
                    Search
                  </button>
                </div>
              </div>

              {loading ? (
                <Loading />
              ) : (
                <div className="table-responsive">
                  <table className="table table-nowrap align-middle table-bordered">
                    <TableHeader
                      columns={[
                        "#",
                        "Customer Name",
                        "Order Id",
                        "Paid Amount",
                        "Balance Amount",
                        "Total Amount",
                        "Property",
                        "Status",
                        "Move In Date",
                        "Move Out Date",
                        "Created On",
                        "Action",
                      ]}
                    />
                    <tbody className="manage-page-group-table-values">
                      {manageBlogs.length === 0 ? (
                        <TableDataStatusError colspan="13" />
                      ) : (
                        manageBlogs.map((item, index) => (
                          <TablesRow
                            key={item.id}
                            rowData={{
                              id:
                                (currentPage - 1) * entriesPerPage + index + 1,
                              CustomerName: (
                                <>
                                  <div>{item.userName}</div>
                                  <div>
                                    <i
                                      className="ri-mail-fill"
                                      style={{ fontSize: "12px" }}
                                    ></i>{" "}
                                    <span style={{ fontWeight: 600 }}>
                                      {item.emailAddress}
                                    </span>
                                  </div>
                                  <div>
                                    <i
                                      className="ri-phone-fill"
                                      style={{ fontSize: "12px" }}
                                    ></i>{" "}
                                    <span style={{ fontWeight: 600 }}>
                                      {item.contactNo}
                                    </span>
                                  </div>
                                </>
                              ),
                              orderId: (
                                <Link
                                  to={`/orders/detail/${item.orderGuid}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  {item.orderId || "-"}
                                </Link>
                              ),
                              paidAmount: `Rs. ${item.paidAmount || "-"}`,
                              balanceAmount: `Rs. ${item.balanceAmount || "-"}`,
                              totalAmount: `Rs. ${item.totalAmount || "-"}`,
                              propertyName: (
                                <>
                                  <span>
                                    {item.propertyName} ({item.stayType})
                                  </span>
                                  <br />
                                  <span>
                                    <label>Flat Number: </label>
                                    {item.flatNo}{" "}
                                    <Link
                                      to={`https://sowerent.netlify.app/property/${item.flatUrl}-${item.flatId}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="ri-external-link-fill"></i>
                                    </Link>
                                  </span>
                                </>
                              ),
                              orderStatus: (
                                <>
                                  {item.orderStatus === "Initiated" && (
                                    <span className="badge rounded-pill badge-soft-primary">
                                      Initiated
                                    </span>
                                  )}
                                  {item.orderStatus === "Success" && (
                                    <span className="badge rounded-pill badge-soft-success">
                                      Success
                                    </span>
                                  )}
                                  {item.orderStatus === "Cancelled" && (
                                    <span className="badge rounded-pill badge-soft-danger">
                                      Cancelled
                                    </span>
                                  )}
                                </>
                              ),
                              moveInDate: new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }).format(new Date(item.moveInDate)),
                              moveOutDate: new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }).format(new Date(item.moveOutDate)),
                              orderCreatedOn: new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }).format(new Date(item.orderCreatedOn)),
                              changeorderStatus: (
                                <>
                                  <BootstrapTooltip title="Cancel Order">
                                    <span
                                      style={{
                                        fontSize: "17px",
                                        marginRight: "8px",
                                        cursor:
                                          item.orderStatus === "Cancelled"
                                            ? "not-allowed"
                                            : "pointer",
                                        color:
                                          item.orderStatus === "Cancelled"
                                            ? "gray"
                                            : "rgb(220 181 53)",
                                      }}
                                      onClick={() =>
                                        item.orderStatus !== "Cancelled" &&
                                        confirmAndExecute(
                                          item.orderGuid,
                                          "Cancelled"
                                        )
                                      }
                                    >
                                      <i className="ri-close-circle-fill" />
                                    </span>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip title="Delete Order">
                                    <span
                                      style={{
                                        fontSize: "17px",
                                        cursor:
                                          item.orderStatus === "Deleted"
                                            ? "not-allowed"
                                            : "pointer",
                                        color:
                                          item.orderStatus === "Deleted"
                                            ? "gray"
                                            : "#dc3545",
                                      }}
                                      onClick={() =>
                                        item.orderStatus !== "Deleted" &&
                                        confirmAndExecute(
                                          item.orderGuid,
                                          "Deleted"
                                        )
                                      }
                                    >
                                      <i className="ri-delete-bin-7-fill"></i>
                                    </span>
                                  </BootstrapTooltip>
                                </>
                              ),
                            }}
                            columns={[
                              "id",
                              "CustomerName",
                              "orderId",
                              "paidAmount",
                              "balanceAmount",
                              "totalAmount",
                              "propertyName",
                              "orderStatus",
                              "moveInDate",
                              "moveOutDate",
                              "orderCreatedOn",
                              "changeorderStatus",
                            ]}
                            hideIcons={false}
                            pageLevelAccessData={pageAccessDetails}
                          />
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                totalEntries={totalCount}
                entriesPerPage={entriesPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
