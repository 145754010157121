import React from 'react';

export const CompleteOwnerDetails = ({ ownerDetails }) => {
    const formatDate = (dateString) => {
        if (!dateString) return 'No Date Available';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric',
        }).format(date);
    };

    const customerDetailsFields = [
        { label: 'Owner First Name', value: ownerDetails.firstName || '-' },
        { label: 'Owner Last Name', value: ownerDetails.lastName || '-' },
        { label: 'Date of Birth', value: formatDate(ownerDetails.dob) || '-' },
        { label: 'Contact Number', value: ownerDetails.contactNo || '-' },
        { label: 'Email Address', value: ownerDetails.emailAddress || '-' },
        { label: 'Father Name', value: ownerDetails.fatherName || '-' },
        { label: 'Pan Number', value: ownerDetails.panNo || '-' },
    ];

    return (
        <div className="row justify-content-center">
            <div className="col-xxl-12">
                <div className="card-header">
                    <h5 className="mb-sm-2 mt-sm-2">Owner Details</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-body">
                            <div className="row g-3 pb-4">
                                {customerDetailsFields.map((field, index) => (
                                    <div className="col-lg-3 col-6 mb-3" key={index}>
                                        <p className="mb-2 fw-semibold">{field.label}</p>
                                        <h5 className="fs-14 mb-0">
                                            <span id="invoice-no" className='sub-title-detail'>
                                                {field.value}
                                            </span>
                                        </h5>
                                    </div>
                                ))}
                                <div className="col-lg-12 col-12 mb-3">
                                    <p className="mb-2 fw-semibold">Street Address</p>
                                    <h5 className="fs-14 mb-0">
                                        <span id="invoice-no" className='sub-title-detail'>
                                            {ownerDetails.completeAddress || '-'}
                                        </span>
                                    </h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}