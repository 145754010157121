import React, { useState, useEffect, useCallback } from 'react';
import { uploadExcelFile } from '../../services/AssetsService';
import ComponentHeader from '../Common/OtherElements/ComponentHeader';
import { validateExcelFileUpload } from '../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';
import { handleErrors } from '../../utils/errorHandler';
import allImages from '../../assets/images-import';

export const UploadAssetList = () => {
  const { id } = useParams();
  const [excelFile, setExcelFile] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [apiError, setApiError] = useState({});
  const [PageLevelAccessurl, setPageLevelAccessurl] = useState();
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    setPageLevelAccessurl('assets/upload-file');
  }, [id]);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.addAccess) {
        navigate('/404-error-page');
      }
    }
  }, [pageAccessData, navigate]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setApiError({});

    const validationErrors = validateExcelFileUpload({ ExcelFile: excelFile });
    if (Object.keys(validationErrors).length > 0) {
      setApiError(validationErrors);
      return;
    }

    try {
      setIsButtonDisabled(true);
      await uploadExcelFile(excelFile);
      toast.success('Excel file uploaded successfully!');
      setExcelFile(null);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsButtonDisabled(false);
    }
  }, [excelFile]);

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };

  const handleDownloadSample = () => {
    const sampleFileUrl = '/SampleFiles/SampleAssetInventory.xlsx';
    const link = document.createElement('a');
    console.log('sadfhfcsedhcn', link);
    link.href = sampleFileUrl;
    link.download = 'SampleAssetInventory.xlsx';
    link.click();
  };

  return (
    <>
      <ComponentHeader title="Upload Excel File" />
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">Upload Excel File</h5>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="d-flex align-items-center">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label htmlFor="excelFile" className="form-label">
                        Excel File <span className="required-field">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="excelFile"
                        accept=".xls,.xlsx"
                        onChange={handleFileChange}
                      />
                      {apiError.ExcelFile && <div className="text-danger">{apiError.ExcelFile}</div>}
                    </div>
                  </div>
                  <div className="col-lg-5 set-upload-button">
                    <button
                      type="submit"
                      className="btn btn-secondary me-2"
                      disabled={isButtonDisabled}
                    >
                      Upload
                    </button>
                  </div>
                  <div className="col-lg-3 d-flex justify-content-end set-upload-button-sample">
                    <button
                      className="btn btn-download"
                      onClick={handleDownloadSample}
                    >
                      <img
                        src={allImages.Excel}
                        alt="Download sample"
                        width="24px"
                      />{" "}
                      <span>
                        Download Sample
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
