import { useState, useEffect } from 'react';
import { fetchBHKTypes } from '../../../../services/bhkTypeService';
import { getPropertyOwners } from '../../../../services/propertyOwnerService';
import { fetchAmenities } from '../../../../services/amenityService';
import { handleErrors } from '../../../../utils/errorHandler';

export const Section1InputFields = (propertySeries) => {
    const [bhkTypes, setBhkTypes] = useState([]);
    const [ownerGuids, setownerGuids] = useState([]);
    const [amenity, setAmenity] = useState([]);
    useEffect(() => {

        const fetchAmenity = async () => {
            try {
                const response = await fetchAmenities();
                const AmenityOptions = response.map(item => ({
                    id: item.id,
                    name: item.amenityName,
                }));
                setAmenity(AmenityOptions);
            } catch (error) {
                handleErrors(error);
            }
        };

        const fetchTypes = async () => {
            try {
                const response = await fetchBHKTypes();
                const bhkOptions = response.result.map(item => ({
                    id: item.bhkType,
                    name: item.bhkType,
                }));
                setBhkTypes(bhkOptions);
            } catch (error) {
                handleErrors(error);
            }
        };

        const fetchownerGuids = async () => {
            try {
                const response = await getPropertyOwners();
                const ownerOptions = response.data.result.map(item => ({
                    id: item.ownerGuid,
                    name: `${item.firstName} ${item.lastName} - ${item.contactNo}`,
                }));
                setownerGuids(ownerOptions);
            } catch (error) {
                handleErrors(error);
            }
        };

        fetchTypes();
        fetchownerGuids();
        fetchAmenity();
    }, []);
    return {
        inputfields: [
            { label: 'Flat Number', name: 'flatNo', type: 'text', labelStar:<span>Flat Number <span className='required-field'>*</span></span> },
            { label: 'Floor Number', name: 'floorNo', type: 'text', labelStar:<span>Floor Number <span className='required-field'>*</span></span> },
            { 
                label: 'Flat Series', 
                name: 'flatSeries', 
                type: 'dropdown', 
                options: Array.from({ length: propertySeries }, (_, i) => ({
                    id: i + 1, 
                    name: (i + 1).toString(),
                })), labelStar:<span>Flat Series <span className='required-field'>*</span></span>
            },
            { label: 'Property Managed By', name: 'propertyManagedBy', type: 'dropdown', options: [
                    { id: 'Owner/Third Party', name: 'Owner/Third Party' },
                    { id: 'SoWeRent', name: 'SoWeRent' }
                ], labelStar:<span>Property Managed By <span className='required-field'>*</span></span> },
            { label: 'Flat Name', name: 'flatName', type: 'text', labelStar:<span>Flat Name <span className='required-field'>*</span></span> },
            { label: 'Flat Url', name: 'flatUrl', type: 'text', labelStar:<span>Flat Url <span className='required-field'>*</span></span> },
            { label: 'Preferred Tenants', name: 'preferredTenants', type: 'preferredTenant' , labelStar:<span>Preferred Tenants <span className='required-field'>*</span></span>},
            { label: 'BHK Type', name: 'bhkType', type: 'dropdown', options: bhkTypes, labelStar:<span>BHK Type <span className='required-field'>*</span></span> },
            { label: 'Furnished', name: 'furnished', type: 'dropdown', options: [
                    { id: 'Furnished', name: 'Furnished' },
                    { id: 'Semi-Furnished', name: 'Semi Furnished' },
                    { id: 'Unfurnished', name: 'UnFurnished' }
                ] , labelStar:<span>Furnished <span className='required-field'>*</span></span>},
            { label: 'Amenity', name: 'amenityIds', type: 'amenity', options: amenity, labelStar:<span>Amenity <span className='required-field'>*</span></span> },
            { label: 'Parking', name: 'parking', type: 'parkingOptions', labelStar:<span>Parking <span className='required-field'>*</span></span> },
            { label: 'Property Owner', name: 'ownerGuid', type: 'propertyOwner', options: ownerGuids, labelStar:<span>Property Owner <span className='required-field'>*</span></span> },
            { label: 'Operating Since', name: 'operatingSince', type: 'text', labelStar: 'Operating Since'},
            { label: 'Flat Size', name: 'flatSize', type: 'number', labelStar:<span>Flat Size <span className='required-field'>*</span></span> },
            { label: 'Stay Type', name: 'stayType', type: 'stayType', labelStar:<span>Stay Type <span className='required-field'>*</span></span> },
            { label: 'Flat Desc', name: 'flatDesc', type: 'flatDesc', labelStar:<span>Flat Description <span className='required-field'>*</span></span> },
            { label: 'Available From', name:'availableFrom', type:'date', labelStar:<span>Available From <span className='required-field'>*</span></span>},
            { label: 'Hand Over Date', name:'handoverDate', type:'date', labelStar:<span>HandOver Date<span className='required-field'>*</span></span>},
            { label: 'Renewal Date', name:'renewalDate', type:'date', labelStar:<span>Renewal Date<span className='required-field'>*</span></span>},
            { label: 'No Of Balcony', name: 'noOfBalcony', type: 'number', labelStar:<span>No of Balcony <span className='required-field'>*</span></span> },
            { label: 'No Of Bedrooms', name: 'noOfBedRooms', type: 'number', labelStar:<span>No of Bedrooms <span className='required-field'>*</span></span> },
            { label: 'No Of Bathrooms', name: 'noOfBathRooms', type: 'number', labelStar:<span>No of Bathrooms <span className='required-field'>*</span></span> },
            { label: 'No Of Person Allowed', name: 'noOfPersonAllowed', type: 'number', labelStar: 'No Of Person Allowed'},
            { label: 'Extra Allowed', name: 'extraAllowed', type: 'text' , labelStar:'Extra Allowed'},
            { label: 'Pets Allowed', name: 'petsAllowed', type: 'dropdown', options: [
                    { id: 'true', name: 'Yes' },
                    { id: 'false', name: 'No' }
                ], labelStar: 'Pets Allowed'},
            { label: 'eMeter Number', name: 'eMeterNo', type: 'text', labelStar: 'eMeter Number'},
            { label: 'wMeter Number', name: 'wMeterNo', type: 'text', labelStar: 'wMeter Number'},
            { label: 'Water Bill', name: 'waterBill', type: 'dropdown', options: [
                    { id: 'Fixed', name: 'Fixed' },
                    { id: 'Custom', name: 'Custom' }
                ], labelStar:'Water Bill' },
            { label: 'Facing', name: 'facing', type: 'text' , labelStar:'Facing'},
            { label: 'Tags', name:'tags', type:'tags', labelStar:'Tags'}
           
        ]

    };
}

export const Section2InputFields = [
    { label: 'Short Term Rent', name: 'monthlyRentST', type: 'number', labelStar:<span>Short Term Rent<span className='required-field'>*</span></span> },
    { label: 'Short Term Deposit', name: 'monthlyRentSTDeposit', type: 'number', labelStar:<span>Short Term Deposit<span className='required-field'>*</span></span> },
    { label: 'Long Term Rent', name: 'monthlyRentLT', type: 'number', labelStar:<span>Long Term Rent<span className='required-field'>*</span></span> },
    { label: 'Long Term Deposit', name: 'monthlyRentLTDeposit', type: 'number', labelStar:<span>Long Term Deposit<span className='required-field'>*</span></span> },
    { label: 'Daily Rent', name: 'dailyRent', type: 'number', labelStar:<span>Daily Rent<span className='required-field'>*</span></span> },
    { label: 'Daily Rent Deposit', name: 'dailyRentDeposit', type: 'number' , labelStar:<span>Daily Rent Deposit<span className='required-field'>*</span></span>},
    { label: 'Owner Rent', name: 'ownerRent', type: 'number', labelStar:<span>Owner Rent<span className='required-field'>*</span></span> },
    { label: 'Fixed Water Bill', name: 'fixedWaterBill', type: 'number', labelStar:'Fixed Water Bill'},
    { label: 'Flat Maintenance', name: 'flatMaintenance', type: 'number', labelStar: 'Flat Maintenance'},
    { label: 'Parking Charges', name: 'parkingCharges', type:'number', labelStar:'Parking Charges'},
    { label: 'Other Charges', name: 'otherCharges', type:'number', labelStar:'Other Charges'},
];

export const Section3InputFields = [
    { label: 'Page Title', name: 'pageTitle', type: 'text' , labelStar:'Page Title'},
    { label: 'Meta Keys', name: 'metaKeys', type: 'text' , labelStar:'Meta Keys'},
    { label: 'Meta Description', name: 'metaDesc', type: 'text', labelStar: 'Meta Description'},
];