import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const createExpManager = async (managerData) => {
  const response = await axiosInstance.post(
    "masters/expense-manager",
    managerData,
    {
      headers,
    }
  );
  return response.data;
};

export const fetchExpManager = async () => {
  const response = await axiosInstance.get("masters/expense-manager", {
    headers,
  });
  return response.data;
};

export const deleteExpManager = async (managerId) => {
  await axiosInstance.delete(`masters/expense-manager/${managerId}`, {
    headers,
  });
};

export const fetchExpManagerById = async (id) => {
  const response = await axiosInstance.get(`masters/expense-manager/${id}`, {
    headers,
  });
  return response.data.result;
};

export const updateExpManager = async (managerData) => {
  const response = await axiosInstance.put(
    "masters/expense-manager",
    managerData,
    {
      headers,
    }
  );
  return response.data;
};
