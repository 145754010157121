import React, { useEffect, useState, useCallback } from "react";
import { getPropertyOwnersDocuments, deletePropertyOwnerDocument } from "../../../../services/propertyOwnerService";
import { paginateData, calculateTotalPages } from "../../../../assets/js/script";
import TableHeader from "../../../Common/TableComponent/TableHeader";
import EntriesDropdown from "../../../Common/TableComponent/EntriesDropdown";
import TablesRow from "../../../Common/TableComponent/TablesRow";
import { Pagination } from "../../../Common/TableComponent/Pagination";
import { Loading } from "../../../Common/OtherElements/Loading";
import Swal from 'sweetalert2';
import { confirmDelete } from '../../../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from "../../../../utils/errorHandler";
import { AddOwnerDocuments } from "./AddOwnerDocuments";
import { Modal } from 'react-bootstrap';

export const ManageOwnerDocuments = ({ ownerguid, PageLevelAccessurl }) => {
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [managePageGroup, setManagePageGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPageGroup, setSelectedPageGroup] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPropertyOwnersDocuments(ownerguid);
      setManagePageGroup(response.data.result || []);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [ownerguid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = managePageGroup.filter(item =>
    item.documentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentData = paginateData(filteredData, currentPage, entriesPerPage);
  const totalPages = calculateTotalPages(filteredData.length, entriesPerPage);

  const handleEntriesChange = (value) => {
    setEntriesPerPage(value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (propertyId) => {
    const confirmed = await confirmDelete('Property');
    if (confirmed) {
      try {
        await deletePropertyOwnerDocument(propertyId);
        setManagePageGroup(prev => prev.filter(item => item.id !== propertyId));
        Swal.fire('Deleted!', 'The property has been deleted successfully.', 'success');
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {PageLevelAccessurl.addAccess ? (
        <AddOwnerDocuments
          editMode={editMode}
          initialData={selectedPageGroup || {}}
          onSuccess={fetchData}
          setSelectedPageGroup={setSelectedPageGroup}
          setEditMode={setEditMode}
          ownerguid={ownerguid}
        />
      ) : ''}
      {PageLevelAccessurl.viewAccess ? (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="mb-sm-2 mt-sm-2">Manage Property Owner Documents</h5>
              </div>
              <div className="card-body manage-amenity-master-card-body">
                <div className="pagination-details-responsive justify-content-between mb-3">
                  <EntriesDropdown
                    entriesPerPage={entriesPerPage}
                    onEntriesChange={handleEntriesChange}
                  />
                  <div>
                    <input
                      type="text"
                      placeholder="Search..."
                      className="form-control mb-2"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className='table-responsive'>
                    <table className="table align-middle table-bordered">
                      <TableHeader columns={["#", "Document Name", "Document Attached", "Added On", "Action",]} />
                      <tbody className="manage-page-group-table-values">
                        {currentData.length === 0 ? (
                          <TableDataStatusError colspan="7" />
                        ) : (
                          currentData.map((item, index) => (
                            <TablesRow
                              key={item.id}
                              rowData={{
                                id: (currentPage - 1) * entriesPerPage + index + 1,
                                documentName: item.documentName,
                                documentLink: (
                                  <>
                                    <img
                                      src={`${item.documentLink}`}
                                      alt="Property Owner Documents"
                                      width="80"
                                      height="80"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleImageClick(item.documentLink)}
                                    />
                                  </>
                                ),
                                updatedOn: new Date(item.addedOn).toLocaleDateString(),
                              }}
                              columns={["id", "documentName", "documentLink", "updatedOn",]}
                              hideIcons={false}
                              onDelete={() => handleDelete(item.id)}
                              pageLevelAccessData={PageLevelAccessurl}
                            />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalEntries={managePageGroup.length}
                  entriesPerPage={entriesPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      ) : ''}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Document Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImage} alt="Document" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
      </Modal>
    </>
  );
};
