import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

  export const paginationSupports = async (formData) => {
    const response = await axiosInstance.post('support/filter', formData, {headers});
    return response;
  }  

  export const deleteSupport = async (supportid) => {
    return await axiosInstance.delete(`support/${supportid}`, { headers });
  };

  export const SupportViewDetails = async (supportId) => {
    const response = await axiosInstance.get(`support/${supportId}`, { headers });
    return response;
  }

  export const addSupport = async (ownerData) => {
      const response = await axiosInstance.post('support/add', ownerData, {  headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${Cookies.get('accessToken')}`,
      }, });
      return response.data;
  };

  export const updateSupport = async (ownerData) => {
      const response = await axiosInstance.put('support/status', ownerData, {  headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${Cookies.get('accessToken')}`,
      }, });
      return response.data;
  };
  