import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ManagePropertyOwnerContent } from '../components/Properties/PropertyOwner/ManagePropertyOwnerContent';
export const PropertyOwnerView = () => {

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
                <ManagePropertyOwnerContent/>
                </div>
            </div>
        </div>
    );
};
