import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { validateMoveOutForm } from '../../utils/validation'; 
import { MoveOut } from '../../services/orderManagementService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../utils/errorHandler';

const MoveOutModal = ({ showModal, handleCloseModal, selectedOrderGuid }) => {
    const [formValues, setFormValues] = useState({
        OrderGuid: selectedOrderGuid,
        moveOutDate: '',
    });
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        setFormValues((prev) => ({
            ...prev,
            OrderGuid: selectedOrderGuid,
        }));
    }, [selectedOrderGuid]);

    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        let formattedDate = date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
        return formattedDate.replace('Sept', 'Sep'); 
    };

    const handleDateChange = (selectedDates) => {
        const formattedDate = selectedDates.length > 0 
            ? formatDate(selectedDates[0]) 
            : '';
        setFormValues((prev) => ({ ...prev, moveOutDate: formattedDate }));
    };

    const handleSubmit = async () => {
        const validationErrors = validateMoveOutForm(formValues);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            setIsButtonDisabled(true);
            await MoveOut(formValues.OrderGuid, formValues.moveOutDate);
            toast.success("Moved Out successfully!");
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsButtonDisabled(false);
            handleCloseModal();
        }
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Move Out</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formMoveOutDate">
                        <Form.Label>Move Out Date</Form.Label>
                        <Flatpickr
                            className="form-control"
                            value={formValues.moveOutDate}
                            onChange={handleDateChange}
                            options={{ 
                                dateFormat: "d-M-Y",
                                monthSelectorType: "static",
                             }} 
                        />
                        {errors.moveOutDate && (
                            <div className="invalid-feedback d-block">{errors.moveOutDate}</div>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSubmit} disabled={isButtonDisabled}>
                    {isButtonDisabled ? 'Confirming...' : 'Confirm Move Out'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoveOutModal;