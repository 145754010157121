import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { fetchSpecss, deleteSpecs } from '../../../services/specsServices';
import { paginateData, calculateTotalPages } from '../../../assets/js/script';
import TableHeader from '../../Common/TableComponent/TableHeader';
import EntriesDropdown from '../../Common/TableComponent/EntriesDropdown';
import TablesRow from '../../Common/TableComponent/TablesRow';
import { Pagination } from '../../Common/TableComponent/Pagination';
import { Loading } from '../../Common/OtherElements/Loading';
import { AddSpecs } from './AddSpecs';
import Swal from 'sweetalert2';
import { confirmDelete } from '../../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../utils/errorHandler';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const ManageSpecsContent = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [manageCities, setManageCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCity, setSelectedCity] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'asset-specs';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetchSpecss();
            setManageCities(response.result || []);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filteredCities = useMemo(() =>
        manageCities.filter(city => city.specName.toLowerCase().includes(searchQuery.toLowerCase())),
        [manageCities, searchQuery]
    );

    const paginatedCities = useMemo(() =>
        paginateData(filteredCities, currentPage, entriesPerPage),
        [filteredCities, currentPage, entriesPerPage]
    );

    const totalPages = useMemo(() =>
        calculateTotalPages(filteredCities.length, entriesPerPage),
        [filteredCities.length, entriesPerPage]
    );

    const handleEntriesChange = useCallback((value) => {
        setEntriesPerPage(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
    }, []);

    const handleDelete = useCallback(async (cityId) => {
        const confirmed = await confirmDelete('Specification');
        if (confirmed) {
            try {
                await deleteSpecs(cityId);
                setManageCities((prev) => prev.filter(city => city.id !== cityId));
                Swal.fire('Deleted!', 'The Specification has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    }, []);

    const handleSearchChange = useCallback((event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    }, []);

    return (
        <>
            {pageAccessDetails.addAccess ? (
                <AddSpecs
                    editMode={editMode}
                    initialData={selectedCity}
                    onSuccess={fetchData}
                    setSelectedPageGroup={setSelectedCity}
                    setEditMode={setEditMode}
                />
            ) : ''}

            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Manage Specification</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="pagination-details-responsive justify-content-between mb-3">
                                    <EntriesDropdown
                                        entriesPerPage={entriesPerPage}
                                        onEntriesChange={handleEntriesChange}
                                    />
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="form-control mb-2"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className="table align-middle table-bordered">
                                            <TableHeader columns={['#', 'Specification Name', 'Added On', 'Action']} />
                                            <tbody className="manage-page-group-table-values">
                                                {paginatedCities.length === 0 ? (
                                                    <TableDataStatusError colspan="4" />
                                                ) : (
                                                    paginatedCities.map((city, index) => (
                                                        <TablesRow
                                                            key={city.id}
                                                            rowData={{
                                                                id: (currentPage - 1) * entriesPerPage + index + 1,
                                                                specName: city.specName,
                                                                addedon: new Date(city.addedOn).toLocaleDateString(),
                                                            }}
                                                            columns={['id', 'specName', 'addedon']}
                                                            hideIcons={false}
                                                            onEdit={() => {
                                                                setSelectedCity(city);
                                                                setEditMode(true);
                                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                            }}
                                                            onDelete={() => handleDelete(city.id)}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    totalEntries={filteredCities.length}
                                    entriesPerPage={entriesPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
};
