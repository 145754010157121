import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSWKPropertyByGuid } from '../../../services/SWKPropertService';
import { ManageEachPropertyDetail } from './ManageEachPropertyDetail';
import { ManageFlatsDetails } from './ManageFlatsDetails';
import { ManageNearbyContent } from '../PropertyNearBy/ManageNearbyContent';
import { handleErrors } from '../../../utils/errorHandler';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';
export const HeaderTab = () => {
    const { id } = useParams();
    const [propertyDetails, setPropertyDetails] = useState({});
    const [propertyName, setPropertyName] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [status, setStatus] = useState('');
    const [createdOn, setCreatedOn] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');
    const [activeTab, setActiveTab] = useState('flats');
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = '/property/detail/:id';
    const navigate = useNavigate();
    const { pageAccessData} = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                const propertyDetails = await fetchSWKPropertyByGuid(id);
                setPropertyDetails(propertyDetails);
                setPropertyName(propertyDetails.propertyName);
                setPropertyType(propertyDetails.propertyType);
                setCreatedOn(formatDate(propertyDetails.addedOn));
                setLastUpdatedOn(formatDate(propertyDetails.updatedOn));
                setStatus(propertyDetails.status);
            } catch (error) {
                handleErrors(error);
            }
        };

        fetchPropertyDetails();
    }, [id]);

    return (
        <>
            <div className="card mt-n4 mx-n4 bg-soft-info">
                <div>
                    <div className="card-body pb-0 px-4">
                        <div className="row mb-3">
                            <div className="col-md">
                                <div className="row align-items-center g-3">

                                    <div className="col-md">
                                        <div>
                                            <h4 className="fw-bold">Property : <span>{propertyName} ({propertyType})</span><span style={{ fontSize: '12px' }} className={`badge text-bg-${status === 'Active' ? 'success' : 'danger'} badge-border ms-3`}>
                                                {status === 'Active' ? 'Active' : 'Draft'}
                                            </span><a id="editLink" style={{ float: 'right' }} title="Edit" href={`/property/update/${propertyDetails.id}`}><i className="mdi mdi-square-edit-outline fs-20"></i></a></h4>

                                            <div className="hstack gap-3 flex-wrap">
                                                <div>Created On : <span className="fw-medium">{createdOn}</span></div>
                                                <div className="vr"></div>
                                                <div>Last Updated On : <span className="fw-medium">{lastUpdatedOn}</span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="nav nav-tabs-custom border-bottom-0" role="tablist">
                            {pageAccessDetails.viewAccess ? (
                                <li className="nav-item" id="documentsTab" role="presentation">
                                    <a className={`nav-link ${activeTab === 'flats' ? 'active' : ''}`} data-bs-toggle="tab" href="#profile1" role="tab" aria-selected={activeTab === 'flats'} onClick={() => setActiveTab('flats')}
                                    >
                                        Flats
                                    </a>
                                </li>
                            ) : ''}
                             {pageAccessDetails.viewAccess || pageAccessDetails.addAccess ? (
                            <li className="nav-item" id="teamsTab" role="presentation">
                                <a className={`nav-link ${activeTab === 'propertyNearby' ? 'active' : ''}`} data-bs-toggle="tab" href="#messages1" role="tab" aria-selected={activeTab === 'propertyNearby'} onClick={() => setActiveTab('propertyNearby')}
                                >
                                    Property Near By
                                </a>
                            </li>
                            ) : ''}
                            {pageAccessDetails.viewAccess ? (
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link ${activeTab === 'propertyDetails' ? 'active' : ''}`} data-bs-toggle="tab" href="#home1" role="tab" aria-selected={activeTab === 'propertyDetails'} onClick={() => setActiveTab('propertyDetails')}
                                    >
                                        Property Details
                                    </a>
                                </li>
                            ) : ''}
                        </ul>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-xxl-12">
                    {activeTab !== 'propertyNearby' ? (
                        pageAccessDetails.viewAccess ? (
                            <div className="card mt-xxl-n5">
                                <div className="tab-content text-muted">
                                    <div className={`tab-pane ${activeTab === 'propertyDetails' ? 'active show' : ''}`} id="home1" role="tabpanel">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 ms-2">
                                                <ManageEachPropertyDetail propertyDetails={propertyDetails} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'flats' ? 'active show' : ''}`} id="profile1" role="tabpanel">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 table-responsive">
                                                <ManageFlatsDetails propertyDetails={propertyDetails} pageLevelAccessData={pageAccessDetails}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    ) : (
                        pageAccessDetails.viewAccess || pageAccessDetails.addAccess ? (
                        <div className="tab-content text-muted">
                            <div className={`tab-pane active show`} id="messages1" role="tabpanel">
                                <div>
                                    <div className="flex-grow-1 ms-2">
                                        <ManageNearbyContent propertyDetails={propertyDetails.propertyGuid}  pageLevelAccessData={pageAccessDetails}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ) : null
                    )}
                </div>
            </div>
        </>
    );
};
