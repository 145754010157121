import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const addHandyman = async (formData) => {
    const payload = formData.formData;
    const response = await axiosInstance.post('properties/handyman/assign', payload, { headers });
    return response.data.result;
  }
  
  export const fetchAssignedHandymans = async () => {
    const response = await axiosInstance.get('properties/handyman/assign', { headers });
    return response.data;
  };
  
  export const deleteAssignedHandymans = async (assignedId) => {
    await axiosInstance.delete(`properties/handyman/assign/${assignedId}`, { headers });
  };
  
  export const updateAssignedHandyman = async (payload) => {
    const response = await axiosInstance.put('properties/handyman/assign', payload, { headers });
    return response.data;
  };
  
  export const fetchAssignedHandymanById = async (id) => {
    const response = await axiosInstance.get(`properties/handyman/assign/${id}`, { headers });
    return response.data;
  };

export const fetchHandymansList = async () => {
    const response = await axiosInstance.get('auth/handymans', { headers });
    return response.data.result;
  };