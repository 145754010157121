import React from 'react'
import { ToastContainer } from 'react-toastify';
import { ManageSpecsContent } from '../components/AppSettings/Specification/ManageSpecsContent';
export const SpecsPage = () => {
  return (
    <div className="main-content">
    <div className="page-content">
        <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
        <ManageSpecsContent/>
        </div>
    </div>
</div>
  )
}
