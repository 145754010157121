import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createFlatMaster = async (formData) => {
    const response = await axiosInstance.post('properties/flat', formData, { headers });
    return response.data;
};

export const fetchFlats = async (propGuid) => {
    const response = await axiosInstance.get(`properties/flat/all/${propGuid}`, { headers });
    return response.data.result;
};

export const deleteFlat = async (flatId) => {
    await axiosInstance.delete(`properties/flat/${flatId}`, { headers });
};

export const fetchFlatById = async (flatId) => {
    const response = await axiosInstance.get(`properties/flat/${flatId}`, { headers });
    return response.data.result;
};

export const updateFlat = async (payload) => {
    const response = await axiosInstance.put('properties/flat', payload, { headers });
    return response.data;
};

export const cloneFlat = async (flatGuid) => {
    return await axiosInstance.post(`properties/flat/clone/${flatGuid}`, { headers });
};

export const updateFlatStatus = async (flatId, flatStatus) => {
    return await axiosInstance.put(`properties/flat/status?flatId=${flatId}&flatStatus=${flatStatus}`, { headers });
};

export const updateFlatFeaturedStatus = async (flatId, flatStatus) => {

    return await axiosInstance.put(`properties/flat/featured?flatId=${flatId}&featureStatus=${flatStatus}`, { headers });
};