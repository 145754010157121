import React from 'react'
import { ToastContainer } from 'react-toastify';
import { ManagePropertyExpenses } from '../components/PropertyExpenses/ManagePropertyExpenses';
export const ManagePropertyExp = () => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                    <ManagePropertyExpenses />
                </div>
            </div>
        </div>
    )
}
