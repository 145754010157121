import React, { useEffect, useState, useRef } from "react";
import {  paginationActiveOnboardingCustomers } from "../../services/onboardingCustomerService";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from '../Common/TableComponent/Pagination';
import { Link } from "react-router-dom";
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const ManageActiveCustomers = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [manageBlogs, setManageBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedTerm, setSearchedTerm] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    
const [pageAccessDetails, setPageAccessDetails] =useState([]);
const PageLevelAccessurl = 'active-onboarding-customers';
const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
      if (pageAccessData) {
          if(!pageAccessData.viewAccess){
          navigate('/404-error-page');
          } else{
              setPageAccessDetails(pageAccessData);
          }
          
      } else {
          console.log('No page access details found');
      }
  },[pageAccessData, navigate])

    const searchInputRef = useRef(null);

    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true);
            const formData = {
                pageSize: entriesPerPage || 10,
                pageNo: currentPage || 1,
                fromDate: fromDate || "",
                toDate: toDate || "",
                sParam: searchedTerm || ""
            };
            try {
                const response = await paginationActiveOnboardingCustomers(formData);
                const { data } = response;
                setManageBlogs(data.result);
                setTotalCount(data.result[0]?.totalCount || 0);
            } catch (error) {
                handleErrors(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProperties();
    }, [entriesPerPage, currentPage, fromDate, toDate, searchedTerm]);


    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchClick = () => {
        setSearchedTerm(searchTerm);
    };


    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const totalPages = Math.ceil(totalCount / entriesPerPage);

    return (
        <>
            <style>
                {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(9){ display:none;} 
                `}
            </style>
            {pageAccessDetails.viewAccess ? (
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-2 mt-sm-2">Manage Active Onboarding Customers</h5>
                        </div>
                        <div className="card-body manage-amenity-master-card-body">
                            <div className="responsive-filter-type mb-3">
                                <div className="entries-dropdown">
                                    <label htmlFor="entriesPerPage" className="form-label me-2">Show entries:</label>
                                    <select
                                        className="form-select"
                                        id="entriesPerPage"
                                        value={entriesPerPage}
                                        onChange={handleEntriesPerPageChange}
                                    >
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                    </select>
                                </div>
                                <div className="date-filters">
                                    <label htmlFor="fromDate" className="form-label me-2">From Date:</label>
                                    <Flatpickr
                                        id="fromDate"
                                        className="form-control"
                                        placeholder="Select From Date"
                                        value={fromDate}
                                        onChange={([date]) => setFromDate(date)}
                                        options={{
                                            dateFormat: "Y-m-d",
                                            monthSelectorType: 'static',
                                        }}
                                    />
                                </div>
                                <div className="date-filters">
                                    <label htmlFor="toDate" className="form-label me-2">To Date:</label>
                                    <Flatpickr
                                        id="toDate"
                                        className="form-control"
                                        placeholder="Select To Date"
                                        value={toDate}
                                        onChange={([date]) => setToDate(date)}
                                        options={{
                                            dateFormat: "Y-m-d",
                                            monthSelectorType: 'static',
                                        }}
                                    />
                                </div>
                                <div className="search-input">
                                    <label htmlFor="search" className="form-label me-2">Search:</label>
                                    <input
                                        type="text"
                                        id="search"
                                        className="form-control"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        ref={searchInputRef}
                                    />
                                </div>
                                <div className="pt-2">
                                    <button
                                        className="btn btn-secondary btn-properties-search"
                                        onClick={handleSearchClick}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>

                            {loading ? (
                                <Loading />
                            ) : (
                                <div className='table-responsive'>
                                    <table className="table align-middle table-bordered">
                                        <TableHeader
                                            columns={["#", "Customer Name", "Date of Birth", "Gender", "Property Details", "Status", "Registered On", "Add or manage Booking"]}
                                        />
                                        <tbody className="manage-page-group-table-values">
                                            {manageBlogs.length === 0 ? (
                                                <TableDataStatusError colspan="10" />
                                            ) : (
                                                manageBlogs.map((item, index) => (
                                                    <TablesRow
                                                        key={item.id}
                                                        rowData={{
                                                            id: (currentPage - 1) * entriesPerPage + index + 1,
                                                            CustomerName: (
                                                                <>
                                                                    <div><Link to={`/onboarding-customers/detail/${item.customerGuid}`} style={{ textDecoration: 'none' }}>
                                                                        {item.firstName} {item.lastName}
                                                                    </Link></div>
                                                                    <div>
                                                                        <i className='ri-mail-fill' style={{ fontSize: '12px' }}></i> <span style={{ fontWeight: 600 }}>{item.emailAddress}</span>
                                                                    </div>
                                                                    <div>
                                                                        <i className='ri-phone-fill' style={{ fontSize: '12px' }}></i> <span style={{ fontWeight: 600 }}>{item.phoneNo}</span>
                                                                    </div>


                                                                </>
                                                            ),
                                                            dob: item.dob || '-',
                                                            gender: item.gender && item.gender.trim() !== "" ? item.gender : "-",
                                                            propertydetails: (
                                                                <>
                                                                    <Link to={`/property/detail/${item.propertyGuid}`} style={{ textDecoration: 'none' }}>
                                                                        {item.propertyName}
                                                                    </Link>
                                                                    <p>Flat Number: {item.flatNo}</p>
                                                                </>
                                                            ),
                                                            status: (
                                                                <span style={{ fontSize: '12px' }} className={`badge badge-soft-${item.status === 'Active' ? 'success' : 'danger'} badge-border`}>
                                                                    {item.status === 'Active' ? 'Active' : 'Blocked'}
                                                                </span>
                                                            ),

                                                            RegisterdOn: new Intl.DateTimeFormat('en-GB', {
                                                                day: '2-digit',
                                                                month: 'short',
                                                                year: 'numeric'
                                                            }).format(new Date(item.registeredOn)),
                                                            addManage: (
                                                                <span className="badge text-bg-danger" style={{ fontSize: '11px' }}><Link to={`/onboarding-customers/add-order/${item.customerGuid}`} style={{ color: '#fff' }}>Add Booking</Link></span>
                                                            )

                                                        }}
                                                        columns={["id", "CustomerName", "dob", "gender", "propertydetails", "status", "RegisterdOn", "addManage"]}
                                                        hideIcons={false}
                                                        pageLevelAccessData={pageAccessDetails}
                                                    />
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                                totalEntries={totalCount}
                                entriesPerPage={entriesPerPage}
                            />
                        </div>

                    </div>
                </div>
            </div>
            ) : ''}
        </>
    );
};
