import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "flatpickr/dist/flatpickr.min.css";
import { fetchSWKProperty } from "../../services/SWKPropertService";
import { fetchFlats, fetchFlatById } from "../../services/FlatMasterService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addorderManagement } from "../../services/orderManagementService";
import { fetchOnboardingCustomersById } from "../../services/onboardingCustomerService";
import { validateOrderDetails } from "../../utils/validation";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import { handleErrors } from "../../utils/errorHandler";
import AsyncSelect from "react-select/async";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";

export const OrderManagementDetails = () => {
  const { userGuid } = useParams();
  const [properties, setProperties] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [flats, setFlats] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedFlat, setSelectedFlat] = useState("");
  const [stayTypeOptions, setStayTypeOptions] = useState([]);
  const [stayType, setStayType] = useState("");
  const [stayDuration, setStayDuration] = useState("");
  const [flatPricing, setFlatPricing] = useState({});
  const [rentAmount, setRentAmount] = useState();
  const [securityDeposit, setSecurityDeposit] = useState();
  const [paymentType, setPaymentType] = useState("");
  const [onboardingCharges, setOnboardingCharges] = useState(2000);
  const [maintenanceCharges, setMaintenanceCharges] = useState();
  const [waterBill, setWaterBill] = useState();
  const [rentDiscount, setRentDiscount] = useState();
  const [securityDiscount, setSecurityDiscount] = useState();
  const [utilityDiscount, setUtilityDiscount] = useState();
  const [paidAmount, setPaidAmount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [disablePaidAmount, setDisablePaidAmount] = useState(true);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    userName: "",
    emailAddress: "",
    contactNo: "",
    moveInDate: "",
    stayType: "",
    stayCount: "",
    noOfGuest: "",
    flatId: "",
    rentAmount: "",
    securityDeposit: "",
    onboardingCharge: "",
    maintenanceCharge: "",
    waterBill: "",
    rentDiscount: "",
    securityDiscount: "",
    utilityDiscount: "",
    paidAmount: "",
    paymentReferenceNo: "",
    payType: "",
  });
  const [errors, setErrors] = useState({});
  const PageLevelAccessurl = "/onboarding-customers/add-order/:userGuid";
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.addAccess) {
        navigate("/404-error-page");
      } else {
        return;
      }
    } else {
      console.log("No page access details found");
    }
  });
  useEffect(() => {
    const loadCustomerDetails = async () => {
      try {
        const data = await fetchOnboardingCustomersById(userGuid);
        setCustomerName(data.result.firstName);
        setCustomerEmail(data.result.emailAddress);
        setCustomerPhone(data.result.phoneNo);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    loadCustomerDetails();
  }, [userGuid]);

  useEffect(() => {
    const loadProperties = async () => {
      try {
        const data = await fetchSWKProperty();
        setProperties(data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    loadProperties();
  }, []);

  useEffect(() => {
    if (!selectedProperty) return;

    const loadFlats = async () => {
      try {
        const flatsData = await fetchFlats(selectedProperty);
        setFlats(flatsData);
      } catch (error) {
        console.error("Error fetching flats:", error);
      }
    };

    loadFlats();
  }, [selectedProperty]);

  useEffect(() => {
    if (!selectedFlat) return;

    const loadFlatDetails = async () => {
      try {
        const flatData = await fetchFlatById(selectedFlat);
        setFlatPricing(flatData.flatPricing);

        const stayTypes = flatData.stayType
          .split(",")
          .map((type) => type.trim());

        if (stayTypes.includes("Daily")) {
          setStayTypeOptions(["Daily", "Monthly"]);
          setStayType("Daily");
        } else {
          setStayTypeOptions(["Monthly"]);
          setStayType("Monthly");
        }
        setMaintenanceCharges(flatData.flatPricing.flatMaintenance || 0);
        setWaterBill(flatData.flatPricing.fixedWaterBill || 0);
      } catch (error) {
        handleErrors(error);
      }
    };

    loadFlatDetails();
  }, [selectedFlat]);

  useEffect(() => {
    if (stayType && stayDuration) {
      let rent = 0;
      let deposit = 0;

      if (stayType === "Daily") {
        rent = flatPricing.dailyRent || 0;
        deposit = flatPricing.dailyRentDeposit || 0;
      } else if (stayType === "Monthly") {
        const months = parseInt(stayDuration, 10);
        if (months < 6) {
          rent = flatPricing.monthlyRentST || 0;
          deposit = flatPricing.monthlyRentSTDeposit || 0;
        } else {
          rent = flatPricing.monthlyRentLT || 0;
          deposit = flatPricing.monthlyRentLTDeposit || 0;
        }
      }

      setRentAmount(rent);
      setSecurityDeposit(deposit);
    }
  }, [stayType, stayDuration, flatPricing]);
  const rentPerDay = (rentAmount - (rentDiscount || 0)) / 30;
  const waterbillPerDay = (waterBill - (utilityDiscount || 0)) / 30;
  const maintenanceChargesPerDay = maintenanceCharges / 30;

  const moveInDate = new Date(values.moveInDate);
  const lastDayOfMonth = new Date(
    moveInDate.getFullYear(),
    moveInDate.getMonth() + 1,
    0
  );

  moveInDate.setHours(0, 0, 0, 0);
  lastDayOfMonth.setHours(23, 59, 59, 0);
  const differenceInTime = lastDayOfMonth - moveInDate;
  let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    const moveinDay =  moveInDate.getDate() - 1;


    differenceInDays = 30 - moveinDay;
  const totalThisMonthRent =
    differenceInDays * rentPerDay +
    differenceInDays * waterbillPerDay +
    differenceInDays * maintenanceChargesPerDay +
    onboardingCharges +
    (securityDeposit - (securityDiscount || 0));
  const formattedTotal = totalThisMonthRent.toFixed(2);

  useEffect(() => {
    if (stayType === "Monthly" && stayDuration < 6 && paymentType === "Full") {
      setDisablePaidAmount(false);
    } else {
      setDisablePaidAmount(true);
    }
  }, [stayType, stayDuration, paymentType]);

  const constMonthlyFullAmountRent =
    rentAmount +
    securityDeposit +
    onboardingCharges +
    waterBill +
    maintenanceCharges -
    ((rentDiscount || 0) + (securityDiscount || 0) + (utilityDiscount || 0));

  useEffect(() => {
    let total = 0;
    if (paymentType === "Full") {
      total = formattedTotal;
    } else if (paymentType === "Token") {
      total = onboardingCharges + 10000;
    }
    setPaidAmount(total);
  }, [
    paymentType,
    rentAmount,
    formattedTotal,
    securityDeposit,
    onboardingCharges,
    maintenanceCharges,
    waterBill,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handlePhoneChange = (contactNo) => setCustomerPhone(contactNo);

  const handleDateChange = (selectedDate) => {
    const formattedDate = selectedDate[0].toISOString();
    setValues((prevData) => ({
      ...prevData,
      moveInDate: formattedDate,
    }));
  };

  const loadPropertyOptions = (inputValue, callback) => {
    const filteredProperties = properties.filter((property) =>
      property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(
      filteredProperties.map((property) => ({
        label: property.propertyName,
        value: property.propertyGuid,
      }))
    );
  };

  const loadFlatOptions = (inputValue, callback) => {
    const filteredFlats = flats.filter((flat) =>
      flat.flatName.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(
      filteredFlats.map((flat) => ({
        label: flat.flatName,
        value: flat.id,
      }))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateOrderDetails({
      ...values,
      userName: customerName,
      emailAddress: customerEmail,
      contactNo: customerPhone,
      flatId: selectedFlat,
      stayType: stayType,
      stayCount: stayDuration,
      rentAmount: rentAmount,
      securityDeposit: securityDeposit,
      onboardingCharge: onboardingCharges,
      maintenanceCharge: maintenanceCharges,
      waterBill: waterBill,
      rentDiscount: rentDiscount,
      securityDiscount: securityDiscount,
      utilityDiscount: utilityDiscount,
      paidAmount: paidAmount,
      constMonthlyFullAmountRent: constMonthlyFullAmountRent,
      paymentType: paymentType,
      formattedTotal: formattedTotal,
    });
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const payload = {
          userGuid: userGuid,
          userName: customerName,
          emailAddress: customerEmail,
          contactNo: customerPhone,
          moveInDate: values.moveInDate,
          stayType: stayType,
          stayCount: stayDuration,
          noOfGuest: values.noOfGuest,
          flatId: selectedFlat,
          rentAmount: rentAmount,
          securityDeposit: securityDeposit,
          onboardingCharge: onboardingCharges,
          maintenanceCharge: maintenanceCharges,
          waterBill: waterBill,
          rentDiscount: rentDiscount || 0,
          securityDiscount: securityDiscount || 0,
          utilityDiscount: utilityDiscount || 0,
          paidAmount: paidAmount,
          paymentReferenceNo: values.paymentReferenceNo,
          payType: paymentType,
        };
        setIsButtonDisabled(true);
        const response = await addorderManagement(payload);
        toast.success("Order added successful!");
        navigate(`/orders/detail/${response.order_id}`);
        setIsButtonDisabled(false);
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      } finally {
        setIsButtonDisabled(false);
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Order Management Details</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="ri-home-2-fill"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/onboarding-customers">
                    Manage Onboard Customers
                  </Link>
                </li>
                <li className="breadcrumb-item">Add Order</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xxl-8">
            <div className="row">
              <div className="card mt-xxl-n5">
                <div className="card-header">
                  <h5 className="mb-sm-1 mt-sm-1">Customer Details</h5>
                </div>
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="userName" className="form-label">
                          Customer Name{" "}
                          <span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="userName"
                          value={customerName}
                          placeholder="Enter Customer Name"
                          onChange={(e) => setCustomerName(e.target.value)}
                          className={`form-control ${
                            errors.userName ? "is-invalid" : ""
                          }`}
                        />
                        {errors.userName && (
                          <p className="text-danger">{errors.userName}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="emailAddress" className="form-label">
                          Email <span className="required-field">*</span>
                        </label>
                        <input
                          type="email"
                          name="emailAddress"
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.target.value)}
                          placeholder="Enter Customer Email"
                          className={`form-control ${
                            errors.emailAddress ? "is-invalid" : ""
                          }`}
                        />
                        {errors.emailAddress && (
                          <p className="text-danger">{errors.emailAddress}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="phonenumber" className="form-label">
                          Phone Number <span className="required-field">*</span>
                        </label>
                        <PhoneInput
                          international
                          onChange={handlePhoneChange}
                          value={customerPhone}
                          defaultCountry="IN"
                          maxLength="15"
                          placeholder="Enter Phone Number"
                        />
                        {errors.contactNo && (
                          <div style={{ color: "#dc3545" }}>
                            {errors.contactNo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card mt-xxl-n5">
                <div className="card-header">
                  <h5 className="mb-sm-1 mt-sm-1">Property Details</h5>
                </div>
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="userName" className="form-label">
                          Properties
                        </label>
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadPropertyOptions}
                          defaultOptions={properties.map((property) => ({
                            label: property.propertyName,
                            value: property.propertyGuid,
                          }))}
                          onChange={(selectedOption) =>
                            setSelectedProperty(selectedOption?.value || "")
                          }
                          value={
                            properties.find(
                              (property) =>
                                property.propertyGuid === selectedProperty
                            )
                              ? {
                                  label: properties.find(
                                    (property) =>
                                      property.propertyGuid === selectedProperty
                                  )?.propertyName,
                                  value: selectedProperty,
                                }
                              : null
                          }
                          isClearable
                          placeholder="Select Property"
                          className={errors.propertyGuid ? "is-invalid" : ""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="flatSelect" className="form-label">
                          Select Flat <span className="required-field">*</span>
                        </label>
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadFlatOptions}
                          defaultOptions={flats.map((flat) => ({
                            label: flat.flatName,
                            value: flat.id,
                          }))}
                          onChange={(selectedOption) =>
                            setSelectedFlat(selectedOption?.value || "")
                          }
                          value={
                            flats.find((flat) => flat.id === selectedFlat)
                              ? {
                                  label: flats.find(
                                    (flat) => flat.id === selectedFlat
                                  )?.flatName,
                                  value: selectedFlat,
                                }
                              : null
                          }
                          isClearable
                          placeholder="Select Flat"
                          className={errors.flatId ? "is-invalid" : ""}
                        />
                        {errors.flatId && (
                          <p className="text-danger">{errors.flatId}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="staytype" className="form-label">
                          Stay Type <span className="required-field">*</span>
                        </label>
                        {stayTypeOptions.length > 1 ? (
                          <select
                            id="stayTypeSelect"
                            className={`form-select ${
                              errors.stayType ? "is-invalid" : ""
                            }`}
                            onChange={(e) => setStayType(e.target.value)}
                            value={stayType}
                          >
                            {stayTypeOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.stayType ? "is-invalid" : ""
                            }`}
                            value={stayType}
                            readOnly
                          />
                        )}
                        {errors.stayType && (
                          <p className="text-danger">{errors.stayType}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label className="form-label">
                          {stayType === "Daily"
                            ? "No of Days:"
                            : "No of Months:"}{" "}
                          <span className="required-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.stayCount ? "is-invalid" : ""
                          }`}
                          value={stayDuration}
                          onChange={(e) => setStayDuration(e.target.value)}
                          min={1}
                          placeholder={
                            stayType === "Daily"
                              ? "Enter number of days"
                              : "Enter number of months"
                          }
                        />
                        {errors.stayCount && (
                          <p className="text-danger">{errors.stayCount}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="noOfGuest" className="form-label">
                          No of Guests <span className="required-field">*</span>
                        </label>
                        <input
                          type="number"
                          name="noOfGuest"
                          value={values.noOfGuest}
                          onChange={handleChange}
                          className={`form-control ${
                            errors.noOfGuest ? "is-invalid" : ""
                          }`}
                          min={1}
                          placeholder="Enter Number of Guests"
                        />
                        {errors.noOfGuest && (
                          <p className="text-danger">{errors.noOfGuest}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="rentAmount" className="form-label">
                          Rent Amount <span className="required-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.rentAmount ? "is-invalid" : ""
                          }`}
                          value={rentAmount || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setRentAmount(value === "" ? null : Number(value));
                          }}
                        />
                        {errors.rentAmount && (
                          <p className="text-danger">{errors.rentAmount}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="securityDeposit" className="form-label">
                          Security Deposit
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.securityDeposit ? "is-invalid" : ""
                          }`}
                          value={securityDeposit || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSecurityDeposit(
                              value === "" ? null : Number(value)
                            );
                          }}
                        />
                        {errors.securityDeposit && (
                          <p className="text-danger">
                            {errors.securityDeposit}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="onboardingCharges"
                          className="form-label"
                        >
                          Onboarding Charges{" "}
                          <span className="required-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.onboardingCharge ? "is-invalid" : ""
                          }`}
                          value={onboardingCharges || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setOnboardingCharges(
                              value === "" ? null : Number(value)
                            );
                          }}
                        />
                        {errors.onboardingCharge && (
                          <p className="text-danger">
                            {errors.onboardingCharge}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="maintenanceCharges"
                          className="form-label"
                        >
                          Maintenance Charges{" "}
                          <span className="required-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.maintenanceCharge ? "is-invalid" : ""
                          }`}
                          value={maintenanceCharges || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setMaintenanceCharges(
                              value === "" ? null : Number(value)
                            );
                          }}
                        />
                        {errors.maintenanceCharge && (
                          <p className="text-danger">
                            {errors.maintenanceCharge}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="waterBill" className="form-label">
                          Water Bill <span className="required-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.waterBill ? "is-invalid" : ""
                          }`}
                          value={waterBill || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setWaterBill(value === "" ? null : Number(value));
                          }}
                        />
                        {errors.waterBill && (
                          <p className="text-danger">{errors.waterBill}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="MoveInDate" className="form-label">
                          Move In Date <span className="required-field">*</span>
                        </label>
                        <Flatpickr
                          data-enable-time
                          value={values.moveInDate}
                          onChange={handleDateChange}
                          className={`form-control ${
                            errors.moveInDate ? "is-invalid" : ""
                          }`}
                          options={{
                            enableTime: true,
                            dateFormat: "Y-m-d",
                            time_24hr: true,
                            monthSelectorType: "static",
                          }}
                          placeholder="Select Move In Date"
                        />
                        {errors.moveInDate && (
                          <p className="text-danger">{errors.moveInDate}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="rentDiscount" className="form-label">
                          Rent Discount
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.rentDiscount ? "is-invalid" : ""
                          }`}
                          value={rentDiscount}
                          onChange={(e) => {
                            const value = e.target.value;
                            setRentDiscount(
                              value === "" ? null : Number(value)
                            );
                          }}
                        />
                        {errors.rentDiscount && (
                          <p className="text-danger">{errors.rentDiscount}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="securityDiscount"
                          className="form-label"
                        >
                          Security Discount
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.securityDiscount ? "is-invalid" : ""
                          }`}
                          value={securityDiscount}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSecurityDiscount(
                              value === "" ? null : Number(value)
                            );
                          }}
                        />
                        {errors.securityDiscount && (
                          <p className="text-danger">
                            {errors.securityDiscount}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="utilityDiscount" className="form-label">
                          Water Bill Discount
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.utilityDiscount ? "is-invalid" : ""
                          }`}
                          value={utilityDiscount}
                          onChange={(e) => {
                            const value = e.target.value;
                            setUtilityDiscount(
                              value === "" ? null : Number(value)
                            );
                          }}
                        />
                        {errors.utilityDiscount && (
                          <p className="text-danger">
                            {errors.utilityDiscount}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card mt-xxl-n5">
                <div className="card-header">
                  <h5 className="mb-sm-1 mt-sm-1">Payment Details</h5>
                </div>
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="altEmail" className="form-label">
                          Payment Type
                        </label>
                        <select
                          id="paymentTypeSelect"
                          className={`form-select ${
                            errors.payType ? "is-invalid" : ""
                          }`}
                          onChange={(e) => setPaymentType(e.target.value)}
                          value={paymentType}
                        >
                          <option value="">Select Payment Type</option>
                          <option value="Full">Full</option>
                          <option value="Token">Token</option>
                        </select>
                        {errors.payType && (
                          <p className="text-danger">{errors.payType}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="altEmail" className="form-label">
                          Paid Amount <span className="required-field">*</span>
                        </label>
                        <div>
                          <input
                            type="number"
                            className={`form-control ${
                              errors.paidAmount ? "is-invalid" : ""
                            }`}
                            value={paidAmount}
                            onChange={(e) =>
                              setPaidAmount(Number(e.target.value))
                            }
                            disabled={disablePaidAmount}
                          />
                          {errors.paidAmount && (
                            <p className="text-danger">{errors.paidAmount}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="paymentReferenceNo"
                          className="form-label"
                        >
                          Payment Reference No
                        </label>
                        <input
                          type="text"
                          name="paymentReferenceNo"
                          value={values.paymentReferenceNo}
                          onChange={handleChange}
                          placeholder="Enter Payment Reference Number"
                          className={`form-control ${
                            errors.paymentReferenceNo ? "is-invalid" : ""
                          }`}
                        />
                        {errors.paymentReferenceNo && (
                          <p className="text-danger">
                            {errors.paymentReferenceNo}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col xxl-4">
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">
                  Total This Month Rent Details
                </h5>
              </div>
              <div className="card-body p-4">
                <p className="mb-2 fw-semibold">This month Rent</p>
                <h5 className="fs-14 mb-0">
                  No of Days ({differenceInDays || ""}) * Rent Per Day (
                  {rentPerDay.toFixed(2)})
                </h5>
                <h5 className="fs-14 mb-0 mt-2">
                  This month Rent ={" "}
                  {(
                    (isNaN(differenceInDays) || differenceInDays === ""
                      ? 0
                      : differenceInDays) *
                    (isNaN(rentPerDay) || rentPerDay === ""
                      ? 0
                      : Number(rentPerDay).toFixed(2))
                  ).toFixed(2)}
                </h5>
              </div>
              <div className="card-body p-4 pt-1">
                <p className="mb-2 fw-semibold">This month Water Bill</p>
                <h5 className="fs-14 mb-0">
                  No of Days ({differenceInDays || ""}) * Water Bill Per day (
                  {waterbillPerDay.toFixed(2)})
                </h5>
                <h5 className="fs-14 mb-0 mt-2">
                  This month Water Bill =
                  {(
                    (isNaN(differenceInDays) || differenceInDays === ""
                      ? 0
                      : differenceInDays) *
                    (isNaN(waterbillPerDay) || waterbillPerDay === ""
                      ? 0
                      : Number(waterbillPerDay).toFixed(2))
                  ).toFixed(2)}
                </h5>
              </div>
              <div className="card-body p-4 pt-1">
                <p className="mb-2 fw-semibold">This month Maintainence</p>
                <h5 className="fs-14 mb-0">
                  No of Days ({differenceInDays || ""}) * Maintainence Per day (
                  {maintenanceChargesPerDay.toFixed(2)})
                </h5>
                <h5 className="fs-14 mb-0 mt-2">
                  This month Maintainence ={" "}
                  {(
                    (isNaN(differenceInDays) || differenceInDays === ""
                      ? 0
                      : differenceInDays) *
                    (isNaN(maintenanceChargesPerDay) ||
                    maintenanceChargesPerDay === ""
                      ? 0
                      : Number(maintenanceChargesPerDay).toFixed(2))
                  ).toFixed(2)}
                </h5>
              </div>
              <div className="card-body p-4 pt-1">
                <p className="mb-2 fw-semibold">Total This Month Rent</p>
                <h5 className="fs-14 mb-0 pt-2">
                  Rent ={" "}
                  {(
                    (isNaN(differenceInDays) || differenceInDays === ""
                      ? 0
                      : differenceInDays) *
                    (isNaN(rentPerDay) || rentPerDay === ""
                      ? 0
                      : Number(rentPerDay).toFixed(2))
                  ).toFixed(2)}
                </h5>
                <h5 className="fs-14 mb-0 pt-2">
                  Water Bill ={" "}
                  {(
                    (isNaN(differenceInDays) || differenceInDays === ""
                      ? 0
                      : differenceInDays) *
                    (isNaN(waterbillPerDay) || waterbillPerDay === ""
                      ? 0
                      : Number(waterbillPerDay).toFixed(2))
                  ).toFixed(2)}
                </h5>
                <h5 className="fs-14 mb-0 pt-2">
                  Maintainence ={" "}
                  {(
                    (isNaN(differenceInDays) || differenceInDays === ""
                      ? 0
                      : differenceInDays) *
                    (isNaN(maintenanceChargesPerDay) ||
                    maintenanceChargesPerDay === ""
                      ? 0
                      : Number(maintenanceChargesPerDay).toFixed(2))
                  ).toFixed(2)}
                </h5>
                <h5 className="fs-14 mb-0 pt-2">
                  Security Deposit = {securityDeposit - (securityDiscount || 0)}
                </h5>
                <h5 className="fs-14 mb-0 pt-2">
                  Onboarding Charges = {onboardingCharges}
                </h5>
                <h5 className="fs-14 mb-0 pt-2 mt-2">
                  Total Payable ={" "}
                  {isNaN(formattedTotal) || formattedTotal === ""
                    ? 0
                    : formattedTotal}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-secondary"
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? "Saving..." : "Save"}
        </button>
      </form>
    </>
  );
};
