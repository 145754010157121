export const FormSection2 = ({ label,labelStar, name, onChange, error, type, value }) => (
    <div className="col-lg-6 col-md-6 col-sm-12">
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{labelStar}</label>
        <input
          type={type || 'text'}
          name={name}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          placeholder={`Enter ${label}`}
          value={value}
          onChange={onChange}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );