import React from 'react';
import TableHeader from '../Common/TableComponent/TableHeader';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';

export const FlatmateDetails = ({ customerData }) => {
    const flatmates = customerData?.flatmates || [];

    return (
        <>
            
                <div className="card-header">
                    <h5 className="mb-sm-2 mt-sm-2">Flatmate Details</h5>
                </div>
                <div className='table-responsive'>
                <table className="table align-middle table-bordered">
                    <TableHeader columns={['#', 'Flatmate Name', 'Relationship', 'Gender', 'Age', 'Id Type', 'Id No', 'Id Status']} />
                    <tbody className="manage-page-group-table-values">
                        {flatmates.length === 0 ? (
                            <TableDataStatusError colspan="8" />
                        ) : (
                            flatmates.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div>
                                            {item.firstName} {item.lastName}
                                        </div>
                                        <div>
                                            <i className='ri-mail-fill'></i> <span style={{ fontWeight: 600 }}>{item.emailAddress}</span>
                                        </div>
                                        <div>
                                            <i className='ri-phone-fill'></i> <span style={{ fontWeight: 600 }}>{item.contactNo}</span>
                                        </div>
                                    </td>
                                    <td>{item.relationship}</td>
                                    <td>{item.gender}</td>
                                    <td>{item.age}</td>
                                    <td>{item.idType}</td>
                                    <td>{item.idNo}</td>
                                    <td>
                                        {item.idStatus === 'Unverified' ? (
                                            <span className="badge rounded-pill badge-soft-danger">Unverified</span>
                                        ) : (
                                            <span className="badge rounded-pill badge-soft-success">Verified</span>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

        </>
    );
};