import axiosInstance from '../Interceptors/axiosInstance.jsx'; 
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createVendorMaster = async (vendorData) => {
    const response = await axiosInstance.post('masters/vendor', vendorData, { headers });
    return response.data; 
};

export const fetchVendor = async () => {
    const response = await axiosInstance.get('masters/vendor', { headers });
    return response.data.result;
};

export const deleteVendor = async (vendorId) => {
    await axiosInstance.delete(`masters/vendor/${vendorId}`, { headers });
};

export const fetchVendorById = async (vendorId) => {
  const response = await axiosInstance.get(`masters/vendor/${vendorId}`, { headers });
  return response.data.result;
};

export const updateVendor = async (payload) => {
    const response = await axiosInstance.put('masters/vendor', payload, { headers });
    return response.data;
};