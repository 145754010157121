import React, { useState } from 'react';
import TableHeader from '../Common/TableComponent/TableHeader';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { UpdateutilityAmount } from '../../services/orderManagementService';
import { handleErrors } from '../../utils/errorHandler';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PaymentUpdateModal } from './PaymentUpdateModal';

export const UtilityDetails = ({ customerData, handleFetchData }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAddPaymentVisible, setModalAddPaymentVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedPaymentId, setSelectedPaymentId] = useState('');
  const [editedAmount, setEditedAmount] = useState('');
  const [discount, setDiscount] = useState('');
  const [netAmount, setNetAmount] = useState('');

  const utilityDetails = customerData?.oUtility || [];

  console.log('utlityDetauls',utilityDetails);

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(date);
  };

  const handleAddPaymentOpenModal = (paymentId) => {
    console.log('payment id',paymentId);
    setSelectedPaymentId(paymentId);
    setModalAddPaymentVisible(true);
};

const handleAddPaymentCloseModal = () => {
    setModalAddPaymentVisible(false);
    setSelectedPaymentId(null);
};

  const handleOpenModal = (item) => {
    setSelectedRowData(item);
    setEditedAmount(item.utilityAmount);
    setDiscount('');
    setNetAmount(item.utilityAmount);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedRowData({});
  };

  console.log('selected payment id',selectedPaymentId);

  const handleSaveClick = async () => {
    try {
      const calculatedDiscount = discount || 0;
      if(netAmount < 0){
        toast.error("Discount cannot be more than the utility amount");
      } else{
        await UpdateutilityAmount(
          selectedRowData.orderGuid,
          netAmount,
          selectedRowData.id,
          calculatedDiscount
        );
        handleFetchData();
        toast.success('Utility details updated successfully');
        handleCloseModal();
      }
     
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleAmountChange = (value) => {
    setEditedAmount(value === "" ? null : Number(value));
    setNetAmount(value - (discount || 0)); 
  };

  const handleDiscountChange = (value) => {
    setDiscount(value === "" ? null : Number(value));
    setNetAmount((editedAmount || 0) - value); 
  };

  const handlePaymentSuccess = () => {
    handleFetchData();
};

  return (
    <div className="card mt-xxl-n5">
      <div className="card-header">
        <h5 className="mb-sm-1 mt-sm-1">Utility Details</h5>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap align-middle table-bordered">
          <TableHeader
            columns={[
              '#',
              'From Date',
              'To Date',
              'Utility Type',
              'Utility Amount',
              'Utility for this month',
              'Payment Id',
              'Payment Status',
              'Paid On',
              'Updated On',
              'Add Payment',
            ]}
          />
          <tbody className="manage-page-group-table-values">
            {utilityDetails.length === 0 ? (
              <TableDataStatusError colspan="12" />
            ) : (
              utilityDetails.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDate(item.fromDate)}</td>
                  <td>{formatDate(item.toDate)}</td>
                  <td>{item.utilityType}</td>
                  <td>
                    Rs. {item.utilityAmount}{' '}
                    <i
                      className="ri-pencil-fill"
                      style={{ cursor: 'pointer', color: '#22186f' }}
                      onClick={() => handleOpenModal(item)}
                    />
                  </td>
                  <td>Rs. {item.totalAmount}</td>
                  <td>{item.paymentId}</td>
                  <td>
                    {item.paymentStatus === 'Initiated' && (
                      <span className="badge rounded-pill badge-soft-primary">Initiated</span>
                    )}
                    {item.paymentStatus === 'Success' && (
                      <span className="badge rounded-pill badge-soft-success">Success</span>
                    )}
                    {item.paymentStatus === 'Failed' && (
                      <span className="badge rounded-pill badge-soft-danger">Failed</span>
                    )}
                    {item.paymentStatus === 'Pending' && (
                      <span className="badge rounded-pill badge-soft-warning">Pending</span>
                    )}
                  </td>
                  <td>{formatDate(item.paidOn)}</td>
                  <td>{formatDate(item.updatedOn)}</td>
                  <td>
                    {item.paymentStatus === 'Success' ? (
                      <span style={{ color: 'green', fontWeight: '500' }}>
                        No Payment Pending
                      </span>
                    ) : (
                      <span
                        className="badge text-bg-danger"
                        style={{ fontSize: '11px' }}
                      >
                        <Link
                          to="#"
                          onClick={() => handleAddPaymentOpenModal(item.orderGuid)}
                          style={{ textDecoration: 'none', color: '#fff' }}
                        >
                          Add Payment
                        </Link>
                      </span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <PaymentUpdateModal show={modalAddPaymentVisible} onClose={handleAddPaymentCloseModal} orderId={selectedPaymentId} pageIdentify='utility' onPaymentSuccess={handlePaymentSuccess}/>

      <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Utility Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Utility Amount</Form.Label>
              <Form.Control
                type="number"
                value={editedAmount}
                onChange={(e) => handleAmountChange(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="number"
                value={discount}
                onChange={(e) => handleDiscountChange(e.target.value)}
                
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Calculated Amount (Utlity Amount - Discount)</Form.Label>
              <Form.Control type="number" value={netAmount} readOnly />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleSaveClick}>
            Update
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};
