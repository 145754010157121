import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ManageSWRPropertyContent } from '../components/Properties/SWRProperties/ManageSWRPropertyContent';
export const SWRPropertyView = () => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
                    <ManageSWRPropertyContent/>
                </div>
            </div>
        </div>
    )
}
