import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TableHeader from '../Common/TableComponent/TableHeader';
import TablesRow from '../Common/TableComponent/TablesRow';
import { Loading } from '../Common/OtherElements/Loading';
import { paginationAssets, deleteAsset, assignAssetsTimelineDisplay, AddInventory, RemoveInventory } from '../../services/AssetsService';
import Swal from 'sweetalert2';
import { confirmDelete } from '../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../utils/errorHandler';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from '../Common/TableComponent/Pagination';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Button, Form } from 'react-bootstrap';
import { BootstrapTooltip } from '../../assets/js/script';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';


export const ManageAssetsManagement = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedTerm, setSearchedTerm] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [assetStatus, setAssetStatus] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [timelineData, setTimelineData] = useState(null);
    const [imageModal, setImageModal] = useState(false);
    const [addInventoryModal, setInventoryModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [removeInventoryModal, setremoveInventoryModal] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [newquantity, setNewQuantity] = useState(0);
    const [inventoryguid, setInventoryGuid] = useState('');
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const navigate = useNavigate();
    const PageLevelAccessurl = 'assets';
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const searchInputRef = useRef(null);
    const fetchProperties = useCallback(async () => {
        setLoading(true);
        const formData = {
            pageSize: entriesPerPage,
            pageNo: currentPage,
            fromDate,
            toDate,
            fiterParam: searchedTerm,
            assetStatus: assetStatus,
        };
        try {
            const response = await paginationAssets(formData);
            const { data } = response;
            setProperties(data.result);
            setTotalCount(data.result[0]?.totalCount || 0);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, [entriesPerPage, currentPage, fromDate, toDate, searchedTerm, assetStatus]);

    useEffect(() => {
        fetchProperties();
    }, [fetchProperties]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handleDelete = async (propertyId) => {
        const confirmed = await confirmDelete('asset');
        if (confirmed) {
            try {
                await deleteAsset(propertyId);
                setProperties(prev => prev.filter(item => item.id !== propertyId));
                Swal.fire('Deleted!', 'The asset has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    const handleStatusClick = async (assetGuid) => {
        try {
            const response = await assignAssetsTimelineDisplay(assetGuid);
            setTimelineData(response.data.result);
            setShowModal(true);
        } catch (error) {
            handleErrors(error);
        }
    };


    const handleSearchClick = () => {
        setSearchedTerm(searchTerm);
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setImageModal(true);
    };

    const handleQuantityChange = (e) => {
        const newQuantity = e.target.value;
        if (!isNaN(newQuantity) && newQuantity >= 0) {
            setNewQuantity(Number(newQuantity));
        }
    };

    const decreaseQuantity = () => {
        if (quantity > 0) {
            setNewQuantity(newquantity - 1);
        }
    };

    const increaseQuantity = () => {
        setNewQuantity(newquantity + 1);
    };

    const handleAddInventorySubmit = async () => {
        if (!newquantity || isNaN(newquantity) || newquantity <= 0) {
            toast.error('Please enter a valid quantity');
            return;
        }

        try {

            const assetGuid = inventoryguid;
            const response = await AddInventory(assetGuid, newquantity);

            if (response.status === 200 || response.status === 201) {
                toast.success('Quantity has been successfully added!');

                setInventoryModal(false);
                setNewQuantity("");
                fetchProperties();
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleRemoveInventorySubmit = async () => {
        if (!newquantity || isNaN(newquantity) || newquantity <= 0) {
            toast.error('Please enter a valid quantity');
            return;
        }
        try {
            const assetGuid = inventoryguid;
            if (newquantity > quantity) {
                toast.error('You cannot remove more items than you have in stock.');
            } else {
                const response = await RemoveInventory(assetGuid, newquantity);

                if (response.status === 200 || response.status === 201) {
                    toast.success('Quantity has been successfully removed!');

                    setremoveInventoryModal(false);
                    setNewQuantity("");
                    fetchProperties();
                }
            }

        } catch (error) {
            handleErrors(error);
        }
    };


    const handleAddInventory = (quantity, assetguid) => {
        setInventoryModal(true);
        setQuantity(quantity);
        setInventoryGuid(assetguid);
    };

    const handleRemoveInventory = (quantity, assetguid) => {
        setremoveInventoryModal(true);
        setQuantity(quantity);
        setInventoryGuid(assetguid);
    }

    const totalPages = Math.ceil(totalCount / entriesPerPage);

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
      };

    return (
        <>
            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Manage Assets</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="responsive-filter-type mb-3">
                                    <div className="entries-dropdownx">
                                        <label htmlFor="entriesPerPage" className="form-label me-2">Show entries:</label>
                                        <select
                                            className="form-select"
                                            id="entriesPerPage"
                                            value={entriesPerPage}
                                            onChange={handleEntriesPerPageChange}
                                        >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                    <div className="date-filters">
                                        <label htmlFor="fromDate" className="form-label me-2">From Date:</label>
                                        <Flatpickr
                                            id="fromDate"
                                            className="form-control"
                                            placeholder='Select From Date'
                                            value={fromDate}
                                            onChange={([date]) => setFromDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                    </div>
                                    <div className="date-filters">
                                        <label htmlFor="toDate" className="form-label me-2">To Date:</label>
                                        <Flatpickr
                                            id="toDate"
                                            className="form-control"
                                            placeholder='Select To Date'
                                            value={toDate}
                                            onChange={([date]) => setToDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="search" className="form-label me-2">Asset Status:</label>
                                        <select
                                            className="form-select"
                                            value={assetStatus}
                                            onChange={(e) => setAssetStatus(e.target.value)}
                                        >
                                            <option value="">Select..</option>
                                            <option value="Active">Active</option>
                                            <option value="Issued">Issued</option>
                                            <option value="Damaged">Damaged</option>
                                        </select>
                                    </div>
                                    <div className="search-input">
                                        <label htmlFor="search" className="form-label me-2">Search:</label>
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Search...."
                                            ref={searchInputRef}
                                        />
                                    </div>

                                    <div className="pt-2">
                                        <button
                                            className="btn btn-secondary btn-properties-search"
                                            onClick={handleSearchClick}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className="table align-middle table-bordered">
                                            <TableHeader columns={['#', 'QR Code', 'Product Name', 'Brand Name', 'Asset Code','Invoice Number', 'Amount','Total InvoiceAmount', 'Status', 'Inventory', 'Action']} />
                                            <tbody className="manage-amenity-master-table-values p-3">
                                                {properties.length > 0 ? (
                                                    properties.map((item, index) => (
                                                        <TablesRow
                                                            key={item.id}
                                                            rowData={{
                                                                id: (currentPage - 1) * entriesPerPage + index + 1,
                                                                qrcode: (
                                                                    <img
                                                                        src={item.qrImage || '-'}
                                                                        alt="QR Code"
                                                                        className="qrcode-img"
                                                                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                                                                        onClick={() => handleImageClick(item.qrImage)}
                                                                    />
                                                                ),
                                                                productName: item.productName,
                                                                brandName: item.brandName + ' (' + item.quantity + ')',
                                                                assetcode: item.assetCode,
                                                                purchaseInvoiceNo: item.purchaseInvoiceNo || '-',
                                                                amount: `Rs. ${item.amount}`,
                                                                totalInvoiceAmount: `Rs. ${item.totalInvoiceAmount}`,
                                                                status: (
                                                                    <span
                                                                        className={`badge rounded-pill ${item.status === 'Issued' ? 'badge-soft-primary' :
                                                                            item.status === 'Active' ? 'badge-soft-success' :
                                                                                item.status === 'Damaged' ? 'badge-soft-danger' : ''
                                                                            }`}
                                                                        onClick={() => handleStatusClick(item.assetGuid)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {item.status}
                                                                    </span>
                                                                ),
                                                                inventory: (
                                                                    <>
                                                                        {pageAccessDetails.addAccess ? (
                                                                            item.typeOfAsset === 'Tagged' ? '-' : (
                                                                                <>
                                                                                    <BootstrapTooltip title="Add Quantity">
                                                                                        <span onClick={() => handleAddInventory(item.quantity, item.assetGuid)}>
                                                                                            <i className="ri-add-box-fill" style={{ color: '#45cb85' }}></i>
                                                                                        </span>
                                                                                    </BootstrapTooltip>
                                                                                    <BootstrapTooltip title="Remove Quantity">
                                                                                        <span onClick={() => handleRemoveInventory(item.quantity, item.assetGuid)}>
                                                                                            <i className="ri-checkbox-indeterminate-fill" style={{ color: '#dc3563' }}></i>
                                                                                        </span>
                                                                                    </BootstrapTooltip>
                                                                                </>
                                                                            )
                                                                        ) : (
                                                                            <span style={{color:'#dc3545'}}>
                                                                            No Access
                                                                        </span>
                                                                        )}
                                                                    </>

                                                                ),
                                                            }}
                                                            columns={['id', 'qrcode', 'productName', 'brandName', 'assetcode','purchaseInvoiceNo', 'amount','totalInvoiceAmount','status', 'inventory']}
                                                            hideIcons={false}
                                                            showIcons={false}
                                                            onEdit={() => {
                                                                navigate(`update/${item.id}`);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                            onDelete={() => handleDelete(item.id)}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                ) : (
                                                    <TableDataStatusError colspan="10" />
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    totalEntries={totalCount}
                                    entriesPerPage={entriesPerPage}
                                />
                            </div>


                        </div>
                    </div>
                </div>

            ) : ''}

            <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Asset Timeline</Modal.Title>
                </Modal.Header>
                <Modal.Body className='timeline-background'>
                    {timelineData && timelineData.length > 0 ? (
                        <div className="timeline">
                            {timelineData.map((event, index) => (
                                <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                                    <i className='icon ri-stack-line'></i>
                                    <div className="date">{formatDate(event.addedOn)}</div>
                                    <div className="content">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                                <span>
                                                    {event.status === 'Issued' ?
                                                        <h5><span className="badge rounded-pill badge-soft-primary">{event.status}</span></h5> :
                                                        event.status === 'Damaged' ?
                                                            <h5><span className="badge rounded-pill badge-soft-danger">{event.status}</span></h5> :
                                                            event.status === 'Moved' ?
                                                                <h5><span className="badge rounded-pill badge-soft-danger">{event.status}</span></h5> :
                                                                event.status === 'Active' ?
                                                                    <h5><span className="badge rounded-pill badge-soft-warning">{event.status}</span></h5> :
                                                                    event.status
                                                    }
                                                </span>
                                                <p className="text-muted mb-2">{event.remarks}</p>
                                                <div>
                                                    <p className="mb-1"><strong>Property:</strong> {event.propertyName}</p>
                                                    <p className="mb-1"><strong>Flat No:</strong> {event.flatNo}</p>
                                                    <p className="mb-1"><strong>Product:</strong> {event.productName} ({event.brandName})</p>
                                                    <p className="mb-1"><strong>Added By:</strong> {event.addedBy}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="mb-1"><strong>
                            No timeline data available for this asset.</strong></p>
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={imageModal} onHide={() => setImageModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    {selectedImage ? (
                        <img src={selectedImage} alt="QR Code" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    ) : (
                        <p>No image available.</p>
                    )}
                </Modal.Body>
            </Modal>


            <Modal show={addInventoryModal} onHide={() => setInventoryModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Inventory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Current Quantity: {quantity}</h5>
                    <Form>
                        <Form.Group controlId="quantity">
                            <Form.Label>Add Quantity</Form.Label>
                            <div className="input-step step-primary">
                                <Button
                                    type="button"
                                    className="minus shadow"
                                    onClick={decreaseQuantity}
                                >
                                    –
                                </Button>
                                <input
                                    type="number"
                                    className="product-quantity "
                                    value={newquantity}
                                    min="0"
                                    max="100"
                                    onChange={handleQuantityChange}
                                />
                                <Button
                                    type="button"
                                    className="plus shadow"
                                    onClick={increaseQuantity}
                                >
                                    +
                                </Button>
                            </div>

                        </Form.Group>
                    </Form>

                    <Button className="btn btn-secondary mt-3" onClick={handleAddInventorySubmit}>
                        Add
                    </Button>
                </Modal.Body>
            </Modal>


            <Modal show={removeInventoryModal} onHide={() => setremoveInventoryModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Inventory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Current Quantity: {quantity}</h5>
                    <Form>
                        <Form.Group controlId="quantity">
                            <Form.Label>Remove Quantity</Form.Label>
                            <div className="input-step step-primary">
                                <Button
                                    type="button"
                                    className="minus shadow"
                                    onClick={decreaseQuantity}
                                >
                                    –
                                </Button>
                                <input
                                    type="number"
                                    className="product-quantity "
                                    value={newquantity}
                                    min={quantity}
                                    onChange={handleQuantityChange}
                                />
                                <Button
                                    type="button"
                                    className="plus shadow"
                                    onClick={increaseQuantity}
                                >
                                    +
                                </Button>
                            </div>

                        </Form.Group>
                    </Form>

                    <Button className="btn btn-secondary mt-3" onClick={handleRemoveInventorySubmit}>
                        Remove
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};
