import React, { useState, useEffect, useCallback } from 'react';
import { createLocation, updateLocation, fetchLocationById } from '../../../services/locationService';
import { validateLocation } from '../../../utils/validation';
import { toast } from 'react-toastify';
import { fetchCities } from '../../../services/cityService';
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../../utils/errorHandler';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';
import AsyncSelect from 'react-select/async';

export const AddLocation = ({ editMode = false, initialData = {}, onSuccess, setSelectedPageGroup, setEditMode }) => {
  const [formData, setFormData] = useState({ cityName: '', locationName: '' });
  const [errors, setErrors] = useState({ cityName: '', locationName: '' });
  const [apiError, setApiError] = useState('');
  const [cities, setCities] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchCitiesData = async () => {
      try {
        const citiesData = await fetchCities();
        setCities(citiesData.result);
      } catch (error) {
        handleErrors(error);
      }
    };

    fetchCitiesData();
  }, []);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const loadCityOptions = (inputValue, callback) => {
    const filteredCities = cities.filter((city) =>
      city.cityName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredCities.map((city) => ({
          label: city.cityName,
          value: city.id,
        }))
      );
    }, 500);
  };

  const handleCityChange = (selectedOption) => {
    const cityId = String(selectedOption?.value) || '';
    const cityName = selectedOption?.label || '';
    setFormData((prevData) => ({
      ...prevData,
      cityId,
      cityName,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchLocationById(initialData.id);
          setFormData({
            cityId: data.result.cityId || '',
            cityName: data.result.cityName || '',
            locationName: data.result.locationName || '',
          });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({ cityName: '', locationName: '' });
      }
    };
  
    fetchData();
  }, [editMode, initialData]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { valid, errors: validationErrors } = validateLocation(formData);
    setErrors(validationErrors);

    if (valid) {
      setApiError('');

      try {
        if (editMode) {
          setIsButtonDisabled(true);
          await updateLocation({ ...formData, id: initialData.id });
          toast.success('Location updated successfully!');
          setIsButtonDisabled(false);
          setEditMode(false);
        } else {
          const selectedCity = cities.find((city) => city.id === Number(formData.cityId));

          setIsButtonDisabled(true);
          await createLocation({
            cityId: selectedCity.id,
            cityName: selectedCity.cityName,
            locationName: formData.locationName,
          });
          toast.success('Location added successfully!');
          setIsButtonDisabled(false);
        }

        setFormData({ cityName: '', locationName: '' });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      }
    }
  }, [formData, editMode, initialData, cities, onSuccess, setEditMode]);

  const handleAddNewClick = () => {
    setFormData({ cityName: '', locationName: '' });
    setErrors({ cityName: '', locationName: '' });
    setApiError('');
    setSelectedPageGroup(null);
    setEditMode(false);
  };
  return (
    <>
      <ComponentHeader title='Location Master' />

      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? 'Update Location' : 'Add Location'}</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="cityName" className="form-label">Select City <span className='required-field'>*</span></label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadCityOptions}
                        defaultOptions={cities.map((city) => ({
                          label: city.cityName,
                          value: city.id,
                        }))}
                        onChange={handleCityChange}
                        value={
                          formData.cityId
                            ? {
                              label: cities.find((city) => String(city.id) === String(formData.cityId))?.cityName,
                              value: formData.cityId, 
                              }
                            : null
                        }
                        isClearable
                        placeholder="Select City"
                        classNamePrefix="react-select"
                        className={errors.cityName ? 'is-invalid' : ''}
                      />
                      {errors.cityName && <div className="invalid-feedback">{errors.cityName}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="locationName" className="form-label">Location Name <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="locationName"
                        value={formData.locationName}
                        placeholder='Enter Location Name'
                        onChange={handleInputChange}
                        className={`form-control ${errors.locationName ? 'is-invalid' : ''}`}
                      />
                      {errors.locationName && <div className="invalid-feedback">{errors.locationName}</div>}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button type="submit" className="btn btn-secondary pt-1 pb-1 p-3" disabled={isButtonDisabled}>
                        {isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}
                      </button>
                      {editMode && (
                        <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1 pt-1 pb-1 p-3">
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
