import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPropertyOwnerData, updatePropertyOwner } from '../../../services/propertyOwnerService';
import allImages from '../../../assets/images-import';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrors } from '../../../utils/errorHandler';
import { validatePropertyOwnerUpdate } from '../../../utils/validation';
import PhoneInput from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";

export const PropertyOwnerUpdate = ({ onBack }) => {
    const { ownerId } = useParams();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [CompleteAddress, setCompleteAddress] = useState('');
    const [PANNo, setPANNo] = useState('');
    const [FatherName, setFatherName] = useState('');
    const [dobvalue, setDobValue] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [currentProfileImage, setCurrentProfileImage] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const PageLevelAccessurl = 'property-owner/update/:ownerId';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.editAccess || !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                return;
            }

        } else {
            console.log('No page access details found');
        }
    })
    const isAdult = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            return age - 1;
        }
        return age;
    };
    useEffect(() => {
        const fetchOwnerData = async () => {
            try {
                const { firstName, lastName, contactNo, emailAddress,dob,completeAddress,panNo, fatherName,profileImage } = await fetchPropertyOwnerData(ownerId);
                setFirstName(firstName || '');
                setLastName(lastName || '');
                setContactNo(contactNo || '');
                setEmail(emailAddress || '');
                setCompleteAddress(completeAddress || '');
                setDobValue(dob || '');
                setPANNo(panNo || '');
                setFatherName(fatherName || '');
                setCurrentProfileImage(profileImage || allImages.defaultprofile);
            } catch (error) {
                handleErrors(error);
            }
        };

        if (ownerId) {
            fetchOwnerData();
        } else {
            console.error('No ownerId provided in the URL.');
        }
    }, [ownerId]);

    const handleInputChange = (setter) => (e) => setter(e.target.value);

    const handlePhoneChange = (phone) => {
        setContactNo(phone);
    };

    const showToast = (message, type = 'success') => {
        toast[type](message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = { firstName, lastName, contactNo, email, profileImage, ownerGuid: ownerId };
        const validationErrors = validatePropertyOwnerUpdate(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', ownerId);
            formDataToSend.append('firstName', firstName);
            formDataToSend.append('lastName', lastName);
            formDataToSend.append('contactNo', contactNo);
            formDataToSend.append('emailAddress', email);
            formDataToSend.append('DOB', dobvalue);
            formDataToSend.append('CompleteAddress', CompleteAddress);
            formDataToSend.append('PANNo', PANNo);
            formDataToSend.append('FatherName', FatherName);
            if (profileImage) {
                formDataToSend.append('profileImage', profileImage);
            }

            setIsButtonDisabled(true);
            await updatePropertyOwner(formDataToSend);
            showToast('Update successful!');
            setIsButtonDisabled(false);
        } catch (error) {
            handleErrors(error);
            setIsButtonDisabled(false);
        }
    };

    const handleProfileChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
        setCurrentProfileImage(file ? URL.createObjectURL(file) : allImages.defaultprofile);
    };

    return (
        <>
            <ToastContainer />
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Property Owner</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to='/property-owner'>
                                        Manage Property Owners
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Update Property Owner-{ownerId}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-1 mt-sm-1">Update Property Owner</h5>
                        </div>
                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 text-center mb-4">
                                        <div className="profile-user position-relative d-inline-block mx-auto">
                                            <img
                                                src={currentProfileImage}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image shadow"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <input
                                                    id="profileImage"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                    onChange={handleProfileChange}
                                                />
                                                <label htmlFor="profileImage" className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body shadow">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="firstName" className="form-label">First Name <span className='required-field'>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={firstName}
                                            onChange={handleInputChange(setFirstName)}
                                            id="firstName"
                                        />
                                        {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="lastName" className="form-label">Last Name <span className='required-field'>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={lastName}
                                            onChange={handleInputChange(setLastName)}
                                            id="lastName"
                                        />
                                        {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="contactNo" className="form-label">Contact Number <span className='required-field'>*</span></label>
                                        <PhoneInput
                                            international
                                            id="ContactNo"
                                            value={contactNo}
                                            onChange={handlePhoneChange}
                                            maxLength="15"
                                            defaultCountry='IN'
                                        />
                                        {errors.contactNo && <p className="text-danger">{errors.contactNo}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="email" className="form-label">Email Address <span className='required-field'>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={email}
                                            onChange={handleInputChange(setEmail)}
                                            id="email"
                                        />
                                        {errors.email && <p className="text-danger">{errors.email}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <label htmlFor="contactNo" className="form-label">Date of Birth<span className='required-field'>*</span></label>
                                        <Flatpickr
                                            className={`form-control ${errors.DOB ? 'is-invalid' : ''}`}
                                            value={dobvalue ? new Date(dobvalue) : null}
                                            placeholder='Enter Date Of Birth'
                                            onChange={(date) => {
                                                const selectedDate = date.length ? date[0].toISOString() : '';
                                                setDobValue(selectedDate);

                                                if (selectedDate) {
                                                    const age = isAdult(selectedDate);
                                                    if (age < 18) {
                                                        setErrors({ ...errors, DOB: 'You must be at least 18 years old.' });
                                                    } else {
                                                        setErrors({ ...errors, DOB: '' });
                                                    }
                                                }
                                            }}
                                            options={{
                                                dateFormat: "d-M-Y",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                        {errors.DOB && <p className="text-danger">{errors.DOB}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="email" className="form-label">Pan Number<span className='required-field'>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={PANNo}
                                            onChange={handleInputChange(setPANNo)}
                                            id="PANNo"
                                        />
                                        {errors.email && <p className="text-danger">{errors.email}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="FatherName" className="form-label">Father Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={FatherName}
                                            onChange={handleInputChange(setFatherName)}
                                            id="FatherName"
                                        />
                                        {errors.FatherName && <p className="text-danger">{errors.FatherName}</p>}
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-sm-12 mb-3">
                                        <label htmlFor="CompleteAddress" className="form-label">Complete Address <span className='required-field'>*</span></label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            value={CompleteAddress}
                                            onChange={handleInputChange(setCompleteAddress)}
                                            id="CompleteAddress"
                                        />
                                        {errors.CompleteAddress && <p className="text-danger">{errors.CompleteAddress}</p>}
                                    </div>
                                    <div className="col-lg-2 mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-secondary w-100"
                                            disabled={isButtonDisabled}
                                        >
                                            {isButtonDisabled ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
