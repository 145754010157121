import React, {  useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { fetchSWKProperty } from '../../../../services/SWKPropertService';
import { fetchFlats } from '../../../../services/FlatMasterService';
import { fetchSWKPropertyByGuid } from '../../../../services/SWKPropertService';
import { fetchFlatById } from '../../../../services/FlatMasterService';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import { validateAgreementForm } from '../../../../utils/validation';
import { createAgreementPreview } from '../../../../services/propertyOwnerService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ManageAllAgreements } from './ManageAllAgreements';

export const ManageAgreementPropertyList = ({ ownerguid }) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [properties, setProperties] = useState([]);
    const [flats, setFlats] = useState([]);
    const [selectedFlat, setSelectedFlat] = useState([]);
    const [noOfMonths, setNoOfMonths] = useState(null);
    const [totalRent, setTotalRent] = useState(0);
    const [securityDeposit, setSecurityDeposit] = useState('');
    const [tokenAmount, setTokenAmount] = useState('');
    const [handoverAmount, setHandoverAmount] = useState('');
    const [commenceAmount, setCommenceAmount] = useState('');
    const [agreementDate, setAgreementDate] = useState('');
    const [commenceDate, setCommenceDate] = useState('');
    const [witness1Name, setWitness1Name] = useState('');
    const [witness2Name, setWitness2Name] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [locality, setLocality] = useState('');
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');
    const [propertyState, setPropertyState] = useState('');
    const [errors, setErrors] = useState('');
    const [flatData, setFlatData] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [rerenderComp, setrerenderComp] = useState(false);

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    function formatDate(date) {
        if (!date) return "-";
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
    }

    useEffect(() => {
        const getProperties = async () => {
            try {
                const result = await fetchSWKProperty();
                setProperties(result);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        getProperties();
    }, []);

    useEffect(() => {
        const getFlats = async () => {
            if (selectedProperty) {
                try {
                    const result = await fetchFlats(selectedProperty);
                    setFlats(result);
                } catch (error) {
                    console.error('Error fetching flats:', error);
                }
            } else {
                setFlats([]);
            }
        };
        getFlats();
    }, [selectedProperty]);

    useEffect(() => {
        const getPropertyDetails = async () => {
            if (selectedProperty) {
                try {
                    const result = await fetchSWKPropertyByGuid(selectedProperty);
                    calculateNoOfMonths(result.renewalDate, result.rentStatDate);
                } catch (error) {
                    console.error('Error fetching property details:', error);
                }
            }
        };
        getPropertyDetails();
    }, [selectedProperty]);

    const calculateNoOfMonths = (renewalDate, rentStatDate) => {
        const startDate = new Date(rentStatDate);
        const endDate = new Date(renewalDate);
        const diffTime = Math.abs(endDate - startDate);
        const diffMonths = diffTime / (1000 * 60 * 60 * 24 * 30);
        setNoOfMonths(Math.round(diffMonths));
    };

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = properties.filter((property) =>
            property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredProperties.map((property) => ({
                    label: property.propertyName,
                    value: property.propertyGuid,
                }))
            );
        }, 1000);
    };

    const loadFlatOptions = (inputValue, callback) => {
        const filteredFlats = flats.filter((flat) =>
            flat.flatNo.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredFlats.map((flat) => ({
                    label: flat.flatNo,
                    value: flat.id,
                }))
            );
        }, 1000);
    };

    const handleFlatSelection = async (selectedOptions) => {
        setSelectedFlat(selectedOptions ? selectedOptions.map(option => option.value) : []);

        let rentTotal = 0;
        let flatNumbers = '';
        for (let flatId of selectedOptions.map(option => option.value)) {
            try {
                const flatDetails = await fetchFlatById(flatId);
                rentTotal += flatDetails.flatPricing.ownerRent || 0;
                flatNumbers += flatDetails.flatNo + ', ';
            } catch (error) {
                console.error('Error fetching flat details:', error);
            }
        }
        flatNumbers = flatNumbers.slice(0, -2);
        setTotalRent(rentTotal);
        setFlatData(flatNumbers);
    };

    const totalRentAmount = noOfMonths * totalRent;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            ownerGuid: ownerguid,
            streetAddress: streetAddress,
            locality: locality,
            pincode: pincode,
            city: city,
            propertyState: propertyState,
            securityDeposit: securityDeposit,
            tokenAmount: tokenAmount,
            handoverAmount: handoverAmount,
            commenceAmount: commenceAmount,
            agreementDate: formatDate(agreementDate),
            commenceDate: formatDate(commenceDate),
            flatNos: flatData,
            propertyGuid: selectedProperty,
            monthlyRent: totalRent,
            noOfMonth: noOfMonths,
            totalRent: totalRentAmount,
            witness1Name: witness1Name,
            witness2Name: witness2Name,

        }
        const validationErrors = validateAgreementForm(formData);

        if (Object.keys(validationErrors).length === 0) {
            try {
                setIsButtonDisabled(true);
                const resultagreement = await createAgreementPreview(formData);
                window.open(resultagreement.result.unsignedDocLink, '_blank')
                setrerenderComp(true);
                toast.success("Document added successfully!");
                setIsButtonDisabled(false);
                setSelectedProperty('');
                setFlats([]);
                setSelectedFlat([]);
                setNoOfMonths(null);
                setTotalRent(0);
                setSecurityDeposit('');
                setTokenAmount('');
                setHandoverAmount('');
                setCommenceAmount('');
                setAgreementDate('');
                setCommenceDate('');
                setWitness1Name('');
                setWitness2Name('');
                setStreetAddress('');
                setLocality('');
                setPincode('');
                setCity('');
                setPropertyState('');
                setErrors('');
                setFlatData('');
            } catch (err) {
                setIsButtonDisabled(false);
                toast.error(err.response.data.message);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            <style>
                {`
                    .table>:not(caption)>*>* {
                        padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                        display:none;
                    }
                    .ri-delete-bin-6-line:before {
                        display:none;
                    }
                    table td:nth-child(10){ display:none;} 
                `}
            </style>
            <div className="manage-flats-details">
                <div className="card-body manage-amenity-master-card-body">
                    <div className='d-flex justify-content-end'>
                        <button
                            className="btn btn-secondary mb-3"
                            onClick={toggleFormVisibility}>
                            Generate Agreement
                        </button>
                    </div>
                    {isFormVisible && (
                        <div className="agreement-form-section mb-3">
                            <form onSubmit={handleSubmit}>
                                <div className='profile-info-fields'>
                                    <h5 className="mb-sm-1 mt-sm-1">Property Details</h5>
                                </div>
                                <div className="row pt-3">

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="propertyName" className="form-label">Property Name</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadPropertyOptions}
                                                defaultOptions={properties.map((property) => ({
                                                    label: property.propertyName,
                                                    value: property.propertyGuid,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedProperty(selectedOption?.value || '');
                                                }}
                                                value={selectedProperty
                                                    ? { label: properties.find((property) => property.propertyGuid === selectedProperty)?.propertyName, value: selectedProperty }
                                                    : null}
                                                isClearable
                                                placeholder="Select Property"
                                            />
                                            {errors.propertyGuid && <p className="text-danger">{errors.propertyGuid}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="id" className="form-label">Flat Name <span className='required-field'>*</span></label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadFlatOptions}
                                                defaultOptions={flats.map((flat) => ({
                                                    label: flat.flatNo,
                                                    value: flat.id,
                                                }))}
                                                onChange={handleFlatSelection}
                                                value={flats.filter(flat => selectedFlat.includes(flat.id)).map(flat => ({
                                                    label: flat.flatNo,
                                                    value: flat.id,
                                                }))}
                                                isMulti
                                                isClearable
                                                placeholder="Select Flats"
                                            />
                                            {errors.flatNos && <p className="text-danger">{errors.flatNos}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="noOfMonths" className="form-label">No of Months</label>
                                            <input type="text" className={`form-control ${errors.noOfMonth ? 'is-invalid' : ''}`} value={noOfMonths || ''} onChange={(e) => setNoOfMonths(e.target.value)} />
                                            {errors.noOfMonth && <p className="text-danger">{errors.noOfMonth}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="rentAmount" className="form-label">Rent Amount</label>
                                            <input type="number" className={`form-control ${errors.monthlyRent ? 'is-invalid' : ''}`} value={totalRent || ''} onChange={(e) => setTotalRent(e.target.value)} />
                                            {errors.monthlyRent && <p className="text-danger">{errors.monthlyRent}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="totalRentAmount" className="form-label">Total Rent Amount</label>
                                            <input type="text" className={`form-control ${errors.totalRent ? 'is-invalid' : ''}`} value={totalRentAmount || ''} />
                                            {errors.totalRent && <p className="text-danger">{errors.totalRent}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="securityDeposit" className="form-label">Security Deposit  <span className='required-field'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.securityDeposit ? 'is-invalid' : ''}`}
                                                value={securityDeposit}
                                                onChange={(e) => setSecurityDeposit(e.target.value)}
                                                min="1"
                                                max="100000000"
                                            />
                                            {errors.securityDeposit && <p className="text-danger">{errors.securityDeposit}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="tokenAmount" className="form-label">Token Amount  <span className='required-field'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.tokenAmount ? 'is-invalid' : ''}`}
                                                value={tokenAmount}
                                                onChange={(e) => setTokenAmount(e.target.value)}
                                                min="1"
                                                max="100000000"
                                            />
                                            {errors.tokenAmount && <p className="text-danger">{errors.tokenAmount}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="handoverAmount" className="form-label">Handover Amount  <span className='required-field'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.handoverAmount ? 'is-invalid' : ''}`}
                                                value={handoverAmount}
                                                onChange={(e) => setHandoverAmount(e.target.value)}
                                                min="1"
                                                max="100000000"
                                            />
                                            {errors.handoverAmount && <p className="text-danger">{errors.handoverAmount}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="commenceAmount" className="form-label">Commence Amount  <span className='required-field'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.commenceAmount ? 'is-invalid' : ''}`}
                                                value={commenceAmount}
                                                onChange={(e) => setCommenceAmount(e.target.value)}
                                                min="1"
                                                max="100000000"
                                            />
                                            {errors.commenceAmount && <p className="text-danger">{errors.commenceAmount}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="agreementDate" className="form-label">Agreement Date <span className='required-field'>*</span></label>
                                            <Flatpickr
                                                className={`form-control ${errors.agreementDate ? 'is-invalid' : ''}`}
                                                value={agreementDate}
                                                onChange={(date) => setAgreementDate(date[0])}
                                                options={{
                                                    dateFormat: "Y-m-d",
                                                    monthSelectorType: 'static',
                                                }}
                                            />
                                            {errors.agreementDate && <p className="text-danger">{errors.agreementDate}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="commenceDate" className="form-label">Commence Date <span className='required-field'>*</span></label>
                                            <Flatpickr
                                                className={`form-control ${errors.commenceDate ? 'is-invalid' : ''}`}
                                                value={commenceDate}
                                                onChange={(date) => setCommenceDate(date[0])}
                                                options={{
                                                    dateFormat: "Y-m-d",
                                                    monthSelectorType: 'static',
                                                }}
                                            />
                                            {errors.commenceDate && <p className="text-danger">{errors.commenceDate}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='profile-info-fields'>
                                    <h5 className="mb-sm-1 mt-sm-1">Witness Details</h5>
                                </div>
                                <div className="row pb-3 pt-3">
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="witness1Name" className="form-label">Witness 1 Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={witness1Name}
                                                onChange={(e) => setWitness1Name(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="witness2Name" className="form-label">Witness 2 Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={witness2Name}
                                                onChange={(e) => setWitness2Name(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='profile-info-fields'>
                                    <h5 className="mb-sm-1 mt-sm-1">Address Section</h5>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-lg-8">
                                        <div className="mb-3">
                                            <label htmlFor="streetAddress" className="form-label">Street Address <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.streetAddress ? 'is-invalid' : ''}`}
                                                value={streetAddress}
                                                onChange={(e) => setStreetAddress(e.target.value)}
                                            />
                                            {errors.streetAddress && <p className="text-danger">{errors.streetAddress}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="locality" className="form-label">Locality <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.locality ? 'is-invalid' : ''}`}
                                                value={locality}
                                                onChange={(e) => setLocality(e.target.value)}
                                            />
                                            {errors.locality && <p className="text-danger">{errors.locality}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="pincode" className="form-label">Pincode <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                                value={pincode}
                                                onChange={(e) => setPincode(e.target.value)}
                                                maxLength={6}
                                            />
                                            {errors.pincode && <p className="text-danger">{errors.pincode}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">City <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                            {errors.city && <p className="text-danger">{errors.city}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="propertyState" className="form-label">Property State <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.propertyState ? 'is-invalid' : ''}`}
                                                value={propertyState}
                                                onChange={(e) => setPropertyState(e.target.value)}
                                            />
                                            {errors.propertyState && <p className="text-danger">{errors.propertyState}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button type="submit" className="btn btn-success" disabled={isButtonDisabled}>
                                            {isButtonDisabled ? 'Please Wait' : 'Preview Agreement'}

                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
            <ManageAllAgreements ownerguid={ownerguid} rerenderComp={rerenderComp}/>
        </>
    );
};
