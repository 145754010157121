import React, { useState } from 'react';
import { updateCustomerStatus } from '../../services/onboardingCustomerService';
import Swal from 'sweetalert2';
import { handleErrors } from '../../utils/errorHandler';

const ToggleSwitch = ({ propId, initialStatus, onStatusChange }) => {
  const [isActive, setIsActive] = useState(initialStatus === 'Active');

  const handleToggle = async () => {
    const modalTitle = isActive ? 'Active' : 'Blocked';
    const modalMessage = isActive 
      ? 'Are you sure you want to Block the customer?' 
      : 'Are you sure you want to Activate?';

    const result = await Swal.fire({
      title: modalTitle,
      text: modalMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      const newStatus = isActive ? 'Blocked' : 'Active';
      try {
        await updateCustomerStatus(propId, newStatus);
        setIsActive(!isActive); 
        onStatusChange(newStatus);

        Swal.fire(
          'Success!',
          `The owner has been ${newStatus === 'Active' ? 'Activated' : 'Blocked'}.`,
          'success'
        );
      } catch (error) {
      handleErrors(error);
      }
    }
  };

  return (
    <>
      <label className="switch">
        <div className="form-check form-switch form-switch-custom form-switch-primary">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isActive}
            onChange={handleToggle}
          />
        </div>
        <span className="slider round"></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
