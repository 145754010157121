import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TableHeader from '../Common/TableComponent/TableHeader';
import TablesRow from '../Common/TableComponent/TablesRow';
import { Loading } from '../Common/OtherElements/Loading';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { paginationPropertyAudit, deletePropertyAudit, addPropertyAuditGallery } from '../../services/propertyAudit';
import Swal from 'sweetalert2';
import { confirmDelete } from '../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../utils/errorHandler';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from '../Common/TableComponent/Pagination';
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { ManagePropertyAuditGallery } from './ManagePropertyAuditGallery';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { fetchSWKProperty } from '../../services/SWKPropertService';

export const ManagePropertyAudit = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [propertieslist, setPropertieslist] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [imageModal, setImageModal] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedAssetGuid, setSelectedAssetGuid] = useState(null);
    const [AuditId, setAuditId] = useState('');
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'property-audit';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    }, [pageAccessData, navigate])


    const searchInputRef = useRef(null);
    const fetchProperties = useCallback(async () => {
        setLoading(true);
        const formData = {
            pageSize: entriesPerPage,
            pageNo: currentPage,
            fromDate,
            toDate,
            fiterParam: selectedProperty,
        };
        try {
            const response = await paginationPropertyAudit(formData);
            const { data } = response;
            setProperties(data.result);
            setTotalCount(data.result[0]?.totalCount || 0);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, [entriesPerPage, currentPage, fromDate, toDate, selectedProperty]);

    useEffect(() => {
        fetchProperties();
    }, [fetchProperties]);

    useEffect(() => {
        const getProperties = async () => {
            try {
                const result = await fetchSWKProperty();
                setPropertieslist(result);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        getProperties();
    }, []);

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = propertieslist.filter((property) =>
            property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredProperties.map((property) => ({
                    label: property.propertyName,
                    value: property.propertyGuid,
                }))
            );
        }, 1000);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handleDelete = async (propertyId) => {
        const confirmed = await confirmDelete('Property Expense');
        if (confirmed) {
            try {
                await deletePropertyAudit(propertyId);
                setProperties(prev => prev.filter(item => item.id !== propertyId));
                Swal.fire('Deleted!', 'The property expense has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const handleImageClick = (assetGuid, auditid) => {
        setAuditId(auditid);
        setImageModal(true);
        setSelectedAssetGuid(assetGuid);
    };


    const handleAttachmentChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);

        if (file) {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        } else {
            setPreviewUrl(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!attachment) {
            toast.error('Select the image file');
            return;
        }


        setIsButtonDisabled(true);
        const formData = new FormData();
        formData.append('Attachment', attachment);
        formData.append('AuditGuid', selectedAssetGuid);

        try {
            await addPropertyAuditGallery(formData);
            toast.success('Gallery item added successfully!');
            setPreviewUrl('');
            setImageModal(false);
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsButtonDisabled(false);
        }
    };



    const totalPages = Math.ceil(totalCount / entriesPerPage);

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(',', '');
    };

    return (
        <>
            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Manage Property Audit</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="responsive-filter-type mb-3">
                                    <div className="entries-dropdownx">
                                        <label htmlFor="entriesPerPage" className="form-label me-2">Show entries:</label>
                                        <select
                                            className="form-select"
                                            id="entriesPerPage"
                                            value={entriesPerPage}
                                            onChange={handleEntriesPerPageChange}
                                        >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                    <div className="date-filters">
                                        <label htmlFor="fromDate" className="form-label me-2">From Date:</label>
                                        <Flatpickr
                                            id="fromDate"
                                            className="form-control"
                                            placeholder='Select From Date'
                                            value={fromDate}
                                            onChange={([date]) => setFromDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                    </div>
                                    <div className="date-filters">
                                        <label htmlFor="toDate" className="form-label me-2">To Date:</label>
                                        <Flatpickr
                                            id="toDate"
                                            className="form-control"
                                            placeholder='Select To Date'
                                            value={toDate}
                                            onChange={([date]) => setToDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                    </div>

                                    <div className="search-input">
                                        <label htmlFor="search" className="form-label me-2">Search:</label>
                                        <AsyncSelect
                                            cacheOptions
                                            loadOptions={loadPropertyOptions}
                                            defaultOptions={propertieslist.map((property) => ({
                                                label: property.propertyName,
                                                value: property.propertyGuid,
                                            }))}
                                            onChange={(selectedOption) => setSelectedProperty(selectedOption?.label || '')}
                                            value={selectedProperty ? { label: selectedProperty, value: propertieslist.find((property) => property.propertyName === selectedProperty)?.propertyGuid } : null}
                                            isClearable
                                            placeholder="Select Property Name"
                                        />
                                    </div>
                                </div>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className="table align-middle table-bordered">
                                            <TableHeader columns={['#', 'Property Name', 'Audit Date', 'Comments', 'Added On', 'Add Image', 'Action']} />
                                            <tbody className="manage-amenity-master-table-values p-3">
                                                {properties.length > 0 ? (
                                                    properties.map((item, index) => (
                                                        <TablesRow
                                                            key={item.id}
                                                            rowData={{
                                                                id: (currentPage - 1) * entriesPerPage + index + 1,

                                                                propertyName: (
                                                                    <>
                                                                        <span><Link to={`/property/detail/${item.propertyGuid}`}>{item.propertyName}</Link></span><br />
                                                                        <span>{item.flatNo ? (`Flat Number:${item.flatNo}`) : ''}</span>
                                                                    </>
                                                                ),
                                                                expenseDate: formatDate(item.auditDate),
                                                                comments: item.comments,
                                                                addedOn: formatDate(item.addedOn),
                                                                addimage: (
                                                                    <span
                                                                        className="badge text-bg-primary"
                                                                        style={{ fontSize: '11px' }}
                                                                        onClick={() => handleImageClick(item.auditGuid, item.id)}
                                                                    >
                                                                        <Link style={{ color: '#fff' }}>Add Image</Link>
                                                                    </span>
                                                                ),

                                                            }}
                                                            columns={['id', 'propertyName', 'expenseDate', 'comments', 'addedOn', 'addimage']}
                                                            hideIcons={false}
                                                            showIcons={false}
                                                            onEdit={() => {
                                                                navigate(`update/${item.id}`);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                            onDelete={() => handleDelete(item.id)}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                ) : (
                                                    <TableDataStatusError colspan="10" />
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    totalEntries={totalCount}
                                    entriesPerPage={entriesPerPage}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            ) : ''}
            <Modal show={imageModal} onHide={() => setImageModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Attachments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {pageAccessDetails.addAccess ? (
                        <form onSubmit={handleSubmit}>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="attachment" className="form-label">
                                        Attachment (Note: Please attach image or file within 2MB)
                                    </label>
                                    <input
                                        type="file"
                                        onChange={handleAttachmentChange}
                                        className="form-control"
                                        required
                                    />
                                </div>

                                {previewUrl && (
                                    <div className="mb-3">
                                        <label className="form-label">Attachment Preview:</label><br />
                                        {attachment.type.startsWith('image/') ? (
                                            <img src={previewUrl} alt="Preview" style={{ maxWidth: '30%', height: 'auto' }} />
                                        ) : (
                                            <a href={previewUrl} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                        )}
                                    </div>
                                )}
                            </div>
                            <button
                                type="submit"
                                className="btn btn-secondary"
                                disabled={isButtonDisabled}
                            >
                                Submit
                            </button>
                        </form>
                    ) : ''}
                    {pageAccessDetails.viewAccess ? (
                        <ManagePropertyAuditGallery auditId={AuditId} />
                    ) : ''}
                </Modal.Body>
            </Modal>

        </>
    );
};
