import React from 'react'
import { ToastContainer } from 'react-toastify';
import { AddPropertyAudit } from '../components/PropertyAudit/AddPropertyAudit';
export const AddAudit = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
        <AddPropertyAudit/>
        </div>
      </div>
    </div>
  )
}
