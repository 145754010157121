import React from 'react';
import { ToastContainer } from 'react-toastify';
import { CompleteViewOrder } from '../components/OrderManagement/CompleteViewOrder';
export const ManageOrders = () => {
    return (
        <div className="main-content">
            <div className="page-content">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
               <CompleteViewOrder/>
            </div>
        </div>

    )
}
