import React from 'react';
import { ToastContainer } from 'react-toastify';
import {ManageActiveCustomers} from '../components/OnboardCustomers/ManageActiveCustomers';
export const ManageActiveOnboardingCustomers = () => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                    <ManageActiveCustomers/>
                </div>
            </div>

        </div>

    )
}
