import React, { useState, useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import allImages from '../../../assets/images-import';
import { validatePropertyOwnerForm } from '../../../utils/validation';
import { addPropertyOwner } from '../../../services/propertyOwnerService';
import { handleErrors } from '../../../utils/errorHandler';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

const FormInput = ({ id, label, type, value, onChange, error, showPassword, onTogglePassword }) => (
  <div className="col-lg-4 col-md-6 col-sm-12">
    <div className="mb-3 position-relative">
      <label htmlFor={id} className="form-label">{label} {id === 'FatherName' ? '' : (
        <span className='required-field'>*</span>
      )}
      </label>
      <input
        type={showPassword ? "text" : type}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        id={id}
        placeholder={`Enter ${label}`}
        value={value}
        onChange={onChange}
      />
      {error && <div className="invalid-feedback">{error}</div>}
      {type === "password" && (
        <span
          onClick={onTogglePassword}
          className={error ? 'view-hide-password-invalid' : 'viewhidePassword'}
        >
          <i className={`ri-${showPassword ? 'eye-line' : 'eye-off-line'}`}></i>
        </span>
      )}
    </div>
  </div>
);

export const AddPropertyOwner = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    DOB: '',
    contactNo: '',
    PANNo: '',
    FatherName: '',
    CompleteAddress:'',
    owner_password: '',
    owner_confirm_password: '',
    profileImage: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [imageUrl, setImageUrl] = useState(allImages.defaultprofile);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const PageLevelAccessurl = 'property-owner/add';
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.addAccess) {
        navigate('/404-error-page');
      } else {
        return;
      }

    } else {
      console.log('No page access details found');
    }
  },[pageAccessData, navigate])

  const [showPassword, setShowPassword] = useState({
    owner_password: false,
    owner_confirm_password: false,
  });

  const isAdult = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };

  const handleTogglePassword = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      contactNo: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      setFormValues((prevValues) => ({
        ...prevValues,
        profileImage: file,
      }));
    }
  };

  const formatDate = (date) => {
    if (!date) return "-";
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options).replace(/ /g, '-');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validatePropertyOwnerForm(formValues);
    if (formValues.owner_password !== formValues.owner_confirm_password) {
      errors.owner_confirm_password = "Passwords do not match";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        setIsButtonDisabled(true);
        const formattedDOB = formatDate(formValues.DOB);
        const dataToSend = { ...formValues, DOB: formattedDOB };
        await addPropertyOwner(dataToSend);
        toast.success("Property owner added successfully!");
        setFormValues({
          firstName: '',
          lastName: '',
          emailAddress: '',
          DOB: '',
          contactNo: '',
          PANNo: '',
          FatherName: '',
          CompleteAddress:'',
          owner_password: '',
          owner_confirm_password: '',
          profileImage: null,
        });
        setIsButtonDisabled(false);
        setImageUrl(allImages.defaultprofile);
        setApiError('');
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      }
    }
  };

  return (
    <>
      <ComponentHeader title='Property Owner' />
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-sm-1 mt-sm-1">Add Property Owner</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                      <img
                        src={imageUrl}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image shadow"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <input id="profileImage" type="file" className="profile-img-file-input" onChange={handleFileChange} />
                        <label htmlFor="profileImage" className="profile-photo-edit avatar-xs">
                          <span className="avatar-title rounded-circle bg-light text-body shadow">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="row">
                      <FormInput id="firstName" label="First Name" type="text" value={formValues.firstName} onChange={handleInputChange} error={formErrors.firstName} />
                      <FormInput id="lastName" label="Last Name" type="text" value={formValues.lastName} onChange={handleInputChange} error={formErrors.lastName} />
                      <FormInput id="emailAddress" label="Email Address" type="text" value={formValues.emailAddress} onChange={handleInputChange} error={formErrors.emailAddress} />
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label htmlFor="contactNo"  className="form-label">Date of Birth</label>
                        <Flatpickr
                          className={`form-control ${formErrors.DOB ? 'is-invalid' : ''}`}
                          value={formValues.DOB ? new Date(formValues.DOB) : null}
                          placeholder='Enter Date Of Birth'
                          onChange={(date) => {
                            const selectedDate = date.length ? date[0].toISOString() : '';
                            setFormValues({ ...formValues, DOB: selectedDate });

                            if (selectedDate) {
                              const age = isAdult(selectedDate);
                              if (age < 18) {
                                setFormErrors({ ...formErrors, DOB: 'You must be at least 18 years old.' });
                              } else {
                                setFormErrors({ ...formErrors, DOB: '' });
                              }
                            }
                          }}
                          options={{
                            dateFormat: "d-M-Y",
                            monthSelectorType: 'static',
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label htmlFor="contactNo" className="form-label">Phone Number <span className='required-field'>*</span></label>
                        <PhoneInput
                          international
                          id="contactNo"
                          value={formValues.contactNo}
                          onChange={handlePhoneChange}
                          defaultCountry='IN'
                          maxLength="15"
                          placeholder='Enter Phone Number'
                        />
                        {formErrors.contactNo && <div style={{ color: '#dc3545' }}>{formErrors.contactNo}</div>}
                      </div>
                      <FormInput id="PANNo" label="Pan Number" type="text" value={formValues.PANNo} onChange={handleInputChange} error={formErrors.PANNo} />
                      <FormInput id="FatherName" label="Father Name" type="text" value={formValues.FatherName} onChange={handleInputChange} error={formErrors.FatherName} />
                      <div className="col-lg-8 col-md-12 col-sm-12 pb-2">
                        <label htmlFor="CompleteAddress" className="form-label">Complete Address<span className='required-field'>*</span></label>
                        <textarea
                        id="CompleteAddress"
                        name="CompleteAddress"
                        value={formValues.CompleteAddress}
                        placeholder='Enter Complete Address'
                        onChange={handleInputChange}
                        className={`form-control ${formErrors.CompleteAddress ? 'is-invalid' : ''}`}
                      />
                        {formErrors.CompleteAddress && <div style={{ color: '#dc3545' }}>{formErrors.CompleteAddress}</div>}
                      </div>
                      <FormInput
                        id="owner_password"
                        label="Password"
                        type="password"
                        value={formValues.owner_password}
                        onChange={handleInputChange}
                        error={formErrors.owner_password}
                        showPassword={showPassword.owner_password}
                        onTogglePassword={() => handleTogglePassword("owner_password")}
                      />
                      
                      <FormInput
                        id="owner_confirm_password"
                        label="Confirm Password"
                        type="password"
                        value={formValues.owner_confirm_password}
                        onChange={handleInputChange}
                        error={formErrors.owner_confirm_password}
                        showPassword={showPassword.owner_confirm_password}
                        onTogglePassword={() => handleTogglePassword("owner_confirm_password")}
                      />

                      {apiError && <div className="alert alert-danger">{apiError}</div>}

                      <div className="col-lg-12">
                        <div className="d-flex justify-content-start">
                          <button type="submit" className="btn btn-secondary pt-1 pb-1" disabled={isButtonDisabled}>{isButtonDisabled ? 'Saving' : 'Save'}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPropertyOwner;
