import React, { useState, useEffect, useCallback } from 'react';
import { createVendorMaster, updateVendor, fetchVendorById } from '../../../services/VendorService';
import { validateVendorForm } from '../../../utils/validation';
import { toast } from 'react-toastify';
import { fetchCities } from '../../../services/cityService';
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../../utils/errorHandler';
import PhoneInput from 'react-phone-number-input';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';

export const AddVendor = ({ editMode = false, initialData = {}, onSuccess, setSelectedPageGroup, setEditMode }) => {
  const [formData, setFormData] = useState({
    city: '',
    vendorName: '',
    contactNo: '',
    emailAddress: '',
    buildingNo: '',
    streetAddress: '',
    _State: '',
    pincode: '',
    gstin: '',
    businessName: ''
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [cities, setCities] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchCitiesData = async () => {
      try {
        const citiesData = await fetchCities();
        setCities(citiesData.result);
      } catch (error) {
        handleErrors(error);
      }
    };

    fetchCitiesData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchVendorById(initialData.id);
          setFormData({
            city: data.city || '',
            vendorName: data.vendorName || '',
            contactNo: data.contactNo || '',
            emailAddress: data.emailAddress || '',
            buildingNo: data.buildingNo || '',
            streetAddress: data.streetAddress || '',
            _State: data._State || '',
            pincode: data.pincode || '',
            gstin: data.gstin || '',
            businessName: data.businessName || ''
          });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({
          city: '',
          vendorName: '',
          contactNo: '',
          emailAddress: '',
          buildingNo: '',
          streetAddress: '',
          _State: '',
          pincode: '',
          gstin: '',
          businessName: ''
        });
      }
    };

    fetchData();
  }, [editMode, initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (phone) => {
    setFormData((prevState) => ({ ...prevState, contactNo: phone }));
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { valid, errors: validationErrors } = validateVendorForm(formData);
    setErrors(validationErrors);

    if (valid) {
      setApiError('');

      try {
        setIsButtonDisabled(true);
        if (editMode) {
          await updateVendor({ ...formData, id: initialData.id });
          toast.success('Vendor updated successfully!');
          setEditMode(false);
        } else {

          await createVendorMaster({
            city: formData.city,
            vendorName: formData.vendorName,
            contactNo: formData.contactNo,
            emailAddress: formData.emailAddress,
            buildingNo: formData.buildingNo,
            streetAddress: formData.streetAddress,
            _State: formData._State,
            pincode: formData.pincode,
            gstin: formData.gstin,
            businessName: formData.businessName,
          });
          toast.success('Vendor added successfully!');
        }

        setFormData({
          city: '',
          vendorName: '',
          contactNo: '',
          emailAddress: '',
          buildingNo: '',
          streetAddress: '',
          _State: '',
          pincode: '',
          gstin: '',
          businessName: ''
        });
        setIsButtonDisabled(false);
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      } finally {
        setIsButtonDisabled(false);
      }
    }
  }, [formData, editMode, initialData, onSuccess, setEditMode]);

  const handleAddNewClick = () => {
    setFormData({
      city: '',
      vendorName: '',
      contactNo: '',
      emailAddress: '',
      buildingNo: '',
      streetAddress: '',
      _State: '',
      pincode: '',
      gstin: '',
      businessName: ''
    });
    setErrors({});
    setApiError('');
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>
      <ComponentHeader title='Vendor Master' />

      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? 'Update Vendor' : 'Add Vendor'}</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="vendorName" className="form-label">Vendor Name <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="vendorName"
                        value={formData.vendorName}
                        placeholder='Enter Vendor Name'
                        onChange={handleInputChange}
                        className={`form-control ${errors.vendorName ? 'is-invalid' : ''}`}
                      />
                      {errors.vendorName && <div className="invalid-feedback">{errors.vendorName}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="contactNo" className="form-label">Vendor Contact Number <span className='required-field'>*</span></label>
                      <PhoneInput
                        international
                        id="contactNo"
                        value={formData.contactNo}
                        onChange={handlePhoneChange}
                        defaultCountry='IN'
                        maxLength="15"
                        placeholder='Enter Vendor Contact Number'
                      />
                      {errors.contactNo && <div style={{ color: '#dc3545' }}>{errors.contactNo}</div>}


                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="emailAddress" className="form-label">Vendor Email Address <span className='required-field'>*</span></label>
                      <input
                        type="email"
                        name="emailAddress"
                        value={formData.emailAddress}
                        placeholder='Enter Vendor Email Address'
                        onChange={handleInputChange}
                        className={`form-control ${errors.emailAddress ? 'is-invalid' : ''}`}
                      />
                      {errors.emailAddress && <div className="invalid-feedback">{errors.emailAddress}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="businessName" className="form-label">Business Name</label>
                      <input
                        type="text"
                        name="businessName"
                        value={formData.businessName}
                        placeholder='Enter Business Name'
                        onChange={handleInputChange}
                        className={`form-control ${errors.businessName ? 'is-invalid' : ''}`}
                      />
                      {errors.businessName && <div className="invalid-feedback">{errors.businessName}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="buildingNo" className="form-label">Building Number <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="buildingNo"
                        value={formData.buildingNo}
                        placeholder='Enter Building Number'
                        onChange={handleInputChange}
                        className={`form-control ${errors.buildingNo ? 'is-invalid' : ''}`}
                      />
                      {errors.buildingNo && <div className="invalid-feedback">{errors.buildingNo}</div>}
                    </div>
                  </div>

                  <div className="col-lg-9 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="streetAddress" className="form-label">Street Address <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="streetAddress"
                        value={formData.streetAddress}
                        placeholder='Enter Street Address'
                        onChange={handleInputChange}
                        className={`form-control ${errors.streetAddress ? 'is-invalid' : ''}`}
                      />
                      {errors.streetAddress && <div className="invalid-feedback">{errors.streetAddress}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">Select City <span className='required-field'>*</span></label>
                      <select
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select City</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.cityName}>{city.cityName}</option>
                        ))}
                      </select>
                      {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="_State" className="form-label">State <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="_State"
                        value={formData._State}
                        placeholder='Enter State'
                        onChange={handleInputChange}
                        className={`form-control ${errors._State ? 'is-invalid' : ''}`}
                      />
                      {errors._State && <div className="invalid-feedback">{errors._State}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="pincode" className="form-label">Pincode <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        placeholder='Enter Pincode'
                        onChange={handleInputChange}
                        className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                      />
                      {errors.pincode && <div className="invalid-feedback">{errors.pincode}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="gstin" className="form-label">GSTIN <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="gstin"
                        value={formData.gstin}
                        minLength={15}
                        maxLength={15}
                        placeholder='Enter GST Identification Number'
                        onChange={handleInputChange}
                        className={`form-control ${errors.gstin ? 'is-invalid' : ''}`}
                      />
                      {errors.gstin && <div className="invalid-feedback">{errors.gstin}</div>}
                    </div>
                  </div>


                </div>

                <div className="col-lg-12">
                  <div className="pt-4">
                    <button type="submit" className="btn btn-secondary pt-1 pb-1 p-3" disabled={isButtonDisabled}>
                      {isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}
                    </button>
                    {editMode && (
                      <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1 pt-1 pb-1 p-3">
                        Cancel
                      </button>
                    )}
                  </div>
                </div>

                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendor;
