import React, { useState, useEffect, useCallback } from "react";
import {
  addCaretaker,
  updateAssignedCaretaker,
  fetchAssignedCaretakerById,
} from "../../../services/AssignPropertiesCaretaker";
import { validateAssignProperties } from "../../../utils/validation";
import { toast } from "react-toastify";
import { handleErrors } from "../../../utils/errorHandler";
import { fetchSWRProperty } from "../../../services/cityService";
import { fetchPropertiesByCaretaker } from "../../../services/cityService";
import { fetchPropertiesByLocations } from "../../../services/AssignProperties";
import "react-toastify/dist/ReactToastify.css";
import { fetchCareTakersList } from "../../../services/AssignPropertiesCaretaker";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import ComponentHeader from "../../Common/OtherElements/ComponentHeader";

export const AddCareTaker = ({
  editMode = false,
  initialData = {},
  onSuccess,
  setSelectedPageGroup,
  setEditMode,
}) => {
  const [formData, setFormData] = useState({
    locationName: "",
    propertyGuid: [],
  });
  const [errors, setErrors] = useState({
    propertyGuid: [],
    userGuid: "",
  });
  const [apiError, setApiError] = useState("");

  const [properties, setProperties] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [supervisors, setSupervisors] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const supervisorData = await fetchCareTakersList();
        setSupervisors(supervisorData);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetchSupervisors();
  }, []);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const result = await fetchSWRProperty();
        setProperties(result);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    getProperties();
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      if (selectedLocation) {
        try {
          const propertyData = await fetchPropertiesByLocations(
            selectedLocation
          );
          setProperties(propertyData);
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setProperties([]);
      }
    };
    fetchProperties();
  }, [selectedLocation]);

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchAssignedCaretakerById(initialData.id);
          const propertyGuid = data.result.propertyGuid || [];
          setSelectedProperty(propertyGuid);

          setFormData({
            propertyGuid: propertyGuid,
            userGuid: data.result.userGuid || "",
          });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({ city: "", locationName: "" });
      }
    };

    fetchData();
  }, [editMode, initialData]);


  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { valid, errors: validationErrors } =
        validateAssignProperties(formData);
      setErrors(validationErrors);

      if (valid) {
        setApiError("");
        try {
          if (editMode) {
            setIsButtonDisabled(true);
            await updateAssignedCaretaker({ ...formData, id: initialData.id });
            toast.success("Care Taker updated successfully!");
            setIsButtonDisabled(false);
            setEditMode(false);
          } else {
            setIsButtonDisabled(true);
            await addCaretaker({
              formData,
            });
            toast.success("Care Taker added successfully!");
            setIsButtonDisabled(false);
          }
          setFormData({
            propertyGuid: [],
            userGuid: "",
          });
          if (onSuccess) {
            onSuccess();
          }
        } catch (error) {
          handleErrors(error);
          setIsButtonDisabled(false);
        }
      }
    },
    [formData, editMode, initialData, onSuccess, setEditMode]
  );

  const handleAddNewClick = () => {
    setSelectedLocation("");
    setSelectedProperty("");
    setFormData({
      propertyGuid: selectedProperty,
      userGuid: "",
    });
    setErrors({
      propertyGuid: [],
      userGuid: "",
    });
    setApiError("");
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>
      <ComponentHeader title="Care Taker" />

      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">
                {editMode ? "Update Care Taker" : "Add Care Taker"}
              </h5>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="supervisorId" className="form-label">
                        Care Taker <span className="required-field">*</span>
                      </label>
                      <select
                        name="userGuid"
                        className={`form-select ${
                          errors.userGuid ? "is-invalid" : ""
                        }`}
                        value={formData.userGuid}
                        onChange={async (e) => {
                          const selectedSupervisor = e.target.value;
                          setFormData((prevData) => ({
                            ...prevData,
                            userGuid: selectedSupervisor,
                          }));

                          if (selectedSupervisor) {
                            try {
                              const assignedProperties =
                                await fetchPropertiesByCaretaker(
                                  selectedSupervisor
                                );

                              setFormData((prevData) => ({
                                ...prevData,
                                propertyGuid: assignedProperties.map(
                                  (property) => property.propertyGuid
                                ),
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching properties for CareTaker:",
                                error
                              );
                            }
                          } else {
                            setFormData((prevData) => ({
                              ...prevData,
                              propertyGuid: [],
                            }));
                          }
                        }}
                      >
                        <option value="">Select CareTaker</option>
                        {supervisors.map((supervisor) => (
                          <option
                            key={supervisor.userGuid}
                            value={supervisor.userGuid}
                          >
                            {supervisor.userName}
                          </option>
                        ))}
                      </select>
                      {errors.userGuid && (
                        <div className="invalid-feedback">
                          {errors.userGuid}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyGuid" className="form-label">
                        Property Name <span className="required-field">*</span>
                      </label>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        sx={{
                          m: 1,
                          width: "100%",
                        }}
                      >
                        <Select
                          labelId="property-multiple-checkbox-label"
                          className={`form-select ${
                            errors.propertyGuid ? "is-invalid" : ""
                          }`}
                          id="property-multiple-checkbox"
                          multiple
                          value={
                            Array.isArray(formData.propertyGuid)
                              ? formData.propertyGuid
                              : []
                          }
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setFormData((prevData) => ({
                              ...prevData,
                              propertyGuid: Array.isArray(value) ? value : [],
                            }));
                          }}
                          input={<OutlinedInput label="Select Property" />}
                          renderValue={(selected) =>
                            selected
                              .map(
                                (propertyGuid) =>
                                  properties.find(
                                    (property) =>
                                      property.propertyGuid === propertyGuid
                                  )?.propertyName
                              )
                              .join(", ")
                          }
                        >
                          {properties.length > 0 ? (
                            properties.map((property) => (
                              <MenuItem
                                key={property.propertyGuid}
                                value={property.propertyGuid}
                              >
                                <Checkbox
                                  checked={
                                    formData.propertyGuid &&
                                    formData.propertyGuid.includes(
                                      property.propertyGuid
                                    )
                                  }
                                />
                                <ListItemText primary={property.propertyName} />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>No Properties Found</MenuItem>
                          )}
                        </Select>
                        {errors.propertyGuid && (
                          <div className="invalid-feedback">
                            {errors.propertyGuid}
                          </div>
                        )}
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="pt-4">
                      <button
                        type="submit"
                        className="btn btn-secondary pt-1 pb-1 p-3"
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled
                          ? editMode
                            ? "Updating"
                            : "Saving"
                          : editMode
                          ? "Update"
                          : "Save"}
                      </button>
                      {editMode && (
                        <button
                          type="button"
                          onClick={handleAddNewClick}
                          className="btn btn-danger ms-1 pt-1 pb-1 p-3"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && (
                  <div className="alert alert-danger">{apiError}</div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
