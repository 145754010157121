import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { paginateData, calculateTotalPages } from '../../../assets/js/script';
import TableHeader from '../../Common/TableComponent/TableHeader';
import EntriesDropdown from '../../Common/TableComponent/EntriesDropdown';
import TablesRow from '../../Common/TableComponent/TablesRow';
import { Pagination } from '../../Common/TableComponent/Pagination';
import { getPropertyOwners, deletePropertyOwner } from '../../../services/propertyOwnerService';
import { Loading } from '../../Common/OtherElements/Loading';
import Swal from 'sweetalert2';
import allImages from '../../../assets/images-import';
import { confirmDelete } from '../../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../utils/errorHandler';
import { Link } from 'react-router-dom';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';

const DEFAULT_ENTRIES_PER_PAGE = 10;

export const ManagePropertyOwnerContent = ({ onEdit }) => {
    const [entriesPerPage, setEntriesPerPage] = useState(DEFAULT_ENTRIES_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [manageAmenityMaster, setManageAmenityMaster] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'property-owner';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const fetchData = useCallback(async () => {
        try {
            const response = await getPropertyOwners();
            setManageAmenityMaster(response.data.result);
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filteredData = manageAmenityMaster.filter(item => {
        const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
    });

    const currentData = paginateData(filteredData, currentPage, entriesPerPage);
    const totalPages = calculateTotalPages(filteredData.length, entriesPerPage);

    const handleEntriesChange = (value) => {
        setEntriesPerPage(value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleDelete = async (ownerId) => {
        const confirmed = await confirmDelete('Property owner');
        if (confirmed) {
            try {
                await deletePropertyOwner(ownerId);
                setManageAmenityMaster(prev => prev.filter(item => item.id !== ownerId));
                Swal.fire('Deleted!', 'The property owner has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    return (
        <>

            {pageAccessDetails.viewAccess ? (

                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Manage Property Owners</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="pagination-details-responsive justify-content-between mb-3">
                                    <EntriesDropdown
                                        entriesPerPage={entriesPerPage}
                                        onEntriesChange={handleEntriesChange}
                                    />
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="form-control mb-2"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className="table table-nowrap align-middle table-bordered">
                                            <TableHeader columns={['#', 'Profile Image', 'First Name', 'Last Name', 'Email Address', 'Phone Number', 'Added On', 'Documents', 'Action']} />
                                            <tbody className="manage-property-owner-table-values">
                                                {currentData.length === 0 ? (
                                                    <TableDataStatusError colspan="8" />
                                                ) : (
                                                    currentData.map((item, index) => (
                                                        <TablesRow
                                                            key={item.id}
                                                            rowData={{
                                                                id: (currentPage - 1) * entriesPerPage + index + 1,
                                                                profileImage: <img src={item.profileImage ? item.profileImage : allImages.defaultprofile} alt="Profile" className="profile-img" />,
                                                                firstName: item.firstName,
                                                                lastName: item.lastName,
                                                                emailAddress: item.emailAddress,
                                                                contactNo: item.contactNo,
                                                                addedOn: new Date(item.addedOn).toLocaleDateString(),
                                                                documents: (
                                                                    <span className="badge text-bg-danger" style={{ fontSize: '11px' }}><Link to={`detail/${item.ownerGuid}`} style={{ color: '#fff' }}>Add Documents</Link></span>
                                                                ),
                                                            }}
                                                            columns={['id', 'profileImage', 'firstName', 'lastName', 'emailAddress', 'contactNo', 'addedOn', 'documents']}
                                                            hideIcons={false}
                                                            onEdit={() => navigate(`update/${item.id}`)}
                                                            onDelete={() => handleDelete(item.id)}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    totalEntries={filteredData.length}
                                    entriesPerPage={entriesPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
};
