import React, { useState, useEffect, useCallback } from 'react';
import { fetchSWKProperty } from '../../services/SWKPropertService';
import { fetchFlats } from '../../services/FlatMasterService';
import { addSupport } from '../../services/SupportService';
import ComponentHeader from '../Common/OtherElements/ComponentHeader';
import { validateSupportForm } from '../../utils/validation';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import "react-toastify/dist/ReactToastify.css";
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const AddSupport = () => {
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [flats, setFlats] = useState([]);
    const [selectedFlat, setSelectedFlat] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [description, setDescription] = useState('');
    const [attachment, setAttachment] = useState(null); 
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [apiError, setApiError] = useState({});
    const [previewUrl, setPreviewUrl] = useState(null);
    const PageLevelAccessurl = 'support/add';
    const navigate = useNavigate();
    const { pageAccessData} = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
      if (pageAccessData) {
          if (!pageAccessData.addAccess) {
              navigate('/404-error-page');
          } else {
              return;
          }

      } else {
          console.log('No page access details found');
      }
  })

    useEffect(() => {
        const getProperties = async () => {
            try {
                const result = await fetchSWKProperty();
                setProperties(result);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        getProperties();
    }, []);

    useEffect(() => {
        const getFlats = async () => {
            if (selectedProperty) {
                try {
                    const result = await fetchFlats(selectedProperty);
                    setFlats(result);
                } catch (error) {
                    console.error('Error fetching flats:', error);
                }
            } else {
                setFlats([]);
            }
        };
        getFlats();
    }, [selectedProperty]);

    const handleAttachmentChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);

        if (file) {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        } else {
            setPreviewUrl(null);
        }
    };

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = properties.filter((property) =>
            property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredProperties.map((property) => ({
                    label: property.propertyName,
                    value: property.propertyGuid,
                }))
            );
        }, 1000);
    };

    const loadFlatOptions = (inputValue, callback) => {
        const filteredFlats = flats.filter((flat) =>
            flat.flatNo.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredFlats.map((flat) => ({
                    label: flat.flatNo,
                    value: flat.flatGuid,
                }))
            );
        }, 1000);
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setApiError({});

        const validationErrors = validateSupportForm(selectedFlat, selectedProduct, description);

        if (Object.keys(validationErrors).length > 0) {
            setApiError(validationErrors);
            return;
        }

        try {
            setIsButtonDisabled(true);
            const formData = new FormData();
            formData.append('PropertyGuid',selectedProperty);
            formData.append('FlatGuid', selectedFlat);
            formData.append('TypeOfSupport', selectedProduct);
            formData.append('Description', description);
            if (attachment) {
                formData.append('Attachment', attachment);
            }

            await addSupport(formData);
            toast.success('Support request added successfully!');
            setSelectedFlat('');
            setSelectedProduct('');
            setDescription('');
            setAttachment(null);
            setPreviewUrl(null);
        } catch (error) {
            setApiError({ flatGuid: 'Error adding support request' });
            toast.error('Error occurred while adding support request!');
        } finally {
            setIsButtonDisabled(false);
        }
    }, [selectedProperty, selectedFlat, selectedProduct, description, attachment]);

    return (
        <>
            <ComponentHeader title="Add Support" />

            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-1 mt-sm-1">Add Support</h5>
                        </div>
                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="propertyName" className="form-label">Property Name</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadPropertyOptions}
                                                defaultOptions={properties.map((property) => ({
                                                    label: property.propertyName,
                                                    value: property.propertyGuid,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedProperty(selectedOption?.value || '');
                                                }}
                                                value={{
                                                    label: properties.find((property) => property.propertyGuid === selectedProperty)?.propertyName,
                                                    value: selectedProperty,
                                                }}
                                                isClearable
                                                placeholder="Select Property"
                                                className={apiError.propertyGuid ? 'is-invalid' : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="flatGuid" className="form-label">Flat Number</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadFlatOptions}
                                                defaultOptions={flats.map((flat) => ({
                                                    label: flat.flatNo,
                                                    value: flat.flatGuid,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedFlat(selectedOption?.value || '');
                                                }}
                                                value={{
                                                    label: flats.find((flat) => flat.flatGuid === selectedFlat)?.flatNo,
                                                    value: selectedFlat,
                                                }}
                                                isClearable
                                                placeholder="Select Flat"
                                                className={apiError.flatGuid ? 'is-invalid' : ''}
                                            />
                                            {apiError.flatGuid && <div className="invalid-feedback">{apiError.flatGuid}</div>}
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="productName" className="form-label">Type of Support <span className='required-field'>*</span></label>
                                            <select
                                                value={selectedProduct}
                                                onChange={(e) => setSelectedProduct(e.target.value)}
                                                className={`form-select ${apiError.typeOfSupport ? 'is-invalid' : ''}`}
                                            >
                                                <option value="">Select Support Type</option>
                                                <option value="Common area">Common area</option>
                                                <option value="Electrical issue">Electrical issue</option>
                                                <option value="Flat furnishing">Flat furnishing</option>
                                                <option value="Flat renovation">Flat renovation</option>
                                                <option value="Furniture issue">Furniture issue</option>
                                                <option value="House keeping">House keeping</option>
                                                <option value="Lift issue">Lift issue</option>
                                                <option value="Offline marketing">Offline marketing</option>
                                                <option value="Painting">Painting</option>
                                                <option value="Pest control">Pest control</option>
                                                <option value="Plumbing issue">Plumbing issue</option>
                                                <option value="Porter">Porter</option>
                                                <option value="Water issue">Water issue</option>
                                                <option value="Water tanker">Water tanker</option>
                                            </select>
                                            {apiError.typeOfSupport && <div className="invalid-feedback">{apiError.typeOfSupport}</div>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description <span className='required-field'>*</span></label>
                                            <textarea
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                className={`form-control ${apiError.description ? 'is-invalid' : ''}`}
                                                rows="4"
                                            />
                                            {apiError.description && <div className="invalid-feedback">{apiError.description}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="mb-3">
                                        <label htmlFor="attachment" className="form-label">Attachment (Note: Please attach image or file within 2MB)</label>
                                        <input
                                            type="file"
                                            onChange={handleAttachmentChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {previewUrl && (
                                        <div className="mb-3">
                                            <label className="form-label">Attachment Preview:</label><br/>
                                            {attachment.type.startsWith("image/") ? (
                                                <img src={previewUrl} alt="Preview" style={{ maxWidth: "30%", height: "auto" }} />
                                            ) : (
                                                <a href={previewUrl} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={isButtonDisabled}
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSupport;
