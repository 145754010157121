import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPropertyOwnerDatabyGuid } from '../../../../services/propertyOwnerService';
import { ManageOwnerDocuments } from './ManageOwnerDocuments';
import { Loading } from '../../../Common/OtherElements/Loading';
import { ManageOwnerProperty } from './ManageOwnerProperty';
import { usePageLevelAccess } from '../../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';
import { AddOwnerBankDetails } from './AddOwnerBankDetails';
import { ManageAgreementPropertyList } from './ManageAgreementPropertyList';
import { CompleteOwnerDetails } from './CompleteOwnerDetails';

export const HeaderTab = () => {
    const { ownerguid } = useParams();
    const [propertyDetails, setPropertyDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('flats');
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'property-owner/detail/:ownerguid';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }
        } else {
            console.log('No page access details found');
        }
    }, [pageAccessData, navigate]); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchPropertyOwnerDatabyGuid(ownerguid);
                setPropertyDetails(data);
            } catch (err) {
                setError('Failed to fetch property owner details.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [ownerguid]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <div className="card mt-n4 mx-n4 bg-soft-info">
                <div>
                    <div className="card-body pb-0 px-4">
                        <div className="row mb-3">
                            <div className="col-md">
                                <div className="row align-items-center g-3">

                                    <div className="col-md">
                                        <div>
                                            <h4 className="fw-bold">
                                                Property Owner: <span>{propertyDetails.firstName} {propertyDetails.lastName}</span>

                                                <a
                                                    id="editLink"
                                                    style={{ float: 'right' }}
                                                    title="Edit"
                                                    href={`/property-owner/update/${propertyDetails.id}`}
                                                >
                                                    <i className="mdi mdi-square-edit-outline fs-20"></i>
                                                </a>
                                            </h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <h5 className="fw-bold">
                                                    <i className='ri-mail-fill' style={{ fontSize: '16px' }}></i><span>{propertyDetails.emailAddress}</span>

                                                </h5>
                                                <div className="vr"></div>
                                                <h5 className="fw-bold">
                                                    <i className='ri-phone-fill' style={{ fontSize: '16px' }}></i><span>{propertyDetails.contactNo}</span>

                                                </h5>

                                            </div>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div>Created On: <span className="fw-medium">{propertyDetails.addedOn}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="nav nav-tabs-custom border-bottom-0" role="tablist">
                            {pageAccessDetails.viewAccess ? (
                                <li className="nav-item" id="documentsTab" role="presentation">
                                    <a className={`nav-link ${activeTab === 'flats' ? 'active' : ''}`} data-bs-toggle="tab" href="#profile1" role="tab" aria-selected={activeTab === 'flats'} onClick={() => setActiveTab('flats')}
                                    >
                                        Owner Details
                                        
                                    </a>
                                </li>
                            ) : ''}

                            {pageAccessDetails.addAccess || pageAccessDetails.viewAccess ? (
                                <li className="nav-item" id="teamsTab" role="presentation">
                                    <a className={`nav-link ${activeTab === 'propertyNearby' ? 'active' : ''}`} data-bs-toggle="tab" href="#messages1" role="tab" aria-selected={activeTab === 'propertyNearby'} onClick={() => setActiveTab('propertyNearby')}
                                    >

                                        Owner Documents
                                    </a>
                                </li>
                            ) : ''}
                            {pageAccessDetails.addAccess ? (
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link ${activeTab === 'propertyDetails' ? 'active' : ''}`} data-bs-toggle="tab" href="#home1" role="tab" aria-selected={activeTab === 'propertyDetails'} onClick={() => setActiveTab('propertyDetails')}
                                    >
                                        Bank Details
                                    </a>
                                </li>
                            ) : ''}
                            {pageAccessDetails.addAccess || pageAccessDetails.viewAccess ? (
                                <li className="nav-item" id="teamsTab" role="presentation">
                                    <a className={`nav-link ${activeTab === 'Agreement' ? 'active' : ''}`} data-bs-toggle="tab" href="#agreement1" role="tab" aria-selected={activeTab === 'Agreement'} onClick={() => setActiveTab('Agreement')}
                                    >

                                        Agreement
                                    </a>
                                </li>
                            ) : ''}
                            {pageAccessDetails.viewAccess ? (
                                <li className="nav-item" id="teamsTab" role="presentation">
                                    <a className={`nav-link ${activeTab === 'OwnerDetails' ? 'active' : ''}`} data-bs-toggle="tab" href="#OwnerDetails1" role="tab" aria-selected={activeTab === 'OwnerDetails'} onClick={() => setActiveTab('OwnerDetails')}
                                    >
                                        Property List
                                    </a>
                                </li>
                            ) : ''}
                        </ul>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-xxl-12">
                    {activeTab !== 'propertyNearby' ? (
                        <div className="card mt-xxl-n5">
                            <div className="tab-content text-muted">
                                <div className={`tab-pane ${activeTab === 'propertyDetails' ? 'active show' : ''}`} id="home1" role="tabpanel">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 ms-2">
                                            <AddOwnerBankDetails ownerguid={ownerguid} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${activeTab === 'flats' ? 'active show' : ''}`} id="profile1" role="tabpanel">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                        <CompleteOwnerDetails ownerDetails={propertyDetails}/>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${activeTab === 'Agreement' ? 'active show' : ''}`} id="agreement1" role="tabpanel">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 table-responsive">
                                            <ManageAgreementPropertyList ownerguid={ownerguid} />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${activeTab === 'OwnerDetails' ? 'active show' : ''}`} id="OwnerDetails1" role="tabpanel">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 table-responsive">
                                        <ManageOwnerProperty ownerguid={ownerguid} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="tab-content text-muted">
                            <div className={`tab-pane active show`} id="messages1" role="tabpanel">
                                <div>
                                    {pageAccessDetails.viewAccess ? (
                                        <div className="flex-grow-1 ms-2">
                                            <ManageOwnerDocuments ownerguid={ownerguid} PageLevelAccessurl={pageAccessDetails} />
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
