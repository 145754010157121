import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { validateExtendStayForm } from '../../utils/validation'; 
import { Extendorder } from '../../services/orderManagementService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../utils/errorHandler';

const ExtendStayModal = ({ showModal, handleCloseModal, selectedOrderGuid }) => {
    const [formValues, setFormValues] = useState({
        OrderGuid: selectedOrderGuid,
        NoOfMonth: '',
        rentIncrementPercent: '',
    });
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        setFormValues((prev) => ({
            ...prev,
            OrderGuid: selectedOrderGuid,
        }));
    }, [selectedOrderGuid]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        const validationErrors = validateExtendStayForm(formValues);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            setIsButtonDisabled(true);
            await Extendorder(formValues);
            toast.success("Booking Extended successfully!");
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsButtonDisabled(false);
            handleCloseModal();
        }
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Extend Stay</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formNoOfMonth">
                        <Form.Label>No Of Months</Form.Label>
                        <Form.Control
                            type="number"
                            name="NoOfMonth"
                            value={formValues.NoOfMonth}
                            onChange={handleChange}
                            isInvalid={!!errors.NoOfMonth}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.NoOfMonth}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formRentIncrementPercent" className="mt-3">
                        <Form.Label>Rent Increment Percent</Form.Label>
                        <Form.Control
                            type="number"
                            name="rentIncrementPercent"
                            value={formValues.rentIncrementPercent}
                            onChange={handleChange}
                            isInvalid={!!errors.rentIncrementPercent}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.rentIncrementPercent}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSubmit} disabled={isButtonDisabled}>
                    {isButtonDisabled ? 'Confirming...' : 'Confirm Extend Stay'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExtendStayModal;