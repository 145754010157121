import React from 'react'
import { ToastContainer } from 'react-toastify';
import { AddPropertyExpenses } from '../components/PropertyExpenses/AddPropertyExpenses';
export const AddPropertyExp = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
        <AddPropertyExpenses/>
        </div>
      </div>
    </div>
  )
}
