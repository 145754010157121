import React from 'react'
import allImages from '../../assets/images-import';
export const LogOutContent = () => {

    return (
        <>
              <div className="auth-page-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <a href="index.html" className="d-inline-block auth-logo">
                                    <img src={allImages.logoImage} alt="" height="20"/>
                                </a>
                            </div>
                            <p className="mt-3 fs-15 fw-medium">Sowerent</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card mt-4">
                            <div className="card-body p-4 text-center">
                                <div className="mt-4 pt-2">
                                    <h5>You are Logged Out</h5>
                                    
                                    <div className="mt-4">
                                        <a href="/auth/signin" className="btn btn-success w-100">Sign In</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}