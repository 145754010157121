import React, { useState, useEffect, useCallback } from 'react';
import { createAmenityMaster, updateAmenity, fetchAmenityById } from '../../../services/amenityService';
import { validateAmenityMaster } from '../../../utils/validation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrors } from '../../../utils/errorHandler';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';

export const AddAmenityMaster = ({
  editMode = false,
  initialData = {},
  onSuccess,
  setSelectedPageGroup,
  setEditMode
}) => {
  const [formData, setFormData] = useState({
    amenityName: '',
    amenityIcon: ''
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchAmenityData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchAmenityById(initialData.id);
          setFormData({
            amenityName: data.amenityName || '',
            amenityIcon: data.amenityIcon || ''
          });
        } catch (error) {
         handleErrors(error);
        }
      } else {
        resetForm();
      }
    };

    fetchAmenityData();
  }, [editMode, initialData]);

  const resetForm = () => {
    setFormData({ amenityName: '', amenityIcon: '' });
    setErrors({});
    setApiError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { valid, errors: validationErrors } = validateAmenityMaster(formData);
    setErrors(validationErrors);

    if (valid) {
      setApiError('');
      try {
        if (editMode) {
          setIsButtonDisabled(true);
          await updateAmenity({ ...formData, id: initialData.id });
          toast.success('Amenity Master updated successfully!');
          setIsButtonDisabled(false);
          setEditMode(false);
        } else {
          setIsButtonDisabled(true);
          await createAmenityMaster(formData);
          toast.success('Amenity Master added successfully!');
          setIsButtonDisabled(false);
        }
        resetForm();
        onSuccess && onSuccess();
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      }
    }
  }, [formData, editMode, initialData, onSuccess,setEditMode]);

  const handleAddNewClick = () => {
    resetForm();
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>
      <ComponentHeader title='Amenity Master'/>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? 'Update Amenity Master' : 'Add Amenity Master'}</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="amenityName" className="form-label">Amenity Name <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="amenityName"
                        value={formData.amenityName}
                        placeholder='Enter Amenity Name'
                        onChange={handleInputChange}
                        className={`form-control ${errors.amenityName ? 'is-invalid' : ''}`}
                      />
                      {errors.amenityName && <div className="invalid-feedback">{errors.amenityName}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="amenityIcon" className="form-label">Amenity Icon <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="amenityIcon"
                        value={formData.amenityIcon}
                        placeholder='Enter Amenity Icon Link'
                        onChange={handleInputChange}
                        className={`form-control ${errors.amenityIcon ? 'is-invalid' : ''}`}
                      />
                      {errors.amenityIcon && <div className="invalid-feedback">{errors.amenityIcon}</div>}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div>
                      <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                      {isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}
                      </button>
                      {editMode && (
                        <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1">
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
