import React, { useState, useEffect, useCallback } from 'react';
import { fetchSWKProperty } from '../../services/SWKPropertService';
import { fetchFlats } from '../../services/FlatMasterService';
import { assignAssetsDisplay, addAssignAssets, changeAssignStatus, viewAssignedAssetByGuid } from '../../services/AssetsService';
import { fetchProduct } from '../../services/ProductService';
import Swal from "sweetalert2";
import { fetchAssignedAssets } from '../../services/AssetsService';
import ComponentHeader from '../Common/OtherElements/ComponentHeader';
import { Loading } from '../Common/OtherElements/Loading';
import TableHeader from '../Common/TableComponent/TableHeader';
import { handleErrors } from '../../utils/errorHandler';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import TablesRow from '../Common/TableComponent/TablesRow';
import { validateAssignAssetsForm } from '../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AsyncSelect from 'react-select/async';
import { BootstrapTooltip } from '../../assets/js/script';
import { Link } from 'react-router-dom';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const AddAssignAssets = () => {
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [flats, setFlats] = useState([]);
    const [selectedFlat, setSelectedFlat] = useState('');
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [assetOptions, setAssetOptions] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [apiError, setApiError] = useState('');
    const [assetData, setAssetData] = useState([]);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [quantityMessage, setQuantityMessage] = useState('');
    const [rows, setRows] = useState([{ id: Date.now(), assetGuid: '', quantity: '', assetDetails: null }]);
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'assets/assign-assets';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.addAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    useEffect(() => {
        const getProperties = async () => {
            try {
                const result = await fetchSWKProperty();
                setProperties(result);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        getProperties();
    }, []);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const result = await fetchProduct();
                setProductOptions(result.result);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        getProducts();
    }, []);

    useEffect(() => {
        const getFlats = async () => {
            if (selectedProperty) {
                try {
                    const result = await fetchFlats(selectedProperty);
                    setFlats(result);
                } catch (error) {
                    console.error('Error fetching flats:', error);
                }
            } else {
                setFlats([]);
            }
        };
        getFlats();
    }, [selectedProperty]);


    useEffect(() => {
        const fetchAssets = async () => {
            if (selectedFlat) {
                setLoadingAssets(true);
                try {
                    const result = await assignAssetsDisplay(selectedFlat);
                    setAssetData(result.data.result);
                } catch (error) {
                    handleErrors(error);
                    setAssetData([]);
                } finally {
                    setLoadingAssets(false);
                }
            } else {
                setAssetData([]);
            }
        };
        fetchAssets();
    }, [selectedFlat]);


    const handleCancelAssign = (flatGuid, assetGuid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to unassign this asset?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unassign it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await changeAssignStatus(flatGuid, assetGuid);
                    if (response && response.data) {
                        toast.success('Asset unassigned successfully!');
                        const updatedData = await assignAssetsDisplay(flatGuid);
                        setAssetData(updatedData.data.result);
                    }
                } catch (error) {
                    toast.error('Error occurred while unassigning asset!');
                }
            }
        });
    };

    useEffect(() => {
        const getAssetsForProduct = async () => {
            if (selectedProduct) {
                try {
                    const result = await fetchAssignedAssets(selectedProduct);
                    setAssetOptions(result.filter(asset => asset.status === "Active") || []);
                } catch (error) {
                    setAssetOptions([]);
                    console.error("Failed to load assets:", error);
                }
            } else {
                setAssetOptions([]);
            }
        };
        getAssetsForProduct();
    }, [selectedProduct]);

    const loadOptions = async (inputValue) => {
        return assetOptions
            .filter(asset => asset.brandName.toLowerCase().includes(inputValue.toLowerCase()))
            .map((asset) => ({
                label: asset.brandName,
                value: asset.assetGuid,
            }));
    };

    const handleAssetChange = (selectedOption, index) => {
        const updatedRows = [...rows];
        updatedRows[index].assetGuid = selectedOption ? selectedOption.value : '';
        updatedRows[index].assetDetails = null;
        setRows(updatedRows);
    };

    useEffect(() => {
        const fetchAssetDetails = async (assetGuid, index) => {
            if (assetGuid) {
                try {
                    const result = await viewAssignedAssetByGuid(assetGuid);
                    const updatedRows = [...rows];
                    updatedRows[index].assetDetails = result[0];
                    setRows(updatedRows);
                } catch (error) {
                    handleErrors(error);
                    const updatedRows = [...rows];
                    updatedRows[index].assetDetails = null;
                    setRows(updatedRows);
                }
            }
        };

        rows.forEach((row, index) => {
            if (row.assetGuid && row.assetDetails === null) {
                fetchAssetDetails(row.assetGuid, index);
            }
        });
    }, [rows]);

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = properties.filter((property) =>
            property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredProperties.map((property) => ({
                    label: property.propertyName,
                    value: property.propertyGuid,
                }))
            );
        }, 1000);
    };

    const loadProductOptions = (inputValue, callback) => {
        const filteredProducts = productOptions.filter((product) =>
            product.productName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredProducts.map((product) => ({
                    label: product.productName,
                    value: product.productGuid,
                }))
            );
        }, 1000);
    };

    const loadFlatOptions = (inputValue, callback) => {
        const filteredFlats = flats.filter((flat) =>
            flat.flatName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(
                filteredFlats.map((flat) => ({
                    label: flat.flatName,
                    value: flat.flatGuid,
                }))
            );
        }, 1000);
    };

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), assetGuid: '', quantity: '', assetDetails: null }]);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setApiError({ flatGuid: '', assetList: [] });

        const formData = {
            flatGuid: selectedFlat,
            assetList: rows.map((row) => ({
                assetGuid: row.assetGuid,
                quantity: row.quantity !== '' ? row.quantity : 1,
            })),
        };


        const validationErrors = validateAssignAssetsForm(formData);

        if (Object.keys(validationErrors).length > 0) {
            setApiError(validationErrors);
            return;
        }

        try {
            setIsButtonDisabled(true);

            await addAssignAssets(formData);

            toast.success('Asset assigned successfully!');

            const updatedData = await assignAssetsDisplay(selectedFlat);
            setAssetData(updatedData.data.result);

            setSelectedProduct('');
            setRows([{ id: Date.now(), assetGuid: '', quantity: '', assetDetails: null }]);

        } catch (error) {
            console.error('Error assigning assets:', error);
            setApiError({ flatGuid: 'Error assigning asset', assetList: [] });
            toast.error('Error occurred while assigning asset!');
        } finally {
            setIsButtonDisabled(false);
        }
    }, [rows, selectedFlat]);


    return (
        <>
            <style>
                {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(10){ display:none;} 
                `}
            </style>
            <ComponentHeader title="Assign Assets" />

            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <h5 className="mb-sm-1 mt-sm-1">Assign Assets</h5>
                        </div>
                        {pageAccessDetails.addAccess ? (
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="propertyName" className="form-label">Property Name</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadPropertyOptions}
                                                    defaultOptions={properties.map((property) => ({
                                                        label: property.propertyName,
                                                        value: property.propertyGuid,
                                                    }))}
                                                    onChange={(selectedOption) => {
                                                        setSelectedProperty(selectedOption?.value || '');
                                                    }}
                                                    value={selectedProperty
                                                        ? { label: properties.find((property) => property.propertyGuid === selectedProperty)?.propertyName, value: selectedProperty }
                                                        : null}
                                                    isClearable
                                                    placeholder="Select Property"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="flatGuid" className="form-label">Flat Name <span className='required-field'>*</span></label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadFlatOptions}
                                                    defaultOptions={flats.map((flat) => ({
                                                        label: `${flat.flatName} (${flat.flatNo})`,
                                                        value: flat.flatGuid,
                                                    }))}
                                                    onChange={(selectedOption) => {
                                                        setSelectedFlat(selectedOption?.value || '');
                                                    }}
                                                    value={{
                                                        label: flats.find((flat) => flat.flatGuid === selectedFlat)?.flatName,
                                                        value: selectedFlat,
                                                    }}
                                                    isClearable
                                                    placeholder="Select Flat"
                                                    className={apiError.flatGuid ? 'is-invalid' : ''}
                                                />
                                                {apiError.flatGuid && <div style={{ color: '#dc3545' }}>{apiError.flatGuid}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 bg-soft-info p-2">
                                        {rows.map((row, index) => (
                                            <div key={row.id} className="row">
                                                <div className="col-lg-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="productName" className="form-label">Product Name</label>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={loadProductOptions}
                                                            defaultOptions={productOptions.map((product) => ({
                                                                label: product.productName,
                                                                value: product.id,
                                                            }))}
                                                            onChange={(selectedOption) => {
                                                                setSelectedProduct(selectedOption ? selectedOption.label : '');
                                                            }}
                                                            isClearable
                                                            placeholder="Select Product"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="asset" className="form-label">Asset Name <span className='required-field'>*</span></label>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={loadOptions}
                                                            defaultOptions={assetOptions.map((asset) => ({
                                                                label: `${asset.brandName} - ${asset.remark} (${asset.typeOfAsset})`,
                                                                value: asset.assetGuid,
                                                            }))}
                                                            onChange={(selectedOption) => handleAssetChange(selectedOption, index)}
                                                            value={row.assetGuid ? { label: `${row.assetDetails?.brandName} - ${row.assetDetails?.remark}`, value: row.assetGuid } : null}
                                                            placeholder="Select Asset"
                                                            isClearable
                                                            className={apiError.assetList?.[index]?.assetGuid ? 'is-invalid' : ''}
                                                        />
                                                        {apiError.assetList?.[index]?.assetGuid && (
                                                            <div style={{ color: '#dc3545' }}>{apiError.assetList[index].assetGuid}</div>
                                                        )}
                                                        {row.assetDetails && (
                                                            <p className="mb-0 mt-1">
                                                                <label>Sl No: </label>
                                                                <span>{row.assetDetails.serielNo}</span>
                                                                <span className='ms-2'>
                                                                    <label>Vendor: </label>
                                                                    <span>{row.assetDetails.vendor}</span>
                                                                </span>
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="quantity" className="form-label">Quantity</label>
                                                        <input
                                                            type="number"
                                                            name="quantity"
                                                            value={row.assetDetails?.typeOfAsset === 'Tagged' ? 1 : row.quantity}
                                                            onChange={(e) => {
                                                                if (row.assetDetails?.typeOfAsset === 'Non-Tagged') {
                                                                    const updatedRows = [...rows];
                                                                    const enteredQuantity = e.target.value === '' ? '' : Number(e.target.value);
                                                            
                                                                    if (enteredQuantity === '' || enteredQuantity <= row.assetDetails?.quantity) {
                                                                        updatedRows[index].quantity = enteredQuantity;
                                                                        setRows(updatedRows);
                                                                        setQuantityMessage((prevMessages) => {
                                                                            const updatedMessages = [...prevMessages];
                                                                            updatedMessages[index] = '';
                                                                            return updatedMessages;
                                                                        });
                                                                    } else {
                                                                        setQuantityMessage((prevMessages) => {
                                                                            const updatedMessages = [...prevMessages];
                                                                            updatedMessages[index] = `The quantity must be between 0 and ${row.assetDetails?.quantity}`;
                                                                            return updatedMessages;
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                            className={`form-control ${apiError.assetList?.[index]?.quantity ? 'is-invalid' : ''}`}
                                                            disabled={row.assetDetails?.typeOfAsset === 'Tagged'}
                                                            max={row.assetDetails?.quantity}
                                                        />
                                                        {apiError.assetList?.[index]?.quantity && (
                                                            <div style={{ color: '#dc3545' }}>{apiError.assetList[index].quantity}</div>
                                                        )}
                                                        {quantityMessage[index] && (
                                                            <div style={{ color: '#dc3545' }}>{quantityMessage[index]}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 align-items-center pt-4">
                                                    {index === rows.length - 1 && (
                                                        <>
                                                            <span className="show-hide-sub-row" onClick={handleAddRow}>
                                                                <i className="ri-add-box-fill" style={{ color: '#45cb85' }}></i>
                                                            </span>
                                                            {rows.length > 1 && (
                                                                <span
                                                                    className="show-hide-sub-row"
                                                                    onClick={() => handleRemoveRow(index)}
                                                                >
                                                                    <i className="ri-checkbox-indeterminate-fill" style={{ color: '#dc3545' }}></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="pt-4">
                                            <button type="submit" className="btn btn-secondary pt-1 pb-1 p-3" disabled={isButtonDisabled}>
                                                {isButtonDisabled ? 'Assigning...' : 'Assign'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Assigned Assets</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                {loadingAssets ? (
                                    <Loading />
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table align-middle table-bordered">
                                            <TableHeader columns={['#', 'Property', 'Product Name', 'Specifications', 'Quantity', 'Assigned On', 'Added By', 'Status', 'Action']} />
                                            <tbody className="manage-page-group-table-values">
                                                {assetData.length === 0 ? (
                                                    <TableDataStatusError colspan="9" />
                                                ) : (
                                                    assetData.map((asset, index) => (
                                                        <TablesRow
                                                            key={asset.assetId}
                                                            rowData={{
                                                                id: index + 1,
                                                                property: (
                                                                    <>
                                                                        <span><Link to={`/property/detail/${asset.propertyGuid}`}>{asset.propertyName}</Link></span>
                                                                        <br />
                                                                        <span>
                                                                            <label>Flat: </label>
                                                                            {asset.flatName}({asset.flatNo})

                                                                        </span>
                                                                    </>
                                                                ),
                                                                productName: asset.productName,
                                                                brandName: asset.brandName,
                                                                quantity: asset.quantity,
                                                                assignedOn: new Date(asset.addedOn).toLocaleDateString(),
                                                                addedBy: asset.addedBy,
                                                                status: (
                                                                    asset.status === 'Issued' ?
                                                                        <span className="badge rounded-pill badge-soft-primary">{asset.status}</span> :
                                                                        asset.status === 'Active' ?
                                                                            <span className="badge rounded-pill badge-soft-success">{asset.status}</span> :
                                                                            asset.status === 'Moved' ?
                                                                                <span className="badge rounded-pill badge-soft-danger">{asset.status}</span> :
                                                                                asset.status
                                                                ),
                                                                unassign: (
                                                                    <>
                                                                        <BootstrapTooltip title="Cancel Assignment">
                                                                        {pageAccessDetails.editAccess ? (
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "17px",
                                                                                    marginRight: "8px",
                                                                                    color: '#1f156d'
                                                                                }}
                                                                                onClick={() => asset.status !== "Cancelled" && handleCancelAssign(asset.flatGuid, asset.assetGuid)}

                                                                            >
                                                                                <i className="ri-close-circle-fill" />
                                                                            </span>
                                                                            ) : (
                                                                                <span style={{color:'#dc3545'}}>
                                                                                Forbidden
                                                                            </span>
                                                                            )}
                                                                        </BootstrapTooltip>
                                                                    </>
                                                                ),
                                                            }}
                                                            columns={['id', 'property', 'productName', 'brandName', 'quantity', 'assignedOn', 'addedBy', 'status', 'unassign']}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
};
