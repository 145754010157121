import React, { useState, useEffect, useCallback } from "react";
import {
  addPropertyNearby, updatePropertyNearby,
  fetchPropertyNearby,
} from "../../../services/PropertyNearbyService";
import { validatePropertyNearby } from "../../../utils/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from "../../../utils/errorHandler";

export const AddPropertyNearby = ({
  editMode = false,
  initialData = {},
  onSuccess,
  setSelectedPageGroup,
  setEditMode,
  propertyDetails,
}) => {
  const [formData, setFormData] = useState({
    propertyGuid: propertyDetails,
    nearByTypes: "",
    nearByName: "",
    distanceInKM: "",
    nearByLink: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchPropertyNearby(initialData.id);
          setFormData({
            nearByTypes: data.nearByTypes || "",
            nearByName: data.nearByName || "",
            distanceInKM: initialData.distanceInKM.toString() || "",
            nearByLink: data.nearByLink || "",
            propertyGuid: propertyDetails,
          });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({ nearByTypes: "", nearByName: "", distanceInKM: "", nearByLink: "", propertyGuid: propertyDetails });
      }
    };

    fetchData();
  }, [editMode, initialData.id, initialData.distanceInKM, propertyDetails]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = (name === "nearByLink" && value.trim() === "") ? "#" : value;

    setFormData((prevData) => ({ ...prevData, [name]: newValue }));

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };



  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const { valid, errors: validationErrors } = validatePropertyNearby({
        ...formData,
        distanceInKM: formData.distanceInKM.toString(),
      });

      setErrors(validationErrors);

      if (valid) {
        setApiError("");
        try {
          if (editMode) {
            setIsButtonDisabled(true);
            await updatePropertyNearby({ ...formData, id: initialData.id });
            toast.success("Property updated successfully!");
            setEditMode(false);
            setIsButtonDisabled(false);
          } else {
            setIsButtonDisabled(true);
            await addPropertyNearby(formData);
            toast.success("Property added successfully!");
            setIsButtonDisabled(false);
          }

          setFormData({ nearByTypes: "", nearByName: "", distanceInKM: "", nearByLink: "", propertyGuid: propertyDetails });
          if (onSuccess) {
            onSuccess();
          }
        } catch (error) {
          handleErrors(error);
          setIsButtonDisabled(false);
        }
      }
    },
    [formData, editMode, initialData, onSuccess,setEditMode, propertyDetails]
  );



  const handleAddNewClick = () => {
    setFormData({ nearByTypes: "", nearByName: "", distanceInKM: "", nearByLink: "", propertyGuid: propertyDetails });
    setErrors({});
    setApiError("");
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>


      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? "Update NearBy Property" : "Add NearBy Property"}</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="nearByTypes" className="form-label">Near By Types <span className='required-field'>*</span></label>
                      <select
                        name="nearByTypes"
                        value={formData.nearByTypes}
                        onChange={handleInputChange}
                        className={`form-select ${errors.nearByTypes ? "is-invalid" : ""}`}
                      >
                        <option value="">Select Near By Type</option>
                        <option value="Business Centres">Business Centres</option>
                        <option value="Shopping">Shopping</option>
                        <option value="Temples">Temples</option>
                        <option value="Hospitals">Hospitals</option>
                        <option value="Eatouts">Eatouts</option>
                        <option value="Nearest Properties">Nearest Properties</option>
                      </select>
                      {errors.nearByTypes && <div className="invalid-feedback">{errors.nearByTypes}</div>}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="nearByName" className="form-label">Near By Name <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="nearByName"
                        value={formData.nearByName}
                        onChange={handleInputChange}
                        placeholder="Enter Near By Name"
                        className={`form-control ${errors.nearByName ? "is-invalid" : ""}`}
                      />
                      {errors.nearByName && <div className="invalid-feedback">{errors.nearByName}</div>}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="distanceInKM" className="form-label">Distance In KM <span className='required-field'>*</span></label>
                      <input
                        type="number"
                        name="distanceInKM"
                        value={formData.distanceInKM}
                        placeholder="Enter Distance In Km"
                        onChange={handleInputChange}
                        className={`form-control ${errors.distanceInKM ? "is-invalid" : ""}`}
                      />
                      {errors.distanceInKM && <div className="invalid-feedback">{errors.distanceInKM}</div>}
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="mb-3">
                      <label htmlFor="nearByLink" className="form-label">Near By Link (optional)</label>
                      <input
                        type="text"
                        name="nearByLink"
                        value={formData.nearByLink}
                        placeholder="Enter Near By Link"
                        onChange={handleInputChange}
                        className={`form-control ${errors.nearByLink ? "is-invalid" : ""}`}
                      />
                      {errors.nearByLink && <div className="invalid-feedback">{errors.nearByLink}</div>}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button type="submit" className="btn btn-secondary pt-1 pb-1 p-3" disabled={isButtonDisabled}>
                        {isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}
                      </button>
                      {editMode && (
                        <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1 pt-1 pb-1 p-3">
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
