import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FileUploadSection } from './GalleryModalComponents/FileUploadSection';
import { ManageFlatGallery } from './GalleryModalComponents/ManageFlatGallery';
import { GalleryUpdate } from './GalleryModalComponents/GalleryUpdate';

const GalleryModal = ({ show, handleClose, flatId, flatName }) => {
    const [mediaType, setMediaType] = useState('');
    const [imageFiles, setImageFiles] = useState([]);
    const [fileName, setFileName] = useState('');
    const [fileAlt, setFileAlt] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [editingItem, setEditingItem] = useState(null);
    
    const handleEdit = (item) => {
        setEditingItem(item);
    };

    const handleSwitchToUpload = () => {
        setEditingItem(null); 
    };
    const [key, setKey] = useState(0);

    const handleReRenderButtonClick = () => {
      setKey(prevKey => prevKey + 1);
    };
    
  
    return (
        <Modal show={show} onHide={handleClose} centered className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Manage Gallery for Flat Name: {flatName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {editingItem ? (
                    <GalleryUpdate 
                        item={editingItem} 
                        onClose={() => setEditingItem(null)} 
                        onSwitchToUpload={handleSwitchToUpload}
                        onRefresh={handleReRenderButtonClick}
                    />
                ) : (
                    <FileUploadSection
                        mediaType={mediaType}
                        setMediaType={setMediaType}
                        fileName={fileName}
                        setFileName={setFileName}
                        fileAlt={fileAlt}
                        setFileAlt={setFileAlt}
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles}
                        videoUrl={videoUrl}
                        setVideoUrl={setVideoUrl}
                        flatId={flatId}
                        onClick={handleReRenderButtonClick}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <ManageFlatGallery 
                    flatId={flatId}
                    onEdit={handleEdit}
                    key={key}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default GalleryModal;