import React, { useState, useEffect } from 'react';
import { fetchGalleryById, updateGallery } from '../../../../services/FlatGalleryService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrors } from '../../../../utils/errorHandler';

export const GalleryUpdate = ({ item, onClose, onSwitchToUpload, onRefresh }) => {
    const [mediaType, setMediaType] = useState('Image');
    const [fileName, setFileName] = useState('');
    const [fileAlt, setFileAlt] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [filePreview, setFilePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryItem = await fetchGalleryById(item.id);
                setMediaType(galleryItem.fileType || 'Image');
                setFileName(galleryItem.fileNm || '');
                setFileAlt(galleryItem.fileAlt || '');
                setFileUrl(galleryItem.fileLink || '');
                setFilePreview(galleryItem.fileLink || null);
            } catch (error) {
                handleErrors(error);
            }
        };

        if (item.id) {
            fetchData();
        }
    }, [item.id]);

    const handleMediaTypeChange = (event) => {
        const type = event.target.value;
        setMediaType(type);
        setFileUrl('');
        setFilePreview(null);
        setImageFile(null);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setFilePreview(reader.result);
            reader.readAsDataURL(file);
            setImageFile(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsButtonDisabled(true);
            const formData = new FormData();
            formData.append('Id', item.id);
            formData.append('FileType', mediaType);
            formData.append('FileNm', fileName);
            formData.append('FileAlt', fileAlt);

            if (mediaType === 'Image' && imageFile) {
                formData.append('ImageFile', imageFile);
            } else if (mediaType === 'Video') {
                formData.append('FileLink', fileUrl);
            }

            await updateGallery(formData);
            setIsButtonDisabled(false);

            toast.success("Gallery item updated successfully!");
            onRefresh();
        } catch (error) {
           handleErrors(error);
           setIsButtonDisabled(false);
        }
    };

    return (
        <div>
            <h5 className="mb-sm-0 ms-3 gallery-modal-heading"><b>Update gallery</b></h5>
            <form className='modal-body'>
                <div className="row mb-3">
                    <div className="col-lg-7">
                        <label htmlFor="mediaType" className="form-label">Select Media Type</label>
                        <select id="mediaType" className="form-select" value={mediaType} onChange={handleMediaTypeChange}>
                            <option value="Image">Image</option>
                            <option value="Video">Video</option>
                        </select>
                    </div>

                    <div className="col-lg-6 pt-3">
                        <label htmlFor="fileName" className="form-label">File Name</label>
                        <input
                            type="text"
                            id="fileName"
                            value={fileName}
                            className="form-control"
                            onChange={(e) => setFileName(e.target.value)}
                            placeholder="Enter file name"
                        />
                    </div>

                    <div className="col-lg-6 pt-3">
                        <label htmlFor="fileAlt" className="form-label">File Alt</label>
                        <input
                            type="text"
                            id="fileAlt"
                            value={fileAlt}
                            className="form-control"
                            onChange={(e) => setFileAlt(e.target.value)}
                            placeholder="Enter file alt text"
                        />
                    </div>

                    {mediaType === 'Image' && (
                        <div className='pt-3'>
                            <label htmlFor="fileUpload" className="form-label">Choose Image</label>
                            <input
                                type="file"
                                id="fileUpload"
                                className="form-control"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                            {filePreview && (
                                <div className='image-container'>
                                    <img src={filePreview} alt={fileAlt} className="uploaded-image" />
                                </div>
                            )}
                        </div>
                    )}

                    {mediaType === 'Video' && (
                        <div className='pt-3'>
                            <label htmlFor="fileUrl">Video URL</label>
                            <input
                                type="text"
                                id="fileUrl"
                                value={fileUrl}
                                className="form-control"
                                onChange={(e) => setFileUrl(e.target.value)}
                                placeholder="Enter video URL"
                            />
                        </div>
                    )}
                    <div className="col-lg-3">
                        <button className="btn btn-secondary mt-2" onClick={handleSubmit}  disabled={isButtonDisabled}>
                        {isButtonDisabled ? 'Updating...' : 'Update'}
                        </button>
                        <button onClick={onSwitchToUpload} className="btn btn-danger mt-2 ms-2">Cancel</button>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};
