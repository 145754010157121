import React, { useEffect, useState } from "react";
import TableHeader from "../../../Common/TableComponent/TableHeader";
import TablesRow from "../../../Common/TableComponent/TablesRow";
import {
  fetchFlatGallery,
  deleteFlatGalleryItem,
  deleteFlatGalleryItems,
} from "../../../../services/FlatGalleryService";
import allImages from "../../../../assets/images-import";
import Swal from "sweetalert2";
import { confirmDelete } from "../../../Common/OtherElements/confirmDeleteClone";
import { Loading } from "../../../Common/OtherElements/Loading";
import { TableDataStatusError } from "../../../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../../../utils/errorHandler";
import { usePageLevelAccess } from "../../../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";

export const ManageFlatGallery = ({ flatId, onEdit }) => {
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const PageLevelAccessurl = "/property/detail/:id";
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.viewAccess) {
        navigate("/400-error-page");
      } else {
        setPageAccessDetails(pageAccessData);
      }
    } else {
      console.log("No page access details found");
    }
  }, [pageAccessData, navigate]);

  useEffect(() => {
    const getFlatsGallery = async () => {
      try {
        const data = await fetchFlatGallery(flatId);
        setCurrentData(data);
      } catch (err) {
        handleErrors(err);
      } finally {
        setLoading(false);
      }
    };

    getFlatsGallery();
  }, [flatId]);

  const handleDelete = async (galleryId) => {
    const confirmed = await confirmDelete("Gallery item");
    if (confirmed) {
      try {
        await deleteFlatGalleryItem(galleryId);
        setCurrentData((prev) => prev.filter((item) => item.id !== galleryId));
        setSelectedRows((prev) => prev.filter((id) => id !== galleryId));
        Swal.fire(
          "Deleted!",
          "The gallery item has been deleted successfully.",
          "success"
        );
      } catch (error) {
        handleErrors(error);
      }
    }
  };


const handleDeleteAll = async () => {
  const confirmed = await confirmDelete("selected gallery items");
  if (confirmed) {
    try {
      await deleteFlatGalleryItems(selectedRows); 
      setCurrentData((prev) =>
        prev.filter((item) => !selectedRows.includes(item.id))
      );
      setSelectedRows([]);
      Swal.fire("Deleted!", "All selected items have been deleted.", "success");
    } catch (error) {
      handleErrors(error);
    }
  }
};

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedRows(currentData.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id, checked) => {
    setSelectedRows((prev) =>
      checked ? [...prev, id] : prev.filter((selectedId) => selectedId !== id)
    );
  };

  return (
    <>
      {pageAccessDetails.viewAccess ? (
        <div className="card-body manage-page-group-card-body table-responsive justify-content-end">
          {selectedRows.length > 0 && (
            <button
              className="btn btn-danger delete-all-button"
              onClick={handleDeleteAll}
            >
              Delete All
            </button>
          )}
          {loading ? (
            <Loading />
          ) : (
            <table className="table align-middle table-bordered flat-detail-table">
              <TableHeader
                columns={[
                  "Select",
                  "File Type",
                  "File Link",
                  "File Alt",
                  "File Name",
                  "Added On",
                  "Action",
                ]}
                onSelectAll={handleSelectAll}
                allSelected={
                  selectedRows.length === currentData.length &&
                  currentData.length > 0
                }
              />
              <tbody>
                {currentData.length === 0 ? (
                  <TableDataStatusError colspan="7" />
                ) : (
                  currentData.map((item) => (
                    <TablesRow
                      key={item.id}
                      rowData={{
                        select: (
                          <input
                            type="checkbox"
                            style={{ width: "50px", height: "20px" }}
                            checked={selectedRows.includes(item.id)}
                            onChange={(e) =>
                              handleRowSelect(item.id, e.target.checked)
                            }
                          />
                        ),
                        filetype: item.fileType,
                        filelink:
                          item.fileType === "Image" ? (
                            <img
                              src={item.fileLink}
                              alt={item.fileAlt}
                              width="50px"
                              height="50px"
                            />
                          ) : (
                            <a
                              href={item.fileLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={allImages.playButton}
                                alt="play button"
                                width="40px"
                                height="40px"
                              />
                            </a>
                          ),
                        filealt: item.fileAlt,
                        filename: item.fileNm,
                        addedon: new Date(item.addedOn).toLocaleDateString(),
                      }}
                      columns={[
                        "select",
                        "filetype",
                        "filelink",
                        "filealt",
                        "filename",
                        "addedon",
                      ]}
                      hideIcons={false}
                      onEdit={() => onEdit(item)}
                      onDelete={() => handleDelete(item.id)}
                      pageLevelAccessData={pageAccessDetails}
                    />
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
