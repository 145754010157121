import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const addCaretaker = async (formData) => {
    const payload = formData.formData;
    const response = await axiosInstance.post('properties/caretaker/assign', payload, { headers });
    return response.data.result;
  }
  
  export const fetchAssignedCaretakers = async () => {
    const response = await axiosInstance.get('properties/caretaker/assign', { headers });
    return response.data;
  };
  
  export const deleteAssignedCaretakers = async (assignedId) => {
    await axiosInstance.delete(`properties/caretaker/assign/${assignedId}`, { headers });
  };
  
  export const updateAssignedCaretaker = async (payload) => {
    const response = await axiosInstance.put('properties/caretaker/assign', payload, { headers });
    return response.data;
  };
  
  export const fetchAssignedCaretakerById = async (id) => {
    const response = await axiosInstance.get(`properties/caretaker/assign/${id}`, { headers });
    return response.data;
  };

export const fetchCareTakersList = async () => {
    const response = await axiosInstance.get('auth/caretakers', { headers });
    return response.data.result;
  };