import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { updateKycStatus } from '../../services/onboardingCustomerService';

export const KycDetails = ({ customerData }) => {
    const Kycdetailsfetched = customerData?.kyc || {};

    const [showModal, setShowModal] = useState(false);
    const [fileSrc, setFileSrc] = useState('');
    const [fileTitle, setFileTitle] = useState('');
    const [docVerification, setDocVerification] = useState(null);

    useEffect(() => {
        setDocVerification(Kycdetailsfetched.docVerifyStatus || null);
    }, [customerData,Kycdetailsfetched.docVerifyStatus]);


    const handleOpenModal = (src, title) => {
        setFileSrc(src);
        setFileTitle(title);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFileSrc('');
        setFileTitle('');
    };

    const handleverification = async () => {
        const cust_id = customerData.customerGuid;
        const verificationstatus = 'Verified';

        Swal.fire({
            title: 'Are you sure?',
            text: "You are about to verify the customer's KYC status.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, verify it!',
            cancelButtonText: 'Cancel'
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await updateKycStatus(cust_id, verificationstatus);
                Swal.fire(
                  'Verified!',
                  'The customer has been successfully verified.',
                  'success'
                );
                setDocVerification('Verified')
              } catch (error) {
                Swal.fire(
                  'Error!',
                  'No Data found for verification',
                  'error'
                );
                console.error('Verification failed:', error);
              }
            }
          });
    }
    const customerDetailsFields = [
        {
            label: 'Aadhar Number',
            value: (
                <span>

                    {Kycdetailsfetched.aadharNo}
                    {Kycdetailsfetched.aadharNo ? (
                        <span className='verified-details'>
                            {Kycdetailsfetched.aadharNoStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            )
        },
        {
            label: 'Pan Number',
            value: (
                <span>

                    {Kycdetailsfetched.pan}
                    {Kycdetailsfetched.pan ? (
                        <span className='verified-details'>
                            {Kycdetailsfetched.panStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}
                </span>
            )
        },
        {
            label: 'Driving License', value: (
                <span>
                    {Kycdetailsfetched.drivingLicense}
                    {Kycdetailsfetched.drivingLicense ? (
                        <span className='verified-details'>
                            {Kycdetailsfetched.drivingLicenseStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            ) || '-'
        },
        {
            label: 'Passport Number', value: (
                <span>
                    {Kycdetailsfetched.passportNo}
                    {Kycdetailsfetched.passportNo ? (
                        <span className='verified-details'>
                            {Kycdetailsfetched.passportNoStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            ) || '-'
        },
        { label: 'Visa validity', value: Kycdetailsfetched.visaValidity || '-' },
        {
            label: 'Document Verification Status', value: (
                <span>
                    {docVerification}
                    {docVerification ? (
                        <span className='verified-details'>
                            {docVerification === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            ) || '-'
        },

    ];
console.log('the data within document verification is',docVerification);
    return (
      <div className="row justify-content-center">
        <div className="col-xxl-12">
          <div className="card-header">
            <h5 className="mb-sm-2 mt-sm-2">KYC Details</h5>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body p-4">
                <div className="row g-3">
                  {customerDetailsFields.map((field, index) => (
                    <div className="col-lg-3 col-6 mb-3" key={index}>
                      <p className="mb-2 fw-semibold">{field.label}</p>
                      <h5 className="fs-14 mb-0">
                        <span id="invoice-no" className="sub-title-detail">
                          {field.value}
                        </span>
                      </h5>
                    </div>
                  ))}
                </div>
                <div className="row g-3 pt-3">
                  <div className="col-lg-3 col-6 mb-3">
                    <p className="mb-2 fw-semibold">Aadhar File Front</p>
                    <h5 className="fs-14 mb-0">
                      <span>
                        {Kycdetailsfetched.aadharFile ? (
                          <img
                            src={`${Kycdetailsfetched.aadharFile}`}
                            alt=""
                            className="rounded avatar-md shadow"
                            onClick={() =>
                              handleOpenModal(
                                Kycdetailsfetched.aadharFile,
                                "Aadhar File Front"
                              )
                            }
                          />
                        ) : (
                          "-"
                        )}
                      </span>
                    </h5>
                  </div>
                  <div className="col-lg-3 col-6 mb-3">
                    <p className="mb-2 fw-semibold">Aadhar File Back</p>
                    <h5 className="fs-14 mb-0">
                      <span>
                        {Kycdetailsfetched.aadharFileBack ? (
                          <img
                            src={`${Kycdetailsfetched.aadharFileBack}`}
                            alt=""
                            className="rounded avatar-md shadow"
                            onClick={() =>
                              handleOpenModal(
                                Kycdetailsfetched.aadharFileBack,
                                "Aadhar File Back"
                              )
                            }
                          />
                        ) : (
                          "-"
                        )}
                      </span>
                    </h5>
                  </div>
                  <div className="col-lg-3 col-6 mb-3">
                    <p className="mb-2 fw-semibold">Pan File</p>
                    <h5 className="fs-14 mb-0">
                      <span>
                        {Kycdetailsfetched.panFile ? (
                          <img
                            src={`${Kycdetailsfetched.panFile}`}
                            alt=""
                            className="rounded avatar-md shadow"
                            onClick={() =>
                              handleOpenModal(
                                Kycdetailsfetched.panFile,
                                "PAN File"
                              )
                            }
                          />
                        ) : (
                          "-"
                        )}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {docVerification === "Verified" || docVerification == null ? (
            ""
          ) : (
            <button
              className="btn btn-danger m-3"
              onClick={handleverification}
              style={{ cursor: "pointer" }}
            >
              Verify Documents
            </button>
          )}
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          className="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{fileTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {fileSrc.endsWith(".pdf") ? (
              <iframe
                src={fileSrc}
                style={{ width: "100%", height: "400px", border: "none" }}
                title="Document Viewer"
              />
            ) : (
              <img
                src={fileSrc}
                alt={fileTitle}
                style={{maxWidth:"100%"}}
              />
            )}
          </Modal.Body>
        </Modal>
        ;
      </div>
    );
};

