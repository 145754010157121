import React from 'react'
import { ToastContainer } from 'react-toastify';
import { ManagePropertyListing } from '../components/Reports/ManagePropertyListing';
export const ManagePropertyListingReport = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
          <ManagePropertyListing />
        </div>
      </div>
    </div>
  )
}
