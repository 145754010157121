import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

  export const addPropertyExpense = async (ownerData) => {
      const response = await axiosInstance.post('expense/property', ownerData, {  headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${Cookies.get('accessToken')}`,
      }, });
      return response.data;
  };

  export const paginationPropertyExpenses = async (formData) => {
    const response = await axiosInstance.post('expense/property/filter', formData, {headers});
    return response;
  } 

  export const deletePropertyExpense = async (supportid) => {
    return await axiosInstance.delete(`expense/${supportid}`, { headers });
  };

  export const updatePropertyExpense = async (ownerData) => {
    const response = await axiosInstance.put('expense/property', ownerData, {  headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('accessToken')}`,
    }, });
    return response.data;
};

export const fetchPropertyExpenseById = async (expenseid) => {
  const response = await axiosInstance.get(`expense/${expenseid}`, { headers });
  return response.data.result;
};