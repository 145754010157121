import React from 'react';
import { AddPropertyOwner } from '../components/Properties/PropertyOwner/AddPropertyOwner';
import { ToastContainer } from 'react-toastify';
export const PropertyOwner = () => {

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
                <AddPropertyOwner /> 
                </div>
            </div>
        </div>
    );
};
