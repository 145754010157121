import React, { useState, useEffect, useCallback } from 'react';
import { createBHKType, updateBHKType, fetchBHKTypeById } from '../../../services/bhkTypeService';
import { validateBHKType } from '../../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../../utils/errorHandler';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';

export const AddBHKType = ({ editMode = false, initialData = {}, onSuccess, setSelectedPageGroup, setEditMode }) => {
  const [formData, setFormData] = useState({ bhkType: '' });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (editMode && initialData?.id) {
      const fetchData = async () => {
        try {
          const data = await fetchBHKTypeById(initialData.id);
          setFormData({ bhkType: data.bhkType || '' });
        } catch(error) {
          handleErrors(error);
        }
      };
      fetchData();
    } else {
      resetForm();
    }
  }, [editMode, initialData]);

  const resetForm = () => {
    setFormData({ bhkType: '' });
    setErrors({});
    setApiError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { valid, errors: validationErrors } = validateBHKType(formData);
      setErrors(validationErrors);

      if (valid) {
        try {
          setApiError('');
          if (editMode) {
            setIsButtonDisabled(true);
            await updateBHKType({ ...formData, id: initialData.id });
            toast.success('BHK Type updated successfully!');
            setIsButtonDisabled(false);
            setEditMode(false);
          } else {
            setIsButtonDisabled(true);
            await createBHKType(formData);
            toast.success('BHK Type added successfully!');
            setIsButtonDisabled(false);
          }
          resetForm();
          onSuccess?.();
        } catch (error) {
          handleErrors(error);
          setIsButtonDisabled(false);
        }
      }
    },
    [formData, editMode, initialData, onSuccess,setEditMode]
  );

  const handleAddNewClick = () => {
    resetForm();
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>
      <ComponentHeader title='BHK Type'/>

      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? 'Update BHK Type' : 'Add BHK Type'}</h5>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="bhkType" className="form-label">BHK Type <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="bhkType"
                        value={formData.bhkType}
                        placeholder='Enter BHK Type Name'
                        onChange={handleInputChange}
                        className={`form-control ${errors.bhkType ? 'is-invalid' : ''}`}
                      />
                      {errors.bhkType && <div className="invalid-feedback">{errors.bhkType}</div>}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                    {isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}
                    </button>
                    {editMode && (
                      <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1">
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
