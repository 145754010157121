import React, { useCallback, useEffect, useState, useRef } from "react";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import { paginationOthersReport } from "../../services/ReportsService";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from "../Common/TableComponent/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import allImages from "../../assets/images-import";
import * as XLSX from "xlsx";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";

export const ManageOtherReports = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const [entriesPerPage, setEntriesPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [paymentStatus, setpaymentStatus] = useState("");
  const [fMonth, setFMonth] = useState(currentMonth);
  const [fYear, setFYear] = useState(currentYear);
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const [downloadBtnDisable, setDownloadBtnDisable] = useState(false);
  const PageLevelAccessurl = "reports/other-report";
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);
  const navigate = useNavigate();
  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.viewAccess) {
        navigate("/404-error-page");
      } else {
        setPageAccessDetails(pageAccessData);
      }
    } else {
      console.log("No page access details found");
    }
  }, [pageAccessData, navigate]);

  const searchInputRef = useRef(null);

  const handleDateChange = ([selectedDate]) => {
    if (selectedDate) {
      const selectedMonth = selectedDate.getMonth() + 1;
      const selectedYear = selectedDate.getFullYear();
      setFMonth(selectedMonth);
      setFYear(selectedYear);
    } else {
      setFMonth("");
      setFYear("");
    }
  };

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    const formData = {
      pageSize: entriesPerPage,
      pageNo: currentPage,
      fMonth,
      fYear,
      sParam: searchedTerm,
      paymentStatus: paymentStatus,
    };
    try {
      const response = await paginationOthersReport(formData);
      const { data } = response;
      setProperties(data.result);
      setTotalCount(data.result[0]?.totalCount || 0);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [entriesPerPage, currentPage, fMonth, fYear, searchedTerm, paymentStatus]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  const handleDownloadReport = async () => {
    const formData = {
      pageSize: totalCount,
      pageNo: 1,
      fMonth,
      fYear,
      sParam: searchedTerm,
      paymentStatus: paymentStatus,
    };

    try {
      setDownloadBtnDisable(true);
      const response = await paginationOthersReport(formData);
      const { data } = response;
      const rentReports = data.result;
      setDownloadBtnDisable(false);

      const excelData = rentReports.map((item, index) => ({
        "#": index + 1,
        "Property Name": item.propertyName,
        "Property Location": item.propertyLocation,
        "Property Type": item.propertyType,
        "Flat Name": item.flatName,
        "Flat Number": item.flatNo,
        "Flat Status": item.flatStatus,
        "User Name": item.userName,
                "Email": item.emailAddress,
                "Contact": item.contactNo,
        "Item Name": item.itemName,
        "Total Amount": item.totalAmount,
        "Move In Date": formatDate(item.moveInDate),
        "Move Out Date": formatDate(item.moveOutDate),
        "Paid On": formatDate(item.paidOn),
        "Payment Status": item.paymentStatus,
        "Payment id": item.paymentId,
                "Order Id": item.orderId,
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Others Report");

      XLSX.writeFile(workbook, "Others_Reports.xlsx");
    } catch (error) {
      handleErrors(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearchClick = () => {
    setSearchedTerm(searchTerm);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const totalPages = Math.ceil(totalCount / entriesPerPage);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      <style>
        {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(11){ display:none;} 
                `}
      </style>
      {pageAccessDetails.viewAccess ? (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header responsive-filter-type justify-content-between">
                <h5 className="mb-sm-2 mt-sm-2">Other Report</h5>
                {pageAccessDetails.downloadAccess ? (
                  <button
                    className="btn btn-download"
                    onClick={handleDownloadReport}
                    disabled={downloadBtnDisable}
                  >
                    <img
                      src={allImages.Excel}
                      alt="Other report excel icon"
                      width="24px"
                    />{" "}
                    <span>
                      {downloadBtnDisable ? "Exporting..." : "Export To Excel"}
                    </span>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="card-body manage-amenity-master-card-body">
                <div className="responsive-filter-type mb-3">
                  <div className="entries-dropdownx">
                    <label htmlFor="entriesPerPage" className="form-label me-2">
                      Show entries:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPage"
                      value={entriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                  <div className="date-filters">
                    <label htmlFor="fromDate" className="form-label me-2">
                      From Date:
                    </label>
                    <Flatpickr
                      id="fromDate"
                      className="form-control"
                      placeholder="Select Month and Year"
                      value={new Date(fYear, fMonth - 1)}
                      onChange={handleDateChange}
                      options={{
                        disableMobile: true,
                        plugins: [
                          new monthSelectPlugin({
                            shorthand: true,
                            dateFormat: "Y-m",
                            altFormat: "F Y",
                          }),
                        ],
                      }}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="search" className="form-label me-2">
                      Payment Status:
                    </label>
                    <select
                      className="form-select"
                      value={paymentStatus}
                      onChange={(e) => setpaymentStatus(e.target.value)}
                    >
                      <option value="">Select..</option>
                      <option value="Success">Paid</option>
                      <option value="Initiated">UnPaid</option>
                    </select>
                  </div>
                  <div className="search-input">
                    <label htmlFor="search" className="form-label me-2">
                      Search:
                    </label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search...."
                      ref={searchInputRef}
                    />
                  </div>

                  <div className="pt-2">
                    <button
                      className="btn btn-secondary btn-properties-search"
                      onClick={handleSearchClick}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle table-bordered">
                      <TableHeader
                        columns={[
                          "#",
                          "Property Details",
                          "Flat Details",
                          "Customer Details",
                          "Item Name",
                          "Total Amount",
                          "Booking Id",
                          "Duration",
                          "Paid On",
                          "Payment Status",
                        ]}
                      />
                      <tbody className="manage-amenity-master-table-values p-3">
                        {properties.length > 0 ? (
                          properties.map((item, index) => (
                            <TablesRow
                              key={item.id}
                              rowData={{
                                id:
                                  (currentPage - 1) * entriesPerPage +
                                  index +
                                  1,
                                propertydetails: (
                                  <>
                                    <div>
                                      <Link
                                        to={`/property/detail/${item.propertyGuid}`}
                                        style={{ textDecoration: "none" }}
                                      >
                                        {item.propertyName} ({item.propertyType}
                                        )
                                      </Link>
                                    </div>
                                    <div>
                                      <i
                                        className="ri-map-pin-2-fill"
                                        style={{ fontSize: "15px" }}
                                      ></i>
                                      {item.propertyLocation}
                                    </div>
                                  </>
                                ),
                                flatDetails: (
                                  <>
                                    <div>
                                      {item.flatName}{" "}
                                      <span style={{ fontWeight: 500 }}></span>
                                      <span
                                        className={`badge rounded-pill ${
                                          item.flatStatus === "Active"
                                            ? "badge-soft-success"
                                            : "badge-soft-danger"
                                        }`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.flatStatus}
                                      </span>
                                    </div>
                                    <div style={{ fontWeight: 400 }}>
                                      Flat Number: {item.flatNo}
                                    </div>
                                  </>
                                ),
                                CustomerName: (
                                  <>
                                    <div>{item.userName}</div>
                                    <div>
                                      <i
                                        className="ri-mail-fill"
                                        style={{ fontSize: "12px" }}
                                      ></i>{" "}
                                      <span style={{ fontWeight: 600 }}>
                                        {item.emailAddress}
                                      </span>
                                    </div>
                                    <div>
                                      <i
                                        className="ri-phone-fill"
                                        style={{ fontSize: "12px" }}
                                      ></i>{" "}
                                      <span style={{ fontWeight: 600 }}>
                                        {item.contactNo}
                                      </span>
                                    </div>
                                  </>
                                ),
                                utlityType: item.itemName || "-",
                                currentRentAmount: `Rs. ${item.totalAmount}`,
                                orderId: (
                                  <span>
                                    <Link
                                      to={`/orders/detail/${item.orderGuid}`}
                                    >
                                      {item.orderId}
                                    </Link>
                                  </span>
                                ),
                                duration: `${formatDate(
                                  item.moveInDate
                                )} - ${formatDate(item.moveOutDate)}`,
                                paidOn: formatDate(item.paidOn) || "-",
                                paymentstatus: (
                                  <span
                                    className={`badge rounded-pill ${
                                      item.paymentStatus === "Success"
                                        ? "badge-soft-success"
                                        : "badge-soft-danger"
                                    }`}
                                  >
                                    {item.paymentStatus}
                                  </span>
                                ),
                              }}
                              columns={[
                                "id",
                                "propertydetails",
                                "flatDetails",
                                "CustomerName",
                                "utlityType",
                                "currentRentAmount",
                                "orderId",
                                "duration",
                                "paidOn",
                                "paymentstatus",
                              ]}
                              hideIcons={false}
                              showIcons={false}
                              pageLevelAccessData={pageAccessDetails}
                            />
                          ))
                        ) : (
                          <TableDataStatusError colspan="10" />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalEntries={totalCount}
                  entriesPerPage={entriesPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
