import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersonalDetails } from '../components/OnboardCustomers/PersonalDetails';
export const OnboardingCustomers = () => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}/>
                <PersonalDetails/>
                </div>
            </div>
            
        </div>
        
    )
}
