import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

  export const addPropertyAudit = async (ownerData) => {
      const response = await axiosInstance.post('audit/property', ownerData, {  headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${Cookies.get('accessToken')}`,
      }, });
      return response.data;
  };

  export const paginationPropertyAudit = async (formData) => {
    const response = await axiosInstance.post('audit/property/filter', formData, {headers});
    return response;
  } 

  export const deletePropertyAudit = async (supportid) => {
    return await axiosInstance.delete(`audit/${supportid}`, { headers });
  };

  export const updatePropertyAudit = async (ownerData) => {
    const response = await axiosInstance.put('audit/property', ownerData, {headers});
    return response.data;
};

export const fetchPropertyAuditById = async (expenseid) => {
  const response = await axiosInstance.get(`audit/${expenseid}`, { headers });
  return response.data.result;
};

export const addPropertyAuditGallery = async (ownerData) => {
    const response = await axiosInstance.post('audit/gallery/add', ownerData, {  headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('accessToken')}`,
    }, });
    return response.data;
};

export const deletePropertyAuditGallery = async (supportid) => {
  return await axiosInstance.delete(`audit/gallery/${supportid}`, { headers });
};