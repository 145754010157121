import React from 'react';
import TableHeader from '../Common/TableComponent/TableHeader';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';

export const WitnessDetails = ({ customerData }) => {
    const flatmates = customerData?.witness || [];

    return (
        <>
         <div className='table-responsive'>
         <div className="card-header">
                    <h5 className="mb-sm-2 mt-sm-2">Witness Details</h5>
                </div>
            <table className="table align-middle table-bordered">
                <TableHeader columns={['#', 'Name', 'Email', 'Contact Number', 'Aadhar Number', 'Aadhar status']} />
                <tbody className="manage-page-group-table-values">
                    {flatmates.length === 0 ? (
                        <TableDataStatusError colspan="6" />
                    ) : (
                        flatmates.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.wName}</td>
                                <td>{item.wEmail}</td>
                                <td>{item.wContact}</td>
                                <td>{item.wAadhar}</td>
                                <td>
                                    {item.wAadharStatus === 'Unverified' ? (
                                        <span className="badge rounded-pill badge-soft-danger">Unverified</span>
                                    ) : (
                                        <span className="badge rounded-pill badge-soft-success">Verified</span>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            </div>

        </>
    );
};